import {ReactNode} from 'react';
import FontSizeEnum from '../enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import styles from './Text.module.css';
import FontWeightEnum from '../enums/fontWeightEnum';
import OverflowEnum from '../enums/overflowEnum';

export interface TextProps {
    readonly children: ReactNode
    readonly size: FontSizeEnum
    readonly color?: ColorEnum
    readonly weight?: FontWeightEnum
    readonly overflow?: OverflowEnum
}

export default function Text(props: TextProps) {

    function getSizeCSSClassName(): string {
        const overflow = props.overflow ? styles[props.overflow] : ''

        return `${styles[props.size]} ${overflow}`
    }

    function getColorCssVar(): string {
        return props.color ? `var(--${props.color})` : ''
    }

    function getWeightCssVar(): string {
        return props.weight ? `var(--${props.weight})` : 'var(--regular-font-weight)'
    }

    return (
        <p
            className={getSizeCSSClassName()}
            style={{color: getColorCssVar(), fontWeight: getWeightCssVar()}}
        >
            {props.children}
        </p>
    )
}
