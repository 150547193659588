import { ExclamationCircleIcon } from '@heroicons/react/outline';
import {Trans, useTranslation} from 'react-i18next';

interface NumberOfTimeslotsSubTextProps {
    readonly number: number
}

export default function NumberOfTimeslotsSubText(props: NumberOfTimeslotsSubTextProps) {
    const { t } = useTranslation();

    if (props.number <= 0)  {
        return (
            <>
                <div className="my-2 flex items-center text-red-400">
                    <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                    <p className="text-sm font-medium">{t('candidate.action.invite.error.timeslotDurationTooShort')}</p>
                </div>
            </>
        );
    }

    if (props.number == 1)  {
        return (
            <Trans
                i18nKey="candidate.action.invite.viewing.numberOfTimeslotMessage"
                components={{Strong: <strong/>}}
            />
        );
    }

    return (
        <Trans
            i18nKey="candidate.action.invite.viewing.numberOfTimeslotsMessage"
            values={{
                number: props.number
            }}
            components={{Strong: <strong/>}}
        />
    );
}
