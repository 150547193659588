import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import LayoutLandingPage from '../../components/generics/LayoutLandingPage';
import LanguageSwitchButtons from '../../components/locales/LanguageSwitchButtons';
import ListingCard from '../../components/listings/ListingCard';
import {getCandidateListing} from '../../redux/actions/candidates/getCandidateDetails';
import {submitViewingInvitationResponse} from '../../redux/actions/viewing/submitViewingInvitationResponse';
import {VIEWING_INVITATION_STATUS} from '../../utils/constants';
import ViewingInvitationResponse from '../../components/viewings/ViewingInvitationResponse';
import ViewingInvitationSubmissionFailed from '../../components/viewings/ViewingInvitationSubmissionFailed';

const SEARCH_PARAM_ACCEPTED = 'accepted';
const SEARCH_PARAM_SUBMITTED = 'submitted';

export default function SubmitViewingInvitationPage() {
    const {id} = useParams();

    const [viewingInvitation, isLoading] = useSelector((state) => [
        state.viewings.data.viewingInvitation, state.viewings.isLoading
    ]);

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();

    function isInvitationAccepted() {
        return searchParams.get(SEARCH_PARAM_ACCEPTED) === 'true';
    }

    function toggleResponse(submittedResponse) {
        dispatch(submitViewingInvitationResponse(
            id,
            submittedResponse === VIEWING_INVITATION_STATUS.DECLINED
        ));

        searchParams.set(SEARCH_PARAM_ACCEPTED, isInvitationAccepted() ? 'false' : 'true');
        setSearchParams(searchParams);
    }

    function getCandidateResponse() {
        if (viewingInvitation.isSubmitted) {
            return viewingInvitation.candidate.viewingInvitationStatus;
        }

        return isInvitationAccepted() ? VIEWING_INVITATION_STATUS.ACCEPTED : VIEWING_INVITATION_STATUS.DECLINED;
    }

    function isInvitationSubmitted() {
        return viewingInvitation.isSubmitted || searchParams.has(SEARCH_PARAM_SUBMITTED);
    }

    useEffect(() => {
        dispatch(getCandidateListing(id));
        if (!isInvitationSubmitted()) {
            dispatch(submitViewingInvitationResponse(id, isInvitationAccepted()));
            searchParams.append(SEARCH_PARAM_SUBMITTED, 'true');
            setSearchParams(searchParams);
        }
    }, []);

    return (
        <>
            <LayoutLandingPage>
                {!isLoading ?
                    <>
                        <div className="grid grid-cols-4 gap-3">
                            <div className="col-span-3"/>
                            <LanguageSwitchButtons className='col-span-1 flex flex-row justify-end items-center'/>
                        </div>
                        <div className="md:grid md:grid-cols-8 md:gap-2">
                            {isInvitationSubmitted() ?
                                <>
                                    <div className="md:col-span-3 ">
                                        <div className="grid grid-cols-6 gap-2">
                                            <ListingCard
                                                listing={viewingInvitation.viewing.listing}
                                                agent={viewingInvitation.viewing.listing.agent} className='col-span-6'/>
                                        </div>
                                    </div>
                                    <div className="mt-5 md:mt-0 md:col-span-5">
                                        <div className="overflow-hidden rounded-lg my-4">
                                            <div className="shadow overflow-hidden rounded-lg">
                                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                                    <ViewingInvitationResponse
                                                        invitationStatus={getCandidateResponse()}
                                                        toggleResponse={toggleResponse}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                <>
                                    <div className="mt-5 md:mt-0 md:col-span-8">
                                        <div className="overflow-hidden rounded-lg my-4">
                                            <div className="shadow overflow-hidden rounded-lg">
                                                <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                                    <ViewingInvitationSubmissionFailed
                                                        retry={
                                                            () => dispatch(submitViewingInvitationResponse(
                                                                id, isInvitationAccepted()
                                                            ))
                                                        }/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </> :
                    ''
                }
            </LayoutLandingPage>
        </>
    );
}
