import { useTranslation } from 'react-i18next';
import TopNavigation from '../../components/navigation/TopNavigation'

export default function CookieDeclarationPage() {

    const { t } = useTranslation()

    if (document.querySelector('script#CookieDeclaration') === null) {
        const script = document.createElement('script');

        script.id = 'CookieDeclaration';
        script.src = 'https://consent.cookiebot.com/4498a3a1-f725-4d82-9b08-064280b561ca/cd.js';
        script.async = true;
        script.type = 'text/javascript';

        document.body.appendChild(script);
    }

    return (
        <>
            <TopNavigation />
            <header className="py-8">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex-1 min-w-0">
                        <h1 className="mt-4 text-3xl font-bold leading-7 text-gray-800  sm:text-4xl sm:truncate font-heading text-uppercase">{t('cookies')}</h1>
                    </div>
                </div>
            </header>
            <div className="pb-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div id="CookieDeclaration"></div>
            </div>
        </>
    )
}
