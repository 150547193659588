import React, { ReactNode } from 'react';
import styles from './IconWithText.module.css';
import Text, { TextElements } from './Text';
import { FontSizeEnum } from '../../enums';

interface IconWithTextProps {
    readonly icon: ReactNode;
    readonly children: TextElements | TextElements[];
}

const IconWithText = ({
    icon,
    children
}: IconWithTextProps) => {
    return (
        <div className={styles.IconWithText}>{icon} <Text size={FontSizeEnum.Md}>{children}</Text></div>
    );
}

export default IconWithText;
