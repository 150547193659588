import HouseholdType from './householdType';
import GuarantorType from './guarantorType';
import CandidateModel from '../../../../../models/candidate/candidateModel';
import RentalPeriod, {rentalPeriodEnumFromNumber} from './rentalPeriod';

export default class CandidateSituationProfileModel {

    constructor(
        public readonly income: number,
        public readonly doesOwnPets: boolean,
        public readonly householdType: HouseholdType,
        public readonly amountOfPeopleInHousehold: number,
        public readonly guarantorType: GuarantorType,
        public readonly rentalPeriod: RentalPeriod,
        public readonly availableSince: Date | null,
        public readonly hasAcceptedAgentPrivacyPolicy: boolean
    ) {}

    public static createFromCandidate(candidate: CandidateModel): CandidateSituationProfileModel {
        return new CandidateSituationProfileModel(
            candidate.income,
            candidate.pets,
            HouseholdType[Object.keys(HouseholdType).find(key => HouseholdType[key] === candidate.householdType)],
            +candidate.household,
            GuarantorType[Object.keys(GuarantorType).find(key => GuarantorType[key] === candidate.guarantor)],
            candidate.term !== null ? rentalPeriodEnumFromNumber(candidate.term) : null,
            candidate.availableSince !== null ? new Date(candidate.availableSince) : null,
            candidate.hasAcceptedAgentPrivacyPolicy
        )
    }

    public isPrivate(): boolean {
        return this.hasAcceptedAgentPrivacyPolicy
    }

    public getPetsLabelText(): string {
        if (this.doesOwnPets === null || this.doesOwnPets === undefined) {
            return '-'
        }
        return this.doesOwnPets ? 'Ja' : 'Nee'
    }

    public getRentalPeriodLabelText(): string {
        switch (this.rentalPeriod) {
            case RentalPeriod.Indefinite: return 'Onbepaalde tijd'
            case RentalPeriod.ZeroMonthToThreeMonths: return '0 - 3 mnd'
            case RentalPeriod.ThreeMonthsToSixMonths: return '3 - 6 mnd'
            case RentalPeriod.SixMonthsToTwelveMonths: return '6 - 12 mnd'
            case RentalPeriod.MoreThanOneYear: return '1 - 2 jr'
            default: return '-'
        }
    }

    public getGuarantorTypeLabelText(): string {
        switch (this.guarantorType) {
            case GuarantorType.Abroad: return 'Buitenland'
            case GuarantorType.InNetherlands: return 'Nederland'
            case GuarantorType.NoGuarantor: return 'Geen'
            default: return '-'
        }
    }

    public getHouseHoldLabelText(): string {
        switch (this.householdType) {
            case HouseholdType.WithFamily: return 'Gezin' + ` (${this.amountOfPeopleInHousehold || '-'})`
            case HouseholdType.Single: return 'Alleen'
            case HouseholdType.WithFriends: return 'Vrienden' + ` (${this.amountOfPeopleInHousehold || '-'})`
            case HouseholdType.WithPartner: return 'Partner' + ` (${this.amountOfPeopleInHousehold || '-'})`
            default: return '-'
        }
    }
}
