import React from 'react';
import PropTypes from 'prop-types';

function PhoneNumberLabel(props) {
    return (
        <div className="flex flex-row items-center text-gray-500">
            <i className="ri-phone-line mr-2"></i>
            <p className="text-sm font-regular">
                {props.phoneNumber || '-'}
            </p>
        </div>
    );
}

PhoneNumberLabel.propTypes = {
    phoneNumber: PropTypes.string.isRequired
};

export default PhoneNumberLabel;
