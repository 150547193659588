import Button, {ButtonProps} from './Button';
import Tooltip from '../../tooltip/Tooltip';
import {useState} from 'react';

interface CopyButtonProps extends ButtonProps {
    readonly tooltipText: string
    readonly textToCopy: string
}
export default function CopyButton(props: CopyButtonProps) {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const [isButtonUnFocused, setIsButtonUnFocused] = useState(false);
    function onClick() {
        if (!isTooltipVisible) {
            navigator.clipboard.writeText(props.textToCopy).then(() => {
                setIsTooltipVisible(true);
                setIsButtonUnFocused(false);
                setTimeout(() => {
                    setIsTooltipVisible(false)
                    setIsButtonUnFocused(true)
                }, 2000);
            });
        }
    }

    return (
        <div>
            <Tooltip
                text={props.tooltipText}
                isVisible={isTooltipVisible}
                noHover={true}
            >
                <Button
                    size={props.size}
                    buttonType={props.buttonType}
                    buttonActionType={props.buttonActionType}
                    icon={props.icon}
                    onClick={onClick}
                    isDisabled={props.isDisabled}
                    title={props.title}
                    isFullWidth={props.isFullWidth}
                    isHidden={props.isHidden}
                    isUnFocused={isButtonUnFocused}
                />
            </Tooltip>
        </div>
    )
}
