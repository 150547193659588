import ColorEnum from '../../../enums/colorEnum'
import {Trans} from 'react-i18next'
import Text from '../../typography/text/Text';
import {useEffect, useState} from 'react';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';

export default function InlineTextLoader() {
    const [dotsCount, setCount] = useState(3);

    useEffect(() => {
        const interval = setInterval(() => {
            if (dotsCount == 3) {
                setCount(0);
            } else {
                setCount(dotsCount + 1);
            }
        }, 350);

        return () => clearInterval(interval);
    }, [dotsCount]);

    return <>
        <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray400} weight={FontWeightEnum.Medium}>
            {<Trans i18nKey={'loader.inline.text'}/>}
            <span className={ dotsCount < 1 ? 'text-transparent' : '' }>.</span>
            <span className={ dotsCount < 2 ? 'text-transparent' : '' }>.</span>
            <span className={ dotsCount < 3 ? 'text-transparent' : '' }>.</span>
        </Text>
    </>
}
