import React from 'react';

export default function ParariusLogo() {
    return <svg width="96" height="22" viewBox="0 0 96 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_3558_108435" maskUnits="userSpaceOnUse" x="0" y="5" width="15" height="17">
            <path fillRule="evenodd" clipRule="evenodd" d="M0.778809 5.63232H14.2666V21.9253H0.778809V5.63232Z"
                fill="white"/>
        </mask>
        <g mask="url(#mask0_3558_108435)">
            <path fillRule="evenodd" clipRule="evenodd"
                d="M5.01418 13.9727C5.6697 14.5738 6.5016 14.8741 7.5101 14.8741C8.5186 14.8741 9.35445 14.5738 10.0187 13.9727C10.6824 13.3721 11.0145 12.6 11.0145 11.6566C11.0145 10.7136 10.6824 9.94154 10.0187 9.34046C9.35445 8.73959 8.5186 8.43915 7.5101 8.43915C6.5016 8.43915 5.6697 8.73959 5.01418 9.34046C4.35889 9.94154 4.03113 10.7136 4.03113 11.6566C4.03113 12.6 4.35889 13.3721 5.01418 13.9727ZM8.11518 5.63232C9.81254 5.63232 11.262 6.21434 12.464 7.37797C13.6656 8.54159 14.2667 9.96793 14.2667 11.6566C14.2667 13.3452 13.6656 14.7714 12.464 15.9352C11.262 17.0988 9.81254 17.6808 8.11518 17.6808C6.35047 17.6808 4.9892 17.1256 4.03116 16.015V21.9253H0.778809V5.95163H4.03116V7.29814C4.9892 6.18773 6.35047 5.63232 8.11518 5.63232Z"
                fill="#27315B"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M20.1404 13.9727C20.7957 14.5738 21.6278 14.8741 22.6363 14.8741C23.6448 14.8741 24.4807 14.5738 25.145 13.9727C25.8086 13.3721 26.1407 12.6 26.1407 11.6566C26.1407 10.7136 25.8086 9.94154 25.145 9.34046C24.4807 8.73959 23.6448 8.43915 22.6363 8.43915C21.6278 8.43915 20.7957 8.73959 20.1404 9.34046C19.4851 9.94154 19.1573 10.7136 19.1573 11.6566C19.1573 12.6 19.4851 13.3721 20.1404 13.9727ZM26.1408 5.95163H29.3929V17.3613H26.1408V16.015C25.1656 17.1256 23.796 17.6808 22.0313 17.6808C20.3504 17.6808 18.9093 17.0988 17.7077 15.9352C16.5059 14.7714 15.905 13.3452 15.905 11.6566C15.905 9.96793 16.5059 8.54159 17.7077 7.37797C18.9093 6.21434 20.3504 5.63232 22.0313 5.63232C23.796 5.63232 25.1656 6.18773 26.1408 7.29814V5.95163Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M35.5944 7.91407C35.8969 7.18393 36.3967 6.63627 37.0943 6.27129C37.7918 5.90612 38.5692 5.72363 39.4266 5.72363V9.00959C38.4345 8.90316 37.548 9.08565 36.7668 9.55705C35.9851 10.0287 35.5944 10.8122 35.5944 11.9073V17.3613H32.3423V5.95159H35.5944V7.91407Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M44.418 13.9727C45.0735 14.5738 45.9054 14.8741 46.9139 14.8741C47.9224 14.8741 48.758 14.5738 49.4223 13.9727C50.0862 13.3721 50.4183 12.6 50.4183 11.6566C50.4183 10.7136 50.0862 9.94154 49.4223 9.34046C48.758 8.73959 47.9224 8.43915 46.9139 8.43915C45.9054 8.43915 45.0735 8.73959 44.418 9.34046C43.7625 9.94154 43.4349 10.7136 43.4349 11.6566C43.4349 12.6 43.7625 13.3721 44.418 13.9727ZM50.4184 5.95163H53.6705V17.3613H50.4184V16.015C49.4432 17.1256 48.0736 17.6808 46.3089 17.6808C44.628 17.6808 43.1866 17.0988 41.9851 15.9352C40.7833 14.7714 40.1826 13.3452 40.1826 11.6566C40.1826 9.96793 40.7833 8.54159 41.9851 7.37797C43.1866 6.21434 44.628 5.63232 46.3089 5.63232C48.0736 5.63232 49.4432 6.18773 50.4184 7.29814V5.95163Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M59.872 7.91407C60.1745 7.18393 60.6745 6.63627 61.3721 6.27129C62.0693 5.90612 62.847 5.72363 63.704 5.72363V9.00959C62.7119 8.90316 61.8258 9.08565 61.0444 9.55705C60.2627 10.0287 59.872 10.8122 59.872 11.9073V17.3613H56.6199V5.95159H59.872V7.91407Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M65.5945 17.3614H68.8466V5.95166H65.5945V17.3614Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M80.1411 5.95166H83.3932V17.3614H80.1411V16.0834C79.3507 17.1485 78.0989 17.6807 76.3846 17.6807C75.0065 17.6807 73.8674 17.2625 72.9686 16.4257C72.0691 15.5893 71.6196 14.433 71.6196 12.9571V5.95166H74.8718V12.5921C74.8718 13.3528 75.099 13.9348 75.5527 14.3377C76.0064 14.741 76.6115 14.9424 77.3677 14.9424C78.2079 14.9424 78.8805 14.7069 79.3847 14.2351C79.889 13.7639 80.1411 13.0563 80.1411 12.1129V5.95166Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M89.2671 9.19211C89.2671 9.49652 89.4898 9.74374 89.9353 9.93378C90.3802 10.124 90.9223 10.2914 91.5614 10.4358C92.1998 10.5805 92.8384 10.763 93.4773 10.9834C94.1159 11.2042 94.6578 11.5732 95.1036 12.0903C95.5487 12.6075 95.7714 13.2539 95.7714 14.0297C95.7714 15.2015 95.2881 16.1028 94.3219 16.7339C93.3553 17.3649 92.1496 17.6808 90.7043 17.6808C88.1158 17.6808 86.3508 16.776 85.4099 14.9654L88.2334 13.5277C88.603 14.5168 89.4268 15.011 90.7043 15.011C91.8639 15.011 92.4435 14.684 92.4435 14.0297C92.4435 13.7257 92.2206 13.4787 91.7757 13.2882C91.33 13.0982 90.7881 12.927 90.1495 12.7747C89.5104 12.6228 88.872 12.4324 88.2334 12.2042C87.5945 11.9761 87.0524 11.6149 86.6073 11.1204C86.162 10.6262 85.9393 10.0063 85.9393 9.26062C85.9393 8.13492 86.3971 7.24889 87.3133 6.60215C88.2292 5.9558 89.368 5.63232 90.7293 5.63232C91.7545 5.63232 92.6871 5.84162 93.5277 6.25981C94.368 6.6784 95.0316 7.2753 95.5195 8.05112L92.7461 9.42027C92.3428 8.64445 91.6704 8.25644 90.7293 8.25644C90.3089 8.25644 89.9603 8.34044 89.683 8.50764C89.4057 8.67503 89.2671 8.90339 89.2671 9.19211Z"
            fill="#27315B"/>
        <path fillRule="evenodd" clipRule="evenodd"
            d="M69.4697 1.98571C69.4697 3.08223 68.4873 3.97143 67.2759 3.97143C66.0644 3.97143 65.082 3.08223 65.082 1.98571C65.082 0.889203 66.0644 0 67.2759 0C68.4873 0 69.4697 0.889203 69.4697 1.98571Z"
            fill="#12A2FC"/>
    </svg>;
}
