import Spinner from '../spinner/Spinner';
import styles from './SpinnerOverlay.module.css';

export default function SpinnerOverlay() {
    return (
        <div className={styles.root}>
            <Spinner testId="spinner-overlay" />
        </div>
    )
}
