import { REMOVE_NOTIFICATION, SET_NOTIFICATION, START_LOADING, STOP_LOADING, SHOW_FORM_SUCCESS } from '../actions/ui/types';

const initialState = {
    isLoading: false,
    notifications: [],
    showFormSuccess: false
};

export default function (state = initialState, action = {}) {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case STOP_LOADING:
            return {
                ...state,
                isLoading: false
            };
        case SHOW_FORM_SUCCESS:
            return {
                ...state,
                showFormSuccess: true
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notifications: [
                    ...state.notifications, action.payload
                ]
            };
        case REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications.filter(
                    notification => notification.id !== action.payload
                )]
            };
        default:
            return state;
    }
}
