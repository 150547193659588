import style from './DateInput.module.css'
import moment, {Moment} from 'moment'
import React, {useEffect, useState} from 'react'

interface DateInputProps {
    readonly minimumValue?: Moment
    readonly value?: Moment
    readonly onChange?: (value: Moment) => void
    readonly id ?: string
}

export default function DateInput(props: DateInputProps) {

    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
        const newDate = moment(e.target.value)
        setValue(newDate)
        props.onChange?.(newDate)
    }

    function handleBlur(e: React.FocusEvent<HTMLInputElement>): void {
        const newDate = moment(e.target.value)
        setValue(newDate)
        props.onChange?.(newDate)
    }

    function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>): void {
        if (e.key === 'Enter') {
            const newDate = moment((e.target as HTMLInputElement).value)
            setValue(newDate)
            props.onChange?.(newDate)
        }
    }

    return (
        <input
            id={props.id}
            type="date"
            className={style.root}
            min={props.minimumValue?.format('yyyy-MM-DD')}
            value={ value?.format('yyyy-MM-DD') ?? '' }
            onChange={(e) => handleChange(e)}
            onBlur={(e) => handleBlur(e)}
            onKeyUp={(e) => handleKeyUp(e)}
        />
    )
}
