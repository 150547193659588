import { request } from '../../../utils/api';
import { UI_ACTIONS } from '../../../utils/constants';


export const setCandidateDetails = (token, id, data) => dispatch => {
    dispatch({ type: UI_ACTIONS.START_LOADING });
    request(token).put(`/api/candidates/${id}`,
        data
    )
        .then(response => {
            if (response.data) {
                dispatch({ type: 'SHOW_FORM_SUCCESS' });
            }
        })
        .catch((e) => {
            dispatch({ type: UI_ACTIONS.STOP_LOADING });
            console.log(e);
        })
        .finally(() => dispatch({ type: UI_ACTIONS.STOP_LOADING }));
};
