import React, { useEffect, useState } from 'react';
import EnrichingForm from '../../components/forms/EnrichingForm';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getEnrichingCandidateDetails } from '../../redux/actions/candidates/getCandidateDetails';
import { setCandidateDetails } from '../../redux/actions/candidates/setCandidateDetails';
import FormSuccess from '../../components/empty-states/FormSuccess';

export default function EnrichingFormPage() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.users);
    const { data } = useSelector((state) => state.candidates);
    const { showFormSuccess } = useSelector((state) => state.ui);
    const [pets, setPets] = useState(null);

    const submitEnrichingData = (details) => {
        details['pets'] = pets;
        details['income'] = parseInt(details.income === '' ? 0 : details.income)
        details['term'] = parseInt(details.term);
        details['birthday'] = details['birthday'] === '' ? null : details['birthday']
        details['availableSince'] = details['availableSince'] === '' ? null : details['availableSince']
        dispatch(setCandidateDetails(token, id, details));
    };

    useEffect(() => {
        dispatch(getEnrichingCandidateDetails(token, id));
    }, [token, dispatch, showFormSuccess]);

    return (
        <>
            {showFormSuccess ?
                <FormSuccess /> :
                <EnrichingForm
                    data={data}
                    pets={pets}
                    setPets={setPets}
                    submitEnrichingData={submitEnrichingData}
                />
            }
        </>
    );
}
