import React, { useEffect, useState } from 'react';
import { Select } from '../select';
import { useTranslation } from 'react-i18next';
import styles from './LanguageSelect.module.css';
import DutchFlagIcon from '../../atoms/icons/flags/DutchFlagIcon';
import GreatBritainFlagIcon from '../../atoms/icons/flags/GreatBritain';
import { findSelectedListItemByValue, ListItem } from '../select/Select';
import currentLanguage from '../../utils/currentLanguage';

const LanguageSelect = () => {
    const { t, i18n } = useTranslation();

    const lang = currentLanguage();
    const [selected, setSelected] = useState(lang);

    const listItems = [
        {
            value: 'nl',
            label: (
                <div className={styles.label}>
                    <DutchFlagIcon/>
                    <span>Nederlands</span>
                </div>
            )
        },
        {
            value: 'en',
            label: (
                <div className={styles.label}>
                    <GreatBritainFlagIcon/>
                    <span>English</span>
                </div>
            )
        }
    ];

    const [selectedListItem, setSelectedListItem] = useState(findSelectedListItemByValue(listItems, selected));

    useEffect(() => {
        i18n.changeLanguage(selectedListItem.value)
    }, [selectedListItem])

    const handleLanguageChange = (listItem: ListItem) => {
        setSelectedListItem(listItem)
    }

    return (
        <>
            <Select
                selected={selected}
                list={listItems}
                onChange={handleLanguageChange}
            />
        </>
    );
};

export default LanguageSelect;
