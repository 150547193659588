import {Menu} from '@headlessui/react'
import {useTranslation} from 'react-i18next'
import ActionType from '../../../enums/actionType'
import {useActionTypeToIcon} from '../../../hooks/useActionTypeToIcon'
import ButtonGroupItemModel from '../logic/model/buttonGroupItemModel'

export interface ButtonGroupMenuItemProps {
    readonly model: ButtonGroupItemModel,
    readonly onClick: (actionType: ActionType) => void,
}

function ButtonGroupMenuItem(props: ButtonGroupMenuItemProps) {
    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }


    const {t} = useTranslation()
    const toIcon = useActionTypeToIcon()

    return (
        <Menu.Item
            data-testid="menu-item"
            as='div'
            disabled={props.model.isDisabled}
            onClick={() => props.onClick(props.model.actionType)}
        >
            {({ active }) => (
                <a className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                )}
                >
                    <button>
                        <i
                            data-testid="menu-item-icon"
                            className={`${toIcon(props.model.actionType)} mr-2`}
                        ></i>
                        <span data-testid="menu-item-label">
                            {t(props.model.label)}
                        </span>
                    </button>
                </a>
            )}
        </Menu.Item>
    )
}

export default ButtonGroupMenuItem
