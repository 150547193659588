import AddressModel from '../model/addressModel'
import ListingPropertiesModel
    from '../../../../components/listings/listingProperties/logics/model/listingPropertiesModel'

export default class TransactionalListingToAddressConverter {

    public static convert(listing: any): any {
        return new AddressModel({
            listingId: listing.id,
            value: `${listing.street} ${listing.houseNumber}, ${listing.postalCode} ${listing.city}`,
            listingProperties: new ListingPropertiesModel(parseFloat(listing.price), listing.surface, listing.rooms)
        })
    }

}
