import React from 'react'
import PropTypes from 'prop-types'
import TopNavigation from '../../../components/navigation/TopNavigation'
import OverviewHeader from '../../../components/headers/OverviewHeader'
import { renderGuarantor, renderHousehold, renderPets, renderIncome, renderWork, renderTerm, renderStartDate } from '../../../utils/renders'
import SendMessageModal from '../../../components/modals/SendMessageModal'
import CreateNoteModal from '../../../components/modals/CreateNoteModal'
import ExtraInformationModal from '../../../components/modals/ExtraInformationModal'
import Tooltip from '../../../components/tooltip/Tooltip'
import {t} from 'i18next'

function CandidateProfilePage(props) {
    const { data, sendMessageToCandidate, subject, body,
        setBody, isMessageModalOpen, setSubject,
        toggleMessageModal, handleCandidateStatus,
        isNoteModalOpen, toggleNoteModal, handleCreateNote,
        toggleExtraInfoModal, isExtraInfoModalOpen,
        handleRequestExtraInfo
    } = props
    const { firstName, phone, employment, income, email,
        guarantor, householdType, household, pets } = data

    return (
        <div className="min-h-full">
            <TopNavigation />
            <main className="py-10">
                {/* Page header */}
                <OverviewHeader type="candidate_profile" data={data} handleCandidateStatus={handleCandidateStatus} />

                <div className="mt-8 max-w-3xl mx-auto  max-h-full sm:px-6 lg:max-w-full bg-gray-100 py-[2rem]">
                    <div className='max-w-3xl lg:max-w-7xl mx-auto p-3 w-full grid grid-cols-3 gap-4 lg:grid-flow-col-dense'>
                        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                            {/* Description list*/}
                            <section aria-labelledby="applicant-information-title">
                                <div className="bg-white sm:rounded-lg">
                                    <div className="px-4 py-5 sm:px-6 flex flex-row justify-between items-center">
                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                            Extra informatie
                                        </h2>
                                        <button
                                            className="inline-flex items-center justify-center px-3 py-2 border
                                                border-transparent text-base font-medium rounded-md shadow-sm
                                                text-white bg-primary hover:bg-primary-dark focus:outline-none
                                                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                            onClick={() => toggleExtraInfoModal(!isExtraInfoModalOpen)}
                                        >
                                            {t('candidate.action.extraInfo')}
                                        </button>
                                    </div>
                                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Werksituatie</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderWork(employment)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Emailadres</dt>
                                                <Tooltip text={email}>
                                                    <dd className="mt-1 text-base text-gray-900 truncate w-full lg:max-w-[8rem] xl:max-w-[12rem]">
                                                        {email}
                                                    </dd>
                                                </Tooltip>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Phone</dt>
                                                <dd className="mt-1 text-base text-gray-900">{phone}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Inkomen</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderIncome(income)}</dd>
                                            </div>

                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Garantsteller</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderGuarantor(guarantor)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Huishouden</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderHousehold(householdType, household)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Huisdieren</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderPets(pets)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Startdatum</dt>
                                                <dd className="mt-1 text-base text-gray-900">~ {renderStartDate(data.availableSince)}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                                <dt className="text-sm font-medium text-gray-500">Huurperiode</dt>
                                                <dd className="mt-1 text-base text-gray-900">{renderTerm(data.term)}</dd>
                                            </div>
                                        </dl>
                                    </div>
                                </div>
                            </section>

                            {/* Comments*/}
                            <section aria-labelledby="notes-title">
                                <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                                    <div className="divide-y divide-gray-200">
                                        <div className="px-4 py-5 sm:px-6 flex flex-row justify-between items-center">
                                            <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                                                Berichten
                                            </h2>
                                            <button
                                                className="inline-flex items-center justify-center px-3 py-2 border
                                                border-transparent text-base font-medium rounded-md shadow-sm
                                                text-white bg-primary hover:bg-primary-dark focus:outline-none
                                                focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                onClick={() => toggleMessageModal(true)}

                                            >
                                                <i className="ri-mail-send-line ri-lg mr-2"></i>
                                                {t('candidate.action.initiateMessage')}
                                            </button>
                                        </div>
                                        <div className="px-4 py-6 sm:px-6">
                                            <ul role="list" className="space-y-8">
                                                {data.messages.map((message) => (
                                                    <li key={message['@id']} id="candidate-message">
                                                        <div className="flex space-x-3 border border-gray-100 w-full
                                                    py-3 px-3 rounded-lg bg-gray-50">
                                                            <div className="flex-shrink-0">
                                                                <img
                                                                    className="h-10 w-10 rounded-full"
                                                                    src={`https://via.placeholder.com/420/cecece/FFFFFF?text=${firstName.slice(0, 1)}`}
                                                                    alt=""
                                                                />
                                                            </div>
                                                            <div className="whitespace-pre-line overflow-hidden break-words break-normal">
                                                                <div>
                                                                    <p className="font-bold text-gray-900 ">
                                                                        {message.details.subject}
                                                                    </p>
                                                                </div>
                                                                <div className=" text-base text-gray-700">
                                                                    <div dangerouslySetInnerHTML={{ __html: message.details.body }} />
                                                                </div>
                                                                <div className="mt-2 text-sm space-x-2">
                                                                    <span className="text-gray-400 font-medium">{renderStartDate(message.createdAt)}</span>{' '}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                            <div className="bg-white sm:rounded-lg sm:overflow-hidden">
                                <div className="divide-y divide-gray-200">
                                    <div className='px-4 py-3 flex flex-row justify-between items-center'>
                                        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                                            Notities
                                        </h2>
                                        <button
                                            type="button"
                                            className="inline-flex items-center px-3 py-2 border
                                            border-gray-200 shadow-sm text-base font-medium rounded-lg
                                            text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-[3px]
                                            focus:ring-primary-100 focus:border-primary-300"
                                            onClick={() => toggleNoteModal(true)}
                                        >
                                            <i className="ri-pencil-line ri-lg mr-2">
                                            </i> Maak notitie
                                        </button>
                                    </div>
                                    <div className="px-4 py-6 sm:px-6">
                                        <ul role="list" className="space-y-8">
                                            {data.notes.length < 1 && <li>
                                                <div className="flex space-x-3">
                                                    <div>
                                                        <div className="mt-1 text-base text-gray-700">
                                                            <p>Geen notities</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>}
                                            {data.notes.map((note) => (
                                                <li key={note.id}>
                                                    <div className="flex border border-gray-100 w-full
                                                    py-3 px-3 rounded-lg bg-gray-50">
                                                        <div className="whitespace-pre-line overflow-hidden">
                                                            <p className="font-medium text-gray-900  break-words break-normal">
                                                                {note.note}
                                                            </p>
                                                            <span className="text-gray-400
                                                                    font-medium my-3">
                                                                {renderStartDate(note.createdAt)}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
                <SendMessageModal
                    receiversEmail={email}
                    isOpen={isMessageModalOpen}
                    closeModal={toggleMessageModal}
                    body={body}
                    subject={subject}
                    setBody={setBody}
                    setSubject={setSubject}
                    sendMessageToCandidate={sendMessageToCandidate}
                />
                <CreateNoteModal
                    body={body}
                    setBody={setBody}
                    isOpen={isNoteModalOpen}
                    closeModal={toggleNoteModal}
                    handleCreateNote={handleCreateNote}
                />
                <ExtraInformationModal
                    extraInfoModalOpen={isExtraInfoModalOpen}
                    toggleExtraInfoModal={toggleExtraInfoModal}
                    selectedPeople={[data]}
                    handleRequestExtraInfo={handleRequestExtraInfo}
                />
            </main>
        </div>
    )
}

CandidateProfilePage.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    subject: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    setBody: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    sendMessageToCandidate: PropTypes.func.isRequired,
    handleCandidateStatus: PropTypes.func.isRequired,
    handleCreateNote: PropTypes.func.isRequired,
    isNoteModalOpen: PropTypes.bool.isRequired,
    toggleNoteModal: PropTypes.func.isRequired,
    isExtraInfoModalOpen: PropTypes.bool.isRequired,
    toggleExtraInfoModal: PropTypes.func.isRequired,
    toggleMessageModal: PropTypes.func.isRequired,
    isMessageModalOpen: PropTypes.bool.isRequired,
    handleRequestExtraInfo: PropTypes.func,
    setSubject: PropTypes.func
}

export default CandidateProfilePage
