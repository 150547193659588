import React from 'react'
import PropTypes from 'prop-types'

const Error = (props) => {
    const { title, message } = props

    return (
        <div className="h-screen w-screen bg-white flex flex-col items-center justify-center space-y-6">
            <i className="ri-error-warning-line ri-5x text-primary"></i>
            <h1 className="text-primary text-3xl font-bold">{title}</h1>
            {message && <h1 className="text-primary text-1xl">{message}</h1>}
            <div className="grid grid-cols-1 border-t-2 border-gray-200 py-4">
                <div className="flex flex-col justify-center items-center">
                    <a href={`${window.location.origin}`} className="text-gray-600 font-medium hover:text-gray-800">
                        Terug naar login
                    </a>
                </div>
            </div>
        </div>
    )
}

Error.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string,
    code: PropTypes.number,
}

export default Error
