import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import style from './CloseButton.module.css'

interface CloseButtonProps {
    readonly color?: ColorEnum
    readonly onClick?: () => void
}

export default function CloseButton(props: CloseButtonProps) {
    return (
        <button
            className={style.root}
            onClick={() => props.onClick?.()}
            type="button"
        >
            <i
                style={{color: colorEnumAsCssVar(props.color ? props.color : ColorEnum.Gray700)}}
                className="ri-close-line ri-lg"
            ></i>
        </button>
    )
}
