enum CandidatePlace {
    New = 'New',
    BeingAssessed = 'BeingAssessed',
    OnWaitingList = 'OnWaitingList',
    Rejected = 'Rejected',
    Approved = 'Approved',
    InvitedToViewing = 'InvitedToViewing',
    AcceptedInvitationToViewing = 'AcceptedInvitationToViewing',
    DeclinedInvitationToViewing = 'DeclinedInvitationToViewing',
    ReceivedExtraInfoForm = 'ReceivedExtraInfoForm',
    FilledInExtraInfoForm = 'FilledInExtraInfoForm',
    ScreeningSent = 'ScreeningSent',
    ScreeningSentWithCoTenants = 'ScreeningSentWithCoTenants',
    ScreeningNotCompleted = 'ScreeningNotCompleted',
    ScreeningNotCompletedWithCoTenants = 'ScreeningNotCompletedWithCoTenants',
    IsListingTenant = 'IsListingTenant',
}

export default CandidatePlace
