import styles from './AnnouncementPageFrame.module.css';
import Logo from '../logo/Logo';
import {LogoLeadflowPoweredBy} from '../logo/LogoLeadflowPoweredBy';
import HorizontalDivider from '../generics/horizontalDivider/HorizontalDivider';
import {ReactNode} from 'react';
import FontSizeEnum from '../typography/enums/fontSizeEnum';
import Text from '../typography/text/Text';
import {Trans} from 'react-i18next';
import ColorEnum from '../../enums/colorEnum';

export interface AnnouncementPageFrameProps {
    readonly children: ReactNode
}

export default function AnnouncementPageFrame(props: AnnouncementPageFrameProps) {
    return (
        <div
            className="flex flex-col items-center justify-center py-6 sm:py-12 px-4 sm:px-6 lg:px-8 bg-gray-100 overflow-x-auto">
            <div className="max-w-md min-h-screen mb-8 sm:mt-8">
                <header className={styles.header}>
                    <a href="https://leadflow.rent/" target="_blank" rel="noreferrer">
                        <Logo/>
                    </a>
                </header>
                <main>
                    <div className="items-center max-w-md space-y-8 bg-white px-4 py-6 rounded-xl shadow">
                        {props.children}
                    </div>
                </main>
                <footer className={styles.footer}>
                    <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                        <Trans
                            i18nKey="announcementPage.footer.text"
                            components={{ Link: <a/> }}
                        />
                    </Text>
                    <HorizontalDivider/>
                    <LogoLeadflowPoweredBy/>
                </footer>
            </div>
        </div>
    )
}
