import DiscardedLeadsModal from '../../../components/modals/DiscardedLeadsModal'
import {useEffect, useRef, useState} from 'react'
import DiscardedLeadsPageModel from './model/discardedLeadsPageModel'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import {useParams} from 'react-router-dom'
import DiscardedLeadsPageService from './service/discardedLeadsPageService'
import {setNotification} from '../../../redux/actions/ui/setNotification'
import TopNavigation from '../../../components/navigation/TopNavigation'
import Title from '../../../components/typography/title/Title'
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum'
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum'
import ColorEnum from '../../../enums/colorEnum'
import styles from './DiscardedLeadsPage.module.css';
import Text from '../../../components/typography/text/Text'
import OverflowEnum from '../../../components/typography/enums/overflowEnum'

export default function DiscardedLeadsPage() {
    const [isDiscardedLeadsModalOpen, setIsDiscardedLeadsModalOpen] = useState(false)

    const {t} = useTranslation()
    const dispatch = useDispatch()
    const {agentId} = useParams()

    const service = useRef(new DiscardedLeadsPageService())

    const [model, setModel] = useState(DiscardedLeadsPageModel.createEmpty())

    useEffect(() => {
        service.current.getAgencyName(agentId)
            .then(val => setModel(prev => prev.with({ agencyName: val })))
        service.current.getDiscardedLeads(agentId)
            .then(val => setModel(prev => prev.with({ discardedLeads: val })))
    }, [])

    function handleTemplateLinkClick(leadIndex: number) {
        setModel(prev => prev.with({
            isModalOpen: true,
            selectedLeadIndex: leadIndex
        }))

        setIsDiscardedLeadsModalOpen(true)
    }

    function dismissDiscardedLead(event, leadId: string) {
        event.preventDefault()
        const confirm = window.confirm(t('discardedLeadsPage.confirmDismissing'))
        if (!confirm) {
            return
        }

        service.current.dismissDiscardedLead(leadId)
            .then(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'success',
                    message: t('discardedLeadsPage.dismiss.success')
                }))

                setModel(
                    prev => prev.with(
                        {
                            discardedLeads: prev.value.discardedLeads.filter((lead) => lead.value.id !== leadId)
                        }
                    )
                )
            })
            .catch(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'failed',
                    message: t('discardedLeadsPage.dismiss.failed')
                }))
            })
    }

    return (
        <div className='relative h-full w-full'>
            <div className={styles.wrapper}>
                <TopNavigation/>
                <header>
                    <div className={styles.headerContainer}>
                        <Title
                            size={FontSizeEnum.Md}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Gray800}
                        >
                            {t('discardedLeadsPage.title')}
                        </Title>
                        <Title
                            size={FontSizeEnum.Xsm}
                            weight={FontWeightEnum.Regular}
                            color={ColorEnum.Gray500}
                        >
                            {model.value.agencyName}
                        </Title>
                    </div>
                </header>
                <main className={styles.mainTableContainer}>
                    {model.value.discardedLeads.length > 0 &&
                        <table className={styles.leadTable} >
                            <thead>
                                <tr>
                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.createdAt')}
                                        </Text>
                                    </th>

                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.step')}
                                        </Text>
                                    </th>

                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.systemReason')}
                                        </Text>
                                    </th>

                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.lead')}
                                        </Text>
                                    </th>
                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.email')}
                                        </Text>
                                    </th>

                                    <th>
                                        <Text
                                            size={FontSizeEnum.Md}
                                            color={ColorEnum.Gray700}
                                        >
                                            {t('discardedLeadsPage.table.template')}
                                        </Text>
                                    </th>

                                    <th><Text size={FontSizeEnum.Md} color={ColorEnum.Gray700}>Acties</Text></th>
                                </tr>
                            </thead>
                            <tbody>
                                {model.value.discardedLeads.map((row, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                <Text
                                                    size={FontSizeEnum.Md}
                                                    color={ColorEnum.Gray700}
                                                >
                                                    {row.getFormattedCreatedAt()}
                                                </Text>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Text
                                                    size={FontSizeEnum.Md}
                                                    color={ColorEnum.Gray700}
                                                >
                                                    {t(row.getReasonResourceKey())}
                                                </Text>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Text
                                                    size={FontSizeEnum.Md}
                                                    color={ColorEnum.Gray700}
                                                >
                                                    {row.value.message}
                                                </Text>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Text
                                                    size={FontSizeEnum.Md}
                                                    color={ColorEnum.Gray700}
                                                >
                                                    {row.value.homeseekerName}
                                                </Text>
                                            </div>
                                        </td>
                                        <td>
                                            <div title={row.value.homeseekerEmail}>
                                                <Text
                                                    size={FontSizeEnum.Md}
                                                    color={ColorEnum.Gray700}
                                                    overflow={OverflowEnum.Ellipsis}
                                                >
                                                    {row.value.homeseekerEmail}
                                                </Text>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {row.value.template.substring(0,75)}&hellip;
                                            </div>
                                        </td>
                                        <td>
                                            <a href="#" onClick={event => dismissDiscardedLead(event, row.value.id)}>
                                                <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500}>Verbergen</Text>
                                            </a>
                                            <a href="#" onClick={() => handleTemplateLinkClick(index)}>
                                                <Text size={FontSizeEnum.Md} color={ColorEnum.Primary500} overflow={OverflowEnum.Ellipsis}>
                                                    {t('discardedLeadsPage.openTemplate')}
                                                </Text>
                                            </a>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    }
                </main>
            </div>
            <DiscardedLeadsModal
                isOpen={isDiscardedLeadsModalOpen}
                onCloseButtonClick={() => setIsDiscardedLeadsModalOpen(false)}
                content={model.getSelectedLeadStrippedTemplate()}
            />
        </div>
    )
}
