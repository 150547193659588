import React, { useState } from 'react';
import { TimeslotSelector } from '.';
import styles from './TimeslotSelection.module.css';
import { Timeslot } from '../../utils/api/viewing/getViewingWithTimeslots';
import { useNavigate, useParams } from 'react-router-dom';
import acceptViewing from '../../utils/api/viewing/acceptViewing';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

interface TimeslotsSelectionProps {
    readonly timeslots: Timeslot[];
}

const TimeslotsSelection = ({
    timeslots,
}: TimeslotsSelectionProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedTimeslotIndex, setSelectedTimeslotIndex] = useState(null);

    const {viewingId, homeseekerApplicationId} = useParams();

    const onTimeslotConfirmClick = async () => {
        setIsLoading(true);
        try {
            await acceptViewing({
                viewingId: viewingId,
                homeseekerApplicationId: homeseekerApplicationId,
                startTime: timeslots[selectedTimeslotIndex].startTime,
                endTime: timeslots[selectedTimeslotIndex].endTime,
            });

            navigate(`/homeseeker/${homeseekerApplicationId}/viewing/${viewingId}/timeslot/accepted`);

            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('viewing.page.confirm.timeslot.success')
            }));
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('viewing.page.confirm.timeslot.failed')
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const timeslotItems = timeslots.map((timeslot: Timeslot, index: number) =>
        <TimeslotSelector
            key={index}
            timeslot={timeslot}
            selected={index === selectedTimeslotIndex}
            loading={isLoading}
            onTimeslotClick={() => setSelectedTimeslotIndex(index)}
            onTimeslotConfirmClick={onTimeslotConfirmClick}
        />
    );

    return (
        <div className={styles.TimeslotSelection}>{timeslotItems}</div>
    );
};

export default TimeslotsSelection;
