import { FunctionComponent, useEffect, useState } from 'react'
import ActionType from '../../enums/actionType'
import ButtonGroupItem from './components/ButtonGroupItem'
import ButtonGroupMenu from './components/ButtonGroupMenu'
import MenuAlignment from './enums/menuAlignment'
import ButtonGroupModel from './logic/model/buttonGroupModel'
import styles from './ButtonGroup.module.css'

export interface ButtonGroupProps {
    readonly model: ButtonGroupModel
    readonly isDisabled: boolean
    readonly menuAlignment?: MenuAlignment
    readonly onClick: (actionType: ActionType) => void
}

const ButtonGroup: FunctionComponent<ButtonGroupProps> = (props: ButtonGroupProps) => {

    const [model, setModel] = useState(props.model.updateIsDisabled(props.isDisabled))

    useEffect(() => {
        setModel(props.model.updateIsDisabled(props.isDisabled))
    }, [props.model, props.isDisabled])

    return (
        <div className='inline-flex rounded-md shadow-sm max-w-fit'>
            {model.items.map((item, i) => !item.isHidden && (
                <div key={i} data-testid={'button-group-item-' + i} className={styles.buttonContainer}>
                    <ButtonGroupItem
                        position={model.getItemPosition(item)}
                        model={item}
                        onClick={(a: ActionType) => props.onClick(a)}
                    />
                </div>
            ))}
            {!model.isMenuEmpty() &&
                <div data-testid="menu" className={styles.buttonContainer}>
                    <ButtonGroupMenu
                        dropDownAlignment={props.menuAlignment}
                        model={model.menu}
                        onClick={(a: ActionType) => props.onClick(a)}
                    />
                </div>}
        </div>
    )
}

ButtonGroup.defaultProps = {
    menuAlignment: MenuAlignment.Below
}

export default ButtonGroup
