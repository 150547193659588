import React, { ReactNode } from 'react';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum';
import { fontSizeEnumAsCssVar } from '../../enums/FontSizeEnum';
import { fontWeightEnumAsCssVar } from '../../enums/FontWeightEnum';
import { AllowedTagNames } from './AllowedElements';

export type TextElements = string | boolean | number | ReactNode;

interface TextProps {
    readonly tag?: AllowedTagNames;
    readonly color?: ColorEnum;
    readonly size?: FontSizeEnum;
    readonly weight?: FontWeightEnum;
    readonly children: TextElements | TextElements[];
}

const Text = ({
    tag: Tag = 'span',
    color = ColorEnum.Inherit,
    size = FontSizeEnum.Md,
    weight = FontWeightEnum.Regular,
    children
}: TextProps) => {
    const style = {
        color: getColorCssVar(color),
        fontSize: fontSizeEnumAsCssVar(size),
        fontWeight: fontWeightEnumAsCssVar(weight)
    };

    return (
        <Tag style={style}>
            {children}
        </Tag>
    );
}

export default Text;
