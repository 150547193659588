import React, {useEffect, useState} from 'react';
import {ArrowNarrowLeftIcon, ArrowNarrowRightIcon} from '@heroicons/react/solid';
import PaginationBarModel from './logic/model/paginationBarModel';
import {useTranslation} from 'react-i18next';
import styles from './PaginationBar.module.css';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export interface PaginationBarProps {
    readonly model: PaginationBarModel
    readonly onItemsPerPageChange?: (model: PaginationBarModel) => void
    readonly onPageChange?: (model: PaginationBarModel) => void
}

function PaginationBar(props: PaginationBarProps) {

    const [model, setModel] = useState(props.model)

    const {t} = useTranslation()

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    function changeItemsPerPage(itemsPerPage: number) {
        const newModel = model.with({itemsPerPage})
        setModel(newModel)
        props?.onItemsPerPageChange(newModel)
    }

    function selectNextPage() {
        const newModel = model.withNextPage()
        setModel(newModel)
        props?.onPageChange(newModel)
    }

    function selectPreviousPage() {
        const newModel = model.withPreviousPage()
        setModel(newModel)
        props?.onPageChange(newModel)
    }

    return (
        <nav
            className={styles.paginationBar}
            aria-label="Pagination"
        >
            <div className={classNames('flex-row flex items-center justify-center space-x-4 w-full')}>
                <p className="block text-md font-medium text-gray-700">
                    {t('pagination.itemsPerPage.lookAt')}
                </p>
                <select
                    onChange={(e: any) => changeItemsPerPage(parseInt(e.target.value))}
                    className="mt-1 pl-3 pr-10 py-2 text-base border-gray-300
                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300 sm:text-sm rounded-md"
                    defaultValue="25"
                    data-testid="select-changeItemsPerPage"
                >
                    <option value={25}>25</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                </select>
                <div className="text-md font-medium text-gray-700 w-full">
                    {t('pagination.itemsPerPage.perPage')}
                </div>
            </div>
            <div className="flex flex-row items-center justify-end space-x-4 w-full">
                { model.getTotalPages() > 0  && <div className="hidden sm:block">
                    <p className="text-md text-gray-900">
                        <span>{t('pagination.page')} </span>
                        <span className="font-medium">
                            {model.value.currentPage}
                        </span>
                        <span> {t('pagination.page.of')} </span>
                        <span className="font-medium">
                            {model.getTotalPages()}
                        </span>
                    </p>
                </div> }
                <div className="flex justify-end space-x-1">
                    <button
                        disabled={model.isCurrentPageAtMinimum()}
                        onClick={() => selectPreviousPage()}
                        className={classNames(model.isCurrentPageAtMinimum() ? 'hover:none text-gray-300' : 'hover:bg-gray-100 text-gray-700', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-md font-medium rounded-md  bg-white disabled:bg-gray-100')}
                        data-testid="button-previousPage"
                    >
                        <ArrowNarrowLeftIcon
                            className={classNames(model.isCurrentPageAtMinimum() ? ' text-gray-300' : 'text-gray-700', 'mr-3 h-5 w-5')}
                            aria-hidden="true"/>
                        {t('pagination.page.prev')}
                    </button>
                    <button
                        disabled={model.isCurrentPageAtMaximum()}
                        onClick={() => selectNextPage()}
                        className={classNames(model.isCurrentPageAtMaximum() ? 'hover:none text-gray-300' : 'hover:bg-gray-100 text-gray-700', 'relative inline-flex items-center px-4 py-2 border border-gray-300 text-md font-medium rounded-md  bg-white disabled:bg-gray-100')}
                        data-testid="button-nextPage"
                    >
                        {t('pagination.page.next')}
                        <ArrowNarrowRightIcon
                            className={classNames(model.isCurrentPageAtMaximum() ? ' text-gray-300' : 'text-gray-700', 'ml-3 h-5 w-5 ')}
                            aria-hidden="true"/>
                    </button>
                </div>
            </div>
        </nav>
    );
}

export default PaginationBar;
