import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Button from '../../buttons/button/Button';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import styles from './ManualContactRequestTab.module.css';
import ButtonSize from '../../buttons/button/logic/models/buttonSize';
import IconType from '../../buttons/button/logic/models/iconType';

interface ManualContactRequestTabProps {
    readonly getURLForContactRequestPage: () => string
}

export default function ManualContactRequestTab(props: ManualContactRequestTabProps) {

    const {t} = useTranslation()

    function openInNewTab(url: string) {
        window.open(url, '_blank', 'noreferrer');
    }

    return (
        <>
            <Text
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                <Trans i18nKey={'listing.details.generateContactRequestLinkModal.tab.manual.description'} components={{
                    p: <p style={{marginBottom: '0.5rem'}}/>,
                    ul: <ul style={{textAlign: 'left', listStyle: 'initial', marginLeft: '1rem'}}/>,
                    li: <li style={{marginBottom: 8}}/>
                }}/>
            </Text>
            <div className={`${styles.closeButton}`}>
                <Button
                    title={t('listing.details.generateContactRequestLinkModal.tab.manual.button')}
                    buttonType={ButtonType.Primary}
                    size={ButtonSize.Md}
                    icon={'ri-external-link-line'}
                    iconType={IconType.Trailing}
                    onClick={() => openInNewTab(props.getURLForContactRequestPage())}
                />
            </div>
        </>
    );

}
