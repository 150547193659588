import ColorEnum from '../../../enums/colorEnum'
import {Trans} from 'react-i18next'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'
import Text from '../../typography/text/Text'
import React from 'react'
import CandidateScreeningReportDownloadLinkModel from './models/candidateScreeningReportDownloadLinkModel'
import CandidateModel from '../../../models/candidate/candidateModel'
import styles from './CandidateScreeningReportDownloadLink.module.css'
interface CandidateScreeningReportDownloadLink {
    readonly candidateModel: CandidateModel
}

export default function CandidateScreeningReportDownloadLink(props: CandidateScreeningReportDownloadLink) {
    const candidateModel = CandidateScreeningReportDownloadLinkModel.createFromCandidate(props.candidateModel)

    return <div className={styles.candidateScreeningReportDownloadLink}>
        <i className="ri-download-2-line"></i>
        {props.candidateModel.hasDoneScreening() ?
            <Text size={FontSizeEnum.Sm} color={ColorEnum.Primary600} weight={FontWeightEnum.Medium}>
                {<Trans i18nKey={candidateModel.showDownloadReportText()}/>}
            </Text>: ''
        }
    </div>
}
