import React from 'react';

export default function FormSuccess() {
    return (
        <div className='min-h-screen w-screen bg-gray-100'>
            <div className='w-screen h-screen flex flex-col items-center justify-center px-4'>
                <div className='bg-white shadow-md rounded-xl sm:w-[500px] px-4 md:px-6 lg:px-8 min-h-1/2 py-10 text-center space-y-6'>
                    <i className="ri-checkbox-circle-fill text-green-600 text-9xl"></i>
                    <h1 className='font-bold text-3xl'>Informatie is succesvol gedeeld met de makelaar</h1>
                    <p className='text-xl'>De makelaar gaat je gegevens doornemen om te kijken of er een match is met de woning.</p>
                    <hr />
                    <h1 className='font-bold text-3xl'>Information successfully shared with agent</h1>
                    <p className='text-xl'>The agent will assess whether your profile matches with the listing.</p>
                </div>
            </div>
        </div>
    );
}
