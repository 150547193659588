import ModalType from '../../../enums/modalType';
import ColorEnum from '../../../enums/colorEnum';

interface ModalTheme {
    readonly mainColor: ColorEnum,
    readonly iconColor: ColorEnum,
}

export default function convertModalTypeToModalTheme(type: ModalType): ModalTheme {
    switch (type) {
        case ModalType.Error:
            return {
                mainColor: ColorEnum.Error100,
                iconColor: ColorEnum.Error600,
            }
        case ModalType.Info:
        default:
            return {
                mainColor: ColorEnum.Primary100,
                iconColor: ColorEnum.Primary600,
            }
    }
}
