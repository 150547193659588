import CandidateScreeningRequestStatusEnum from '../../enums/candidateScreeningRequestStatusEnum';
import CandidateScreeningRequestOutcomeEnum from '../../enums/candidateScreeningRequestOutcomeEnum';
import ImmutableModel from '../immutableModel/immutableModel'
import CoTenantModel from './coTenantModel'

interface ScreeningRequestModelProps {
    readonly id: string
    readonly rating: number | null
    readonly status: CandidateScreeningRequestStatusEnum | null
    readonly outcome: CandidateScreeningRequestOutcomeEnum | null
    readonly coTenants: ReadonlyArray<CoTenantModel>
}

export default class ScreeningRequestModel extends ImmutableModel<ScreeningRequestModel, ScreeningRequestModelProps> {
}
