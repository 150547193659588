import React from 'react'
import styles from './PageHeader.module.css'
import Typography from '../../atoms/typography/Typography'
import {useTranslation} from 'react-i18next'

interface props {
    readonly title: string
}

const PageHeader = ({title}: props) => {
    const {t} = useTranslation()

    return (
        <header className={styles.container}>
            <Typography type='h1'>
                {t(title)}
            </Typography>
        </header>
    )
}

export default PageHeader
