import ColorEnum from '../../../enums/colorEnum'
import style from './FlowyFigure.module.css'

export interface flowyFigureProps {
    readonly color: ColorEnum
}

export default function FlowyFigure(props: flowyFigureProps) {
    function getColorCssVar(): string {
        return `var(--${props.color})`
    }

    return (
        <div className={style.flowyFigure}>
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="133" viewBox="0 0 120 133" fill="none">
                <path
                    fill={getColorCssVar()}
                    d="M-68.9996 69.5231C-59.9446 85.2068 -47.8891 98.9535 -33.5215 109.978C-19.1538 121.003 -2.75542 129.09 14.7375 133.777C32.2304 138.464 50.4753 139.66 68.4303 137.296C86.3854 134.932 103.699 129.055 119.383 120L82.0411 55.3222C74.8509 59.4734 66.9136 62.1678 58.6822 63.2515C50.4508 64.3351 42.0865 63.7869 34.067 61.6381C26.0475 59.4893 18.5297 55.7819 11.943 50.7277C5.35621 45.6735 -0.17056 39.3715 -4.32177 32.1813L-68.9996 69.5231Z"
                />
            </svg>
        </div>
    )
}
