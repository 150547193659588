import moment from 'moment'

export default class FormattedDateLabelModel {

    constructor(
        public readonly date: Date | null
    ) { }

    public getTimeFromNow(): string {
        return this.date !== null ? moment(this.date).fromNow() : '-'
    }
}
