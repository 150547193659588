import styles from './ErrorPageFragment.module.css';
import {useTranslation} from 'react-i18next';

export default function ErrorPageFragment() {
    const {t} = useTranslation()

    return (
        <div className={styles.titles}>
            <i className={'ri-close-circle-fill ' + styles.icon}/>
            <h1 className="text-2xl font-bold text-primary-700">{t('marketingMessagesSubscribe.error.header')}</h1>
            <p>{t('marketingMessagesSubscribe.error.text')}</p>
        </div>
    )
}
