import AgentModel from '../models/agent/agentModel'

export interface AgentJSON {
    readonly id: string
    readonly names: ReadonlyArray<string>
    readonly platform: string
    readonly createdAt: string
}

export default class AgentJsonToModelConverter {
    
    public static convert(obj: AgentJSON): AgentModel {
        return new AgentModel(
            obj.names,
            obj.platform
        )
    }

}
