import style from './TenantCard.module.css';
import Text from '../../../../typography/text/Text'
import FontSizeEnum from '../../../../typography/enums/fontSizeEnum'
import ColorEnum from '../../../../../enums/colorEnum'
import FontWeightEnum from '../../../../typography/enums/fontWeightEnum'
import CandidateScreeningStatusBadge
    from '../../../../molecules/candidateScreeningStatusBadge/CandidateScreeningStatusBadge'
import CandidateScreeningRequestStatusEnum from '../../../../../enums/candidateScreeningRequestStatusEnum'
import ContactInformation from '../contactInformation/ContactInformation';

interface TenantScreeningCardProps {
    readonly fullName: string
    readonly screeningStatus: CandidateScreeningRequestStatusEnum,
    readonly hideHomeseekerDetails?: boolean,
    readonly phone?: string,
    readonly email?: string,
    readonly birthday?: string,
}

export default function TenantScreeningCard(props: TenantScreeningCardProps) {
    const homeseekerDetailsHidden = () => {
        return props.hideHomeseekerDetails !== true
    }

    return <div className={style.root}>
        <div className={style.container}>
            <div className={style.header}>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Semibold}>
                    {`${props.fullName}` || '-'}
                </Text>
                <CandidateScreeningStatusBadge screeningStatus={props.screeningStatus} />
            </div>
            {homeseekerDetailsHidden() &&
                <ContactInformation
                    birthday={props.birthday}
                    email={props.email}
                    phone={props.phone}
                />
            }
        </div>
    </div>
}
