import ApiService from '../enums/apiService';

if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-undef
    (window as any).REACT_APP_LEADFLOW_CANDIDATES_API = process.env.REACT_APP_LEADFLOW_API;
    // eslint-disable-next-line no-undef
    (window as any).REACT_APP_LEADFLOW_LEADS_API = process.env.REACT_APP_LEADFLOW_LEADS_API;
}
export function getServiceUrl(service: ApiService): string {
    switch (service) {
        case ApiService.Candidates:
            return `${(window as any).REACT_APP_LEADFLOW_CANDIDATES_API}`
        case ApiService.Leads:
            return `${(window as any).REACT_APP_LEADFLOW_LEADS_API}`
        default:
            return `${(window as any).REACT_APP_LEADFLOW_CANDIDATES_API}`
    }
}
