
const initialState = {
    data: {
        totalItems: 0,
        listings: []
    },
    selectedListing: {
        details: {},
        candidates: {
            totalItems: 0,
            items: []
        },
        image: '',
        description: '',
        availabilityInDays: 0,
        onlineSinceInDays: 0,
        numberOfLeads: 0,
        numberOfLeadsToAssess: 0,
        numberOfMessages: 0,
        numberOfNotSuitableLeads: 0,
        numberOfRejectedLeads: 0,
        numberOfSuitableLeads: 0,
        numberOfUnassessedLeads: 0,
        numberOfUnreadMessages: 0,
        hasTenant: false,
        status: '',
        closedAt: null
    },
    error: null,
    isLoading: false,
    errorMessage: '',

};

export default function (state = initialState, action = {}) {
    const {payload, type} = action;
    switch (type) {
        case 'GET_LISTINGS':
            return {
                ...state, data: {
                    totalItems: payload.totalItems,
                    listings: payload.result
                }
            };
        case 'GET_LISTINGS_OLD':
            return {
                ...state, data: {
                    totalItems: payload['hydra:totalItems'],
                    listings: payload['hydra:member']
                }
            };
        case 'GET_LISTING_DETAILS': {
            return {
                ...state,
                selectedListing: {
                    ...state.selectedListing,
                    details: {
                        available_at: payload.availableAt,
                        city: payload.city,
                        description: payload.description,
                        enrichment: payload.enrichment,
                        house_number: payload.houseNumber,
                        image_url: payload.imageUrl,
                        link: payload.link || payload.url,
                        listing_id: payload.id,
                        online_since: payload.onlineSince,
                        origin_id: payload.originId,
                        origin_name: payload.originName,
                        postal_code: payload.postalCode,
                        price: payload.price,
                        rooms: payload.rooms,
                        street: payload.street,
                        surface: payload.surface
                    },
                    id: payload.id,
                    image: payload.imageUrl,
                    description: payload.description,
                    availabilityInDays: payload.availabilityInDays,
                    numberOfMessages: payload.numberOfMessages,
                    numberOfUnreadMessages: payload.numberOfUnreadMessages,
                    hasTenant: payload.hasTenant,
                    closedAt: payload.closedAt || undefined,
                    status: payload.status,
                    numberOfLeads: payload.numberOfLeads,
                    numberOfLeadsToAssess: payload.numberOfLeadsToAssess,
                    numberOfNotSuitableLeads: payload.numberOfNotSuitableLeads,
                    numberOfRejectedLeads: payload.numberOfRejectedLeads,
                    numberOfSuitableLeads: payload.numberOfSuitableLeads,
                    numberOfUnassessedLeads: payload.numberOfUnassessedLeads
                }
            };
        }
        case 'SET_CANDIDATE_COUNT_PER_SELECTED_LISTING':
            return {
                ...state, selectedListing: {
                    ...state.selectedListing,
                    candidates: {
                        items: payload['result'],
                        totalItems: payload['totalItems']
                    }
                }
            };
        case 'SET_AUTO_ENRICHING_MODE':
            return {
                ...state,
                selectedListing: {
                    ...state.selectedListing,
                    details: {
                        ...state.selectedListing.details,
                        enrichment: payload
                    }
                }
            };
        default:
            return state;
    }
}
