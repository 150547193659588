import styles from './IconLabel.module.css'

export interface IconLabelProps {
    readonly icon: string
    readonly label: string
}

export default function IconLabel(props: IconLabelProps) {
    return (
        <div className={styles.root}>
            <i className={`${props.icon} font-medium`}></i>
            <p className='flex-row flex mr-5 py-2'>
                {`${props.label}`}
            </p>
        </div>
    )
}
