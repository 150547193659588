import React, {useEffect, useState} from 'react'
import styles from './Loading.module.css'
import logo from '../../../assets/img/logo.png'
import {Trans, useTranslation} from 'react-i18next'

const Loading = () => {
    const [display, setDisplay] = useState(false)

    const { t } = useTranslation()

    useEffect(() => {
        // Delay content display to prevent flashing on quick loads
        setTimeout(() => {
            setDisplay(true)
        }, 100)
    }, [])

    return (
        <div className={styles.loadingBox}>
            {display &&
                <div className={styles.loadingInnerBox}>
                    <img className={styles.logo} src={logo} alt="Logo"/>
                    <p className={`${styles.loadingTitle} text-primary text-2xl font-bold`}>{t('loadingPage.title')}</p>
                    <p className="text-primary text-1xl text-center">
                        <Trans
                            i18nKey="loadingPage.description"
                            components={{Link: <a style={{textDecoration: 'underline'}} />}}
                        />
                    </p>
                </div>
            }
        </div>
    )
}

export default Loading
