import CandidateScreeningRequestStatusEnum from '../../enums/candidateScreeningRequestStatusEnum'
import ImmutableModel from '../immutableModel/immutableModel'

interface CoTenantModelProps {
    readonly id: string
    readonly fullName: string
    readonly email: string
    readonly phoneNumber: string
    readonly outcome: string
    readonly screeningRequestStatus: CandidateScreeningRequestStatusEnum
}

export default class CoTenantModel extends ImmutableModel<CoTenantModel, CoTenantModelProps> {
}
