import React, {ReactNode} from 'react';
import styles from './PageContainer.module.css';
import { ColorEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum';

interface PageContainerProps {
    readonly children: ReactNode;
    readonly backgroundColor?: ColorEnum;
}

const PageContainer = ({
    children,
    backgroundColor = ColorEnum.White
}: PageContainerProps) => {
    return (
        <div
            className={styles.PageContainer}
            style={{ backgroundColor: getColorCssVar(backgroundColor) }}
        >
            <div className={styles.wrapper}>
                {children}
            </div>
        </div>
    );
};

export default PageContainer;
