import React, {ChangeEvent} from 'react';
import styles from './RadioButton.module.css';
import CheckedRadioIcon from '../../generics/radioIcon/CheckedRadioIcon'
import UncheckedRadioIcon from '../../generics/radioIcon/UncheckedRadioIcon';

interface RadioButtonProps {
    readonly id?: string
    readonly name?: string
    readonly isChecked?: boolean
    readonly isDisabled?: boolean
    readonly value?: string | number
    readonly onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    readonly onClick?: (e: React.MouseEvent<HTMLInputElement>) => void

}

export default function RadioButton(props: RadioButtonProps) {

    function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
        if (props.onChange) {
            props.onChange(e)
        }
    }

    function handleOnClick(e: React.MouseEvent<HTMLInputElement>) {
        if (props.onClick) {
            props.onClick(e)
        }
    }

    return (
        <label className={styles.container}>
            <input
                id={props.id ? props.id : ''}
                name={props.name}
                type="radio"
                disabled={props.isDisabled}
                checked={props.isChecked}
                value={props.value}
                onChange={handleOnChange}
                onClick={handleOnClick}
            />
            <span className={styles.checkmark}>
                <div className={styles.unchecked}><UncheckedRadioIcon/></div>
                <div className={styles.checked}><CheckedRadioIcon/></div>
            </span>
        </label>
    )
}
