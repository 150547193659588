enum ActionType {
    None = 'None',
    Approve = 'Approve',
    MoveToWaitingList = 'Queue',
    Reject = 'Reject',
    RejectWithoutMessage = 'RejectWithoutMessage',
    SendMessage = 'SendMessage',
    Invite = 'Invite',
    RequestExtraInfo = 'RequestExtraInfo',
    Screen = 'Screen',
    AssignTenant = 'AssignTenant'
}

export default ActionType
