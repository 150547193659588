import { combineReducers } from 'redux';
import listings from './listings';
import agents from './agents';
import users from './users';
import ui from './ui';
import candidates from './candidates';
import viewings from './viewings';

export default combineReducers({
    listings,
    agents,
    users,
    candidates,
    viewings,
    ui,
});

