import AnnouncementPageFrame from '../../components/announcementPageFrame/AnnouncementPageFrame';
import SuccessPageFragment from './fragments/success/SuccessPageFragment';
import ConfirmationPageFragment from './fragments/confirmation/ConfirmationPageFragment';
import ErrorPageFragment from '../../components/errorPageFragment/ErrorPageFragment';
import {useEffect, useRef, useState} from 'react';
import LoadStateEnum from '../../enums/loadStateEnum';
import {useParams} from 'react-router-dom';
import Loading from '../../components/empty-states/loading/Loading';
import MarketingSubscribeSuccessPageFragment from '../marketingMessagesSubscribe/fragments/success/SuccessPageFragment';
import { growthbook } from '../../utils/analytics';
import EmailAddressConfirmationService from '../../services/emailAddressConfirmationService';

export default function EmailAddressConfirmationPage() {
    const [loadState, setLoadState] = useState<LoadStateEnum | undefined>()
    const {id, listingCity} = useParams()
    const isMarketingMessagesSubscriptionConfirmed = useRef(true)
    const emailAddressConfirmationService = useRef(new EmailAddressConfirmationService())

    useEffect(() => {
        growthbook.setAttributes({
            ...growthbook.getAttributes(),
            id,
        });
    }, [id])

    async function handleConfirmationSubmitButtonClick(isConfirmed: boolean) {
        isMarketingMessagesSubscriptionConfirmed.current = isConfirmed

        await confirmEmailAddress()
        if (isMarketingMessagesSubscriptionConfirmed.current === true) {
            await subscribeToMarketingMessages()
        }
    }

    async function confirmEmailAddress() {
        setLoadState(LoadStateEnum.Loading)
        try {
            await emailAddressConfirmationService.current.confirmHomeseekerEmail(id)
            setLoadState(LoadStateEnum.Success)
        } catch(e) {
            setLoadState(LoadStateEnum.Error)
        }
    }

    async function subscribeToMarketingMessages() {
        setLoadState(LoadStateEnum.Loading)
        try {
            await emailAddressConfirmationService.current.subscribeHomeseekerToMarketingMessages(id)
            setLoadState(LoadStateEnum.Success)
        } catch(e) {
            setLoadState(LoadStateEnum.Error)
        }
    }

    return (
        <>
            {loadState === LoadStateEnum.Loading ?
                <Loading/> :
                <AnnouncementPageFrame>
                    {loadState === undefined && <ConfirmationPageFragment
                        onError={() => setLoadState(LoadStateEnum.Error)}
                        service={emailAddressConfirmationService.current}
                        homeseekerId={id}
                        isMarketingMessagesSubscriptionConfirmed={isMarketingMessagesSubscriptionConfirmed.current}
                        onSubmitButtonClick={(isMarketingMessagesSubscriptionConfirmed) => {
                            handleConfirmationSubmitButtonClick(isMarketingMessagesSubscriptionConfirmed)
                        }}
                    />}
                    {loadState === LoadStateEnum.Success &&
                        (isMarketingMessagesSubscriptionConfirmed.current === true ? <MarketingSubscribeSuccessPageFragment listingCity={listingCity} /> : <SuccessPageFragment/>)
                    }
                    {loadState === LoadStateEnum.Error && <ErrorPageFragment/>}
                </AnnouncementPageFrame>
            }
        </>
    )
}
