import React, {useState} from 'react';
import EmailMessageForm from '../tabs/EmailMessageForm';
import CandidateModel from '../../models/candidate/candidateModel'
import Modal from '../organisms/modal/Modal'
import ColorEnum from '../../enums/colorEnum'
import FontSizeEnum from '../typography/enums/fontSizeEnum'
import FontWeightEnum from '../typography/enums/fontWeightEnum'
import Text from '../typography/text/Text'
import {useTranslation} from 'react-i18next'
import style from './SendMessageModal.module.css'

interface SendMessageModalProps {
    readonly receiversEmail: string  | null,
    readonly isOpen: boolean,
    readonly setIsSendMessageModelOpen: (boolean: boolean) => void,
    readonly sendMessageToCandidate: (subject: string, message: string, candidates: ReadonlyArray<CandidateModel>) => void,
    readonly selectedPeople: ReadonlyArray<CandidateModel>
}

function SendMessageModal(props: SendMessageModalProps) {
    const [message, setMessage] = useState('')
    const [subject, setSubject] = useState('')
    const {t} = useTranslation()

    const handleCloseModal = () => {
        props.setIsSendMessageModelOpen(false);
        setMessage('');
        setSubject('');
    };

    const onChangeSubject = (subject: string) => {
        setSubject(subject)
    }

    const onChangeMessage = (message: string) => {
        setMessage(message)
    }

    const onClose = () => {
        props.sendMessageToCandidate(subject, message, props.selectedPeople)
        setMessage('');
        setSubject('');
        props.setIsSendMessageModelOpen(false);
    }

    return (
        <Modal
            icon={'ri-mail-line'}
            iconColor={ColorEnum.Primary600}
            iconBackgroundColor={ColorEnum.Primary100}
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            onCloseButtonClick={() => handleCloseModal()}
        >
            <div className='min-w-full'>
                <div className="grid grid-cols-6 gap-4">
                    <div className="col-span-5">
                        <div className={style.titleContainer}>
                            <Text
                                size={FontSizeEnum.Lg}
                                weight={FontWeightEnum.Bold}
                                color={ColorEnum.Gray800}
                            >
                                {t('candidate.action.sendMessage.title')}
                            </Text>
                        </div>
                    </div>
                </div>
                <div className='h-full'>
                    <EmailMessageForm
                        selectedPeople={props.selectedPeople}
                        receiversEmail={props.receiversEmail}
                        subject={subject}
                        message={message}
                        onChangeSubject={onChangeSubject}
                        onChangeMessage={onChangeMessage}
                        sendMessageToCandidate={() => onClose()}
                    />
                </div>
            </div>

        </Modal>

    );
}

export default SendMessageModal;
