import style from './IconWithFlowyBackground.module.css'
import IconBackgroundFlowyFigure from './IconBackgroundFlowyFigure'
import React from 'react'

interface IconWithFlowyBackgroundProps {
    readonly icon: string
}

export default function IconWithFlowyBackground(props: IconWithFlowyBackgroundProps) {
    return (
        <div className={style.iconContainer}>
            <i className={`${props.icon} ${style.icon}`}/>
            <IconBackgroundFlowyFigure/>
        </div>
    )
}
