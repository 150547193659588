import {Trans, useTranslation} from 'react-i18next';
import {ThumbUpIcon} from '@heroicons/react/outline';
import HorizontalDivider from '../../../../components/generics/horizontalDivider/HorizontalDivider';
import styles from './SuccessFragment.module.css';
import Text from '../../../../components/typography/text/Text';
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum';
import {useParams} from 'react-router-dom';
import ColorEnum from '../../../../enums/colorEnum';

export default function SuccessFragment() {
    const {id} = useParams()
    const {t} = useTranslation()

    return (
        <>
            <div className={styles.titles}>
                <div className={styles.iconFrame}>
                    <ThumbUpIcon
                        width="48"
                        height="48"
                        className="text-success-700"
                    />
                </div>
                <h1 className="text-2xl font-bold text-primary-700">
                    {t('marketingMessagesUnsubscribe.success.header')}
                </h1>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    <Trans
                        i18nKey="marketingMessagesUnsubscribe.success.text"
                        components={{Link: <a/>, strong: <strong/>}}
                        values={{homeseekerId: id}}
                    />
                </Text>
                <HorizontalDivider/>

                <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                    {t('marketingMessagesUnsubscribe.smallText.part1')}
                </Text>

                <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                    {t('marketingMessagesUnsubscribe.smallText.part2')}
                </Text>
            </div>
        </>
    )

}
