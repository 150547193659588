import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ButtonLink from '../generics/ButtonLink';

function ViewingInvitationSubmissionFailed({retry}) {
    const { t } = useTranslation();

    return (
        <>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t('viewing.invitation.failure.title')}
            </h3>
            <p className='text-base text-gray-600 whitespace-pre-line'>
                {t('viewing.invitation.failure.body')}
                <br/><br/>
                <ButtonLink onClick={() => retry()}>
                    {t('viewing.invitation.failure.link.text')}
                </ButtonLink>
            </p>
        </>
    );
}

ViewingInvitationSubmissionFailed.propTypes = {
    retry: PropTypes.func.isRequired,
};

export default ViewingInvitationSubmissionFailed;
