import styles from './ContactInformation.module.css';
import { renderWork } from '../../../../../utils/renders';

interface ContactInformationProps {
    readonly birthday:string,
    readonly email: string,
    readonly phone: string,
    readonly employment?: string,
}

export default function ContactInformation(props: ContactInformationProps) {

    const isEmpoylmentUndefined = () => {
        return props.employment !== undefined
    }

    return <div className={styles.detailsContainer}>

        <div className="h-full">
            <i className="flex ri-cake-2-line flex-col"></i>
        </div>
        <p className="mb-0.5 text-base text-gray-900 break-all w-full whitespace-normal">{props.birthday || '-'}</p>

        <div className="h-full">
            <i className="flex ri-mail-line flex-col"></i>
        </div>
        <p className="mb-0.5 text-base text-gray-900 break-all w-full whitespace-normal">{props.email || '-'}</p>

        <div>
            <i className="flex ri-phone-line flex-col"></i>
        </div>
        <p className="mb-0.5 text-base text-gray-900">{props.phone || '-'}</p>

        {isEmpoylmentUndefined() &&
            <>
                <div>
                    <i className="flex ri-briefcase-line flex-col"></i>
                </div>
                <p className="mb-0.5 text-base text-gray-900">{renderWork(props.employment)}</p>
            </>
        }
    </div>
}
