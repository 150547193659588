import ScreeningModalServiceInterface from '../components/modals/screeningModal/service/screeningModalServiceInterface'
import api from '../utils/api'
import {setNotification} from '../redux/actions/ui/setNotification'
import store from '../redux/store'
import {t} from 'i18next'

export default class ScreeningModalService implements ScreeningModalServiceInterface {
    public async activateScreening(): Promise<void> {
        await api.post('api/activate-screening-service-for-my-agency')
    }

    public async sendCandidateScreeningRequestInvite(homeseekerApplicationId: string, allowCoTenants: boolean): Promise<void> {
        await api.post('api/send-candidate-screening-request-invite', { homeseekerApplicationId, allowCoTenants })

        store.dispatch(setNotification({
            id: Date.now(),
            message: t('candidate.screening.success'),
            type: 'success'
        }))
    }

    public async updateTransactionalListingPrice(listingId: string, priceInEuros: string): Promise<void> {
        await api.post('api/update-transactional-listing-price', {listingId, priceInEuros})
    }

    public updateTransactionalListingPriceSuccess(): void {
        store.dispatch(setNotification({
            id: Date.now(),
            message: t('listing.update_rental_price.success'),
            type: 'success'
        }))
    }

    public updateTransactionalListingPriceFailed(): void {
        store.dispatch(setNotification({
            id: Date.now(),
            message: t('listing.update_rental_price.failed'),
            type: 'failed'
        }))
    }

    public async rememberNotToShowExplanationAnymore(): Promise<void> {
        window.localStorage.setItem('isExtraExplanationShowing', 'false')
    }

    public async getIsExtraExplanationShowing(): Promise<boolean> {
        const result = window.localStorage.getItem('isExtraExplanationShowing')
        return Promise.resolve(result === 'false' ? false : true)
    }
}
