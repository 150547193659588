import { request } from '../../../utils/api';
import { setNotification } from '../ui/setNotification';

export const setCandidateNote = (token, candidateId, value) => (dispatch) => {
    dispatch({ type: 'START_LOADING' });
    request(token)
        .post('/api/lead_notes', {
            note: value,
            candidate: `/api/candidates/${candidateId}`,
        })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                dispatch(setNotification({
                    id: Date.now(),
                    message: 'Notitie opgeslagen.',
                    type: 'success'
                }));
            }
        })
        .catch((e) => {
            dispatch({ type: 'STOP_LOADING' });
            console.log(e);
        })
        .finally(() => dispatch({ type: 'STOP_LOADING' }));
};
