import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'

export interface CheckedRadioIconProps {
    readonly radioColor?: ColorEnum
}

export default function CheckedRadioIcon(props: CheckedRadioIconProps) {
    function getColorCssVar(): string {
        if (props.radioColor === undefined) {
            return colorEnumAsCssVar(ColorEnum.Primary50);
        }
        return colorEnumAsCssVar(props.radioColor);
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <rect x="1" y="0.5" width="15" height="15" rx="7.5" fill={getColorCssVar()}/>
            <rect x="1" y="0.5" width="15" height="15" rx="7.5" stroke="#054EA8"/>
            <circle cx="8.5" cy="8" r="3" fill="#054EA8"/>
        </svg>
    )
}
