import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import DiscardedLeadModel from './discardedLeadModel'

export interface DiscardedLeadsPageModelProps {
    readonly agencyName: string
    readonly discardedLeads: ReadonlyArray<DiscardedLeadModel>
    readonly selectedLeadIndex?: number
    readonly isModalOpen: boolean
}

export default class DiscardedLeadsPageModel extends ImmutableModel<DiscardedLeadsPageModel, DiscardedLeadsPageModelProps> {

    public static createEmpty(): DiscardedLeadsPageModel {
        return new DiscardedLeadsPageModel({
            agencyName: '',
            discardedLeads: [],
            isModalOpen: false
        })
    }

    public getSelectedLeadStrippedTemplate(): string {
        const selectedInquery = this.value.discardedLeads[this.value.selectedLeadIndex]
        return selectedInquery ? selectedInquery.getStrippedTemplate() : ''
    }
}
