export default abstract class ImmutableModel<M extends ImmutableModel<M, P>, P> {

    constructor(public readonly value: P) {}

    public with(props: Partial<P>): M {
        return new (this.constructor as { new(value: P): M })({
            ...this.value,
            ...props,
        } as P)
    }
}
