import ListingPropertiesModel from '../../../../../../listings/listingProperties/logics/model/listingPropertiesModel';
import AddressModel from '../../../../../../../models/listing/addressModel';
import ListingStatusTimerModel from '../../../../../../../models/listing/listingStatusTimerModel';

export default class AddressContentModel {
    constructor(
        public readonly address: AddressModel,
        public readonly listingStatusTimer: ListingStatusTimerModel,
        public readonly listingProperties: ListingPropertiesModel
    ) { }

    public isInfoVisible(): boolean {
        return this.listingProperties.isSurfaceVisible() || this.listingProperties.isRoomsVisible() || this.listingProperties.isPriceVisible()
    }
}
