import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import {ReactNode, useEffect, useState} from 'react'
import style from './Banner.module.css'
import CloseButton from '../../atoms/closeButton/CloseButton'

interface BannerProps {
    readonly isShown?: boolean
    readonly backgroundColor: ColorEnum
    readonly borderColor: ColorEnum
    readonly icon?: string
    readonly iconColor?: ColorEnum
    readonly isCloseButtonHidden?: boolean
    readonly closeButtonColor?: ColorEnum
    readonly children: ReactNode
    readonly onCloseButtonClick?: () => void
}

export default function Banner(props: BannerProps) {

    const [isShown, setIsShown] = useState(true)

    useEffect(() => {
        setIsShown(props.isShown ? props.isShown : true)
    }, [props.isShown])

    function handleCloseButtonClick() {
        setIsShown(false)
        props.onCloseButtonClick?.()
    }

    return (
        <div
            className={style.root}
            style={{
                display: isShown ? 'block' : 'none',
                backgroundColor: colorEnumAsCssVar(props.backgroundColor),
                borderColor: colorEnumAsCssVar(props.borderColor),
            }}
        >
            <div className={style.contentContainer}>
                {props.icon &&
                    <i
                        style={{color: colorEnumAsCssVar(props.iconColor)}}
                        className={`${props.icon} ${style.icon}`}
                    ></i>
                }
                <div className={style.content}>
                    {props.children}
                </div>
                {!props.isCloseButtonHidden &&
                    <CloseButton
                        onClick={() => handleCloseButtonClick()}
                        color={props?.closeButtonColor}
                    />
                }
            </div>
        </div>
    )
}
