import {request} from '../../../utils/api';
import {getListingDetails} from '../listings/getListingDetails';
import {setNotification} from '../ui/setNotification';
import {getCandidateDetails} from './getCandidateDetails';

export const changeCandidateStatus = (token, ids, transition, listingId, params, isNotified = false) => dispatch => {
    dispatch({type: 'START_LOADING'});
    request(token).post(`/api/bulk/candidates/status/${transition}`, {
        candidatesIds: [...ids],
        isNotified: isNotified
    })
        .then(() => dispatch(setNotification({type: 'success', message: 'Kandidaat status aangepast', id: Date.now()})))
        .then(() => {
            (listingId && params) ?
                dispatch(getListingDetails(token, listingId, params)) :
                dispatch(getCandidateDetails(token, ids[0]));
        })
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            console.log(e);
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));

};
