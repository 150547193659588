import React from 'react';
import styles from './Link.module.css';
import {useNavigate} from 'react-router-dom';

interface Props {
    url: string;
    children: string;
}

const Link = ({url, children}: Props) => {
    const navigate = useNavigate();

    return (
        <button data-testid="button-declined" className={styles.linkButton} onClick={() => navigate(url)}>
            {children}
        </button>
    );
};

export default Link;
