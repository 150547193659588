import { useTranslation } from 'react-i18next';
import ButtonGroupItemModel from '../logic/model/buttonGroupItemModel';
import { useActionTypeToIcon } from '../../../hooks/useActionTypeToIcon';
import ActionType from '../../../enums/actionType';
import { FunctionComponent } from 'react';
import ButtonGroupItemPosition from './enums/buttonGroupItemPosition';

export interface ButtonGroupItemProps {
    readonly model: ButtonGroupItemModel
    readonly position?: ButtonGroupItemPosition
    readonly onClick: (actionType: ActionType) => void
}

const ButtonGroupItem: FunctionComponent<ButtonGroupItemProps> = (props: ButtonGroupItemProps) => {

    const { t } = useTranslation()
    const toIcon = useActionTypeToIcon()

    function getBorderTailwindClass(): string {
        if (props.position === ButtonGroupItemPosition.FirstAndLast) {
            return 'rounded-md border'
        }
        if (props.position === ButtonGroupItemPosition.First) {
            return 'rounded-l-md border pr-[17px] border-r-transparent'
        }
        if (props.position === ButtonGroupItemPosition.Last) {
            return 'rounded-r-md border'
        }
        return 'border pr-[17px] border-r-transparent'
    }

    return (
        <button
            data-testid="button"
            disabled={props.model.isDisabled}
            onClick={() => props.onClick(props.model.actionType)}
            className={`
                relative inline-flex items-center ${getBorderTailwindClass()} border-gray-300 px-4 py-2 gap-[5px] text-sm
                font-medium text-gray-700 hover:bg-gray-50 box-border
                focus:z-10 focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                ${props.model.isDisabled ? 'bg-transparent text-gray-200 hover:bg-gray-50 cursor-not-allowed' : 'hover:bg-gray-50'}`
            }
        >
            <i
                data-testid="icon"
                className={`
                    ${toIcon(props.model.actionType)}
                    ${props.model.isDisabled ? `bg-transparent text-gray-200 cursor-not-allowed` : undefined}
                    h-5 mr-1 
            `}></i>
            <span
                data-testid="label"
                className={props.model.isDisabled ? `bg-transparent text-gray-200 cursor-not-allowed` : undefined}
            >
                {t(props.model.label)}
            </span>
        </button>
    )
}

ButtonGroupItem.defaultProps = {
    position: ButtonGroupItemPosition.Intermediate
}

export default ButtonGroupItem
