import ImmutableModel from '../../../../../models/immutableModel/immutableModel';

interface PaginationBarModelProps {
    readonly totalItems: number
    readonly itemsPerPage: number
    readonly currentPage: number
}

export default class PaginationBarModel extends ImmutableModel<PaginationBarModel, PaginationBarModelProps> {

    public isCurrentPageAtMinimum(): boolean {
        return this.value.currentPage <= 1
    }

    public isCurrentPageAtMaximum(): boolean {
        return this.value.currentPage >= this.getTotalPages()
    }

    public getTotalPages(): number {
        if (this.value.totalItems <= 0 || this.value.itemsPerPage <= 0) {
            return 0
        }
        return Math.ceil(this.value.totalItems / this.value.itemsPerPage) || 1
    }

    public withPreviousPage(): PaginationBarModel {
        if (this.isCurrentPageAtMinimum()) {
            return this
        }
        return this.with({ currentPage: this.value.currentPage - 1 })
    }

    public withNextPage(): PaginationBarModel {
        if (this.isCurrentPageAtMaximum()) {
            return this
        }
        return this.with({ currentPage: this.value.currentPage + 1 })
    }
}
