import React from 'react'
import styles from './Typography.module.css'

interface Props {
    readonly children: string
    readonly type: 'h1' | 'h6' | 'st1' | 'p'
}

const Typography = ({children, type}: Props) => {
    switch (type) {
        case 'h1':
            return (
                <h1 className={styles.h1}>{children}</h1>
            )
        case 'h6':
            return (
                <h6 className={styles.h6}>{children}</h6>
            )
        case 'st1':
            return (
                <p className={styles.st1}>{children}</p>
            )
        default:
            return (
                <p className={styles.p}>{children}</p>
            )
    }
}

export default Typography
