import ModalModel from '../../../../modal/logic/models/modalModel';
import ModalButtonModel from '../../../../modal/logic/models/modalButtonModel';
import TranslationModel from '../../../../../models/translationModel';
import UnarchiveListingModalActionType from './unarchiveListingModalActionType';

export default class UnarchiveListingModalModel implements ModalModel<UnarchiveListingModalActionType> {
    constructor(
        public readonly listingId: string,
        public readonly title: TranslationModel,
        public readonly icon: string,
        public readonly isOpen: boolean,
        public readonly isAutoEnrichmentEnabled: boolean,
        public readonly buttons: ReadonlyArray<ModalButtonModel<UnarchiveListingModalActionType>>
    ) {}

    public static createEmpty(): UnarchiveListingModalModel {
        return new UnarchiveListingModalModel(
            '',
            new TranslationModel('listings.unarchive.modal.title', { address: '' }),
            'ri-error-warning-line',
            false,
            false,
            [
                new ModalButtonModel('actions.cancel', false, UnarchiveListingModalActionType.Cancel),
                new ModalButtonModel('actions.move', true, UnarchiveListingModalActionType.Unarchive)
            ]
        )
    }

    public withListingId(val: string): UnarchiveListingModalModel {
        return new UnarchiveListingModalModel(val, this.title, this.icon, this.isOpen, this.isAutoEnrichmentEnabled, this.buttons)
    }

    public withTitle(val: TranslationModel): UnarchiveListingModalModel {
        return new UnarchiveListingModalModel(this.listingId, val, this.icon, this.isOpen, this.isAutoEnrichmentEnabled, this.buttons)
    }

    public withIsOpen(val: boolean): UnarchiveListingModalModel {
        return new UnarchiveListingModalModel(this.listingId, this.title, this.icon, val, this.isAutoEnrichmentEnabled, this.buttons)
    }

    public withIsAutoEnrichmentEnabled(val: boolean): UnarchiveListingModalModel {
        return new UnarchiveListingModalModel(this.listingId, this.title, this.icon, this.isOpen, val, this.buttons)
    }
}
