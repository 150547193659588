import { Trans, useTranslation } from 'react-i18next'
import Text from '../../../../components/typography/text/Text'
import style from './ConfirmationPageFragment.module.css'
import Button from '../../../../components/buttons/button/Button'
import ButtonType from '../../../../components/buttons/button/logic/models/buttonType'
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../../../enums/colorEnum'
import { useEffect, useState } from 'react'
import Checkbox from '../../../../components/forms/checkbox/Checkbox'
import { getGA4CookiebotConsent } from '../../../../utils/analytics'
import EmailAddressConfirmationService from '../../../../services/emailAddressConfirmationService'
import InlineTextLoader from '../../../../components/molecules/inlineLoader/InlineTextLoader'

export interface ConfirmationPageFragmentProps {
    readonly onError: () => void
    readonly service: EmailAddressConfirmationService
    readonly homeseekerId: string
    readonly isMarketingMessagesSubscriptionConfirmed: boolean
    readonly onSubmitButtonClick: (isMarketingMessagesSubscriptionConfirmed: boolean) => void
}

export default function ConfirmationPageFragment(props: ConfirmationPageFragmentProps) {
    const [isMarketingMessagesSubscriptionConfirmed, setIsMarketingMessagesSubscriptionConfirmed] = useState(props.isMarketingMessagesSubscriptionConfirmed)
    const {t} = useTranslation()

    const [redactedEmail, setRedactedEmail] = useState(undefined)
    const [isRedactedEmailLoading, setIsRedactedEmailLoading] = useState(false)

    useEffect(() => {
        getRedactedEmail()
    }, [])

    async function getRedactedEmail() {
        setIsRedactedEmailLoading(true)
        try {
            const redactedEmail = await props.service.getRedactedHomeseekerEmail(props.homeseekerId)
            setRedactedEmail(redactedEmail)
        } catch {
            props.onError()
        }
        setIsRedactedEmailLoading(false)
    }

    function handleSubmitButtonClick() {
        const cookie_consent = getGA4CookiebotConsent()

        if (cookie_consent !== null) {
            const event_data: any = {
                'event': 'email_confirmed',
                'consent': cookie_consent
            };

            (window as any).dataLayer.push(event_data)

            if (isMarketingMessagesSubscriptionConfirmed) {
                event_data.event = 'email_opted_in_marketing_messages';
                (window as any).dataLayer.push(event_data)
            }
        }

        props.onSubmitButtonClick(isMarketingMessagesSubscriptionConfirmed)
    }

    if (isRedactedEmailLoading) {
        return <InlineTextLoader/>
    }

    return (
        <>
            <h1 className="text-2xl font-bold text-primary-700">{t('emailAddressConfirmation.confirmation.title')}</h1>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                {t('emailAddressConfirmation.confirmation.body', { email: redactedEmail })}
            </Text>

            <div className={style.checkboxContainer}>
                <div className={style.checkboxContainerWrapper}>
                    <Checkbox
                        id="emailAddressConfirmation"
                        name="emailAddressConfirmation"
                        isChecked={isMarketingMessagesSubscriptionConfirmed}
                        onChange={(e) => setIsMarketingMessagesSubscriptionConfirmed(e.target.checked)}
                    />
                </div>
                <label htmlFor="emailAddressConfirmation" className={style.text}>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray500}
                    >
                        {t('emailAddressConfirmation.confirmation.checkbox')}
                    </Text>
                </label>
            </div>

            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                <Trans
                    i18nKey="marketingMessagesSubscribe.confirmation.footer"
                    components={{ Link: <a/> }}
                />
            </Text>

            <Button
                title={t('emailAddressConfirmation.confirmation.button')}
                buttonType={ButtonType.Primary}
                isFullWidth
                onClick={() => handleSubmitButtonClick()}
            />
        </>
    )
}
