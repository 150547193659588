import { classNames } from '../../utils';
import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
    className?: string;
}

const LoadingSpinner = ({
    className
}: LoadingSpinnerProps) => {
    return (
        <div className={classNames(styles.LoadingSpinner, className && className)}></div>
    )
}

export default LoadingSpinner;
