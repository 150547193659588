import Tooltip from '../../tooltip/Tooltip'
import Badge from '../../generics/badge/Badge'
import CandidateStatusComboBadgeModel from './models/candidateStatusComboBadgeModel'
import {useTranslation} from 'react-i18next'

interface CandidateStatusComboBadgeProps {
    readonly model: CandidateStatusComboBadgeModel
}

export default function CandidateStatusComboBadge(props: CandidateStatusComboBadgeProps) {
    const {t} = useTranslation()

    return <>
        <Tooltip
            text={t(props.model.tooltipText)}
            isDisabled={props.model.tooltipText === undefined}
        >
            <Badge
                icon={t(props.model.icon)}
                label={t(props.model.label)}
                labelColor={props.model.labelColor}
                iconBackgroundColor={props.model.iconBackgroundColor}
                backgroundColor={props.model.backgroundColor}
                borderColor={props.model.borderColor}
            />
        </Tooltip>
    </>
}
