enum FontSizeEnum {
    Xs = 'xs', // 0.75rem - 12px
    Sm = 'sm', // 0.875rem - 14px
    Md = 'md', // 1rem - 16px
    Lg = 'lg', // 1.125rem - 18px
    Xl = 'xl', // 1.25rem - 20px

    DisplayXs = 'display-xs', // 1.5rem - 24px
    DisplaySm = 'display-sm', // 1.875rem - 30px
    DisplayMd = 'display-md', // 2.25rem - 36px
    DisplayLg = 'display-lg', // 3rem - 48px
    DisplayXl = 'display-xl', // 3.75rem - 60px
    Display2Xl = 'display-2xl', // 4.5rem - 72px
}

export function fontSizeEnumAsCssVar(fontSizeEnum: FontSizeEnum): string {
    return `var(--font-size-${fontSizeEnum})`;
}

export default FontSizeEnum;
