import { request } from '../../../utils/api';
import { API_URIS } from '../../../utils/constants';

export const deleteAgentLogin = (token, loginData) => (dispatch) => {
    request(token).delete(`${API_URIS.AGENT_LOGINS_URI}/${loginData.id}`)
        .catch((e) => console.log(e)).finally((loginData) => {
            dispatch({
                type: 'REMOVE_AGENT_LOGIN',
                payload: loginData,
            });
        });
};
