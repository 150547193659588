import CoTenantModel from '../models/screeningRequest/coTenantModel'
import {stringToEnum} from '../utils/enumType'
import CandidateScreeningRequestStatusEnum from '../enums/candidateScreeningRequestStatusEnum'
import CandidateScreeningRequestOutcomeEnum from '../enums/candidateScreeningRequestOutcomeEnum'

export interface CoTenantJSON {
    readonly id: string
    readonly fullName: string
    readonly email: string
    readonly phoneNumber: string
    readonly status: string
    readonly outcome: string | null
}

export default class CoTenantJsonToModelConverter {
    public static convert(obj: CoTenantJSON): CoTenantModel {
        return new CoTenantModel({
            id: obj.id,
            fullName: obj.fullName,
            email: obj.email,
            phoneNumber: obj.phoneNumber,
            screeningRequestStatus: stringToEnum(obj.status, CandidateScreeningRequestStatusEnum),
            outcome: obj.outcome !== null ? stringToEnum(obj.outcome, CandidateScreeningRequestOutcomeEnum) : null,
        })
    }
}
