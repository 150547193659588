import ButtonGroupItemPosition from '../../components/enums/buttonGroupItemPosition';
import ButtonGroupItemModel from './buttonGroupItemModel';
import ButtonGroupMenuModel from './buttonGroupMenuModel';

export default class ButtonGroupModel {
    private constructor(
        public readonly items: ReadonlyArray<ButtonGroupItemModel>,
        public readonly menu?: ButtonGroupMenuModel,
        public readonly isDisabled = false
    ) { }

    public static create(items: ButtonGroupItemModel[], menu?: ButtonGroupMenuModel, isDisabled = false): ButtonGroupModel {
        return new ButtonGroupModel(
            items.map(i => i.withIsDisabled(isDisabled)),
            menu && menu.updateIsDisabled(isDisabled),
            isDisabled
        )
    }

    public updateIsDisabled(val: boolean): ButtonGroupModel {
        const items = this.items.map(i => i.withIsDisabled(val))
        const menu = this.menu.updateIsDisabled(val)
        return new ButtonGroupModel(items, menu, val)
    }

    public isMenuEmpty(): boolean {
        return this.menu === undefined || this.menu.items.length === 0
    }

    public getItemPosition(item: ButtonGroupItemModel): ButtonGroupItemPosition {
        if (this.isItemFirst(item) && this.isItemLast(item) && this.isMenuEmpty()) {
            return ButtonGroupItemPosition.FirstAndLast
        }
        else if (this.isItemFirst(item)) {
            return ButtonGroupItemPosition.First
        }
        else if (this.isItemLast(item) && this.isMenuEmpty()) {
            return ButtonGroupItemPosition.Last
        }
        return ButtonGroupItemPosition.Intermediate
    }

    private isItemFirst(item: ButtonGroupItemModel): boolean {
        return this.items.findIndex(i => i.label === item.label) === 0
    }

    private isItemLast(item: ButtonGroupItemModel): boolean {
        return this.items.findIndex(i => i.label === item.label) === this.items.length - 1
    }
}
