import Modal from '../../organisms/modal/Modal';
import ColorEnum from '../../../enums/colorEnum';
import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import React from 'react';
import { useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import styles from './GenerateContactRequestLinkModal.module.css';
import CopyContactRequestLinkTab from './CopyContactRequestLinkTab';
import HorizontalNavigationTabs from '../../navigation/HorizontalNavigationTabs';
import NavigationTab from '../../navigation/NavigationTab';
import ManualContactRequestTab from './ManualContactRequestTab';
import SendByEmailContactRequestTab from './SendByEmailContactRequestTab';

interface GenerateContactRequestLinkModalProps {
    readonly isOpen: boolean,
    readonly setIsOpen: (boolean: boolean) => void,
    readonly setIsGenerateContactRequestLinkModalOpen: (boolean: boolean) => void,
}
function GenerateContactRequestLinkModal(props: GenerateContactRequestLinkModalProps) {

    const {t} = useTranslation()
    const [selectedTab, setSelectedTab] = React.useState('copy-link');
    const { id } = useParams();
    function getURLForContactRequestPage() {
        const baseUrl = window.location.origin
        return `${baseUrl}/contact-verzoek/${id}`
    }

    function getSelectedTabContents() {
        switch (selectedTab) {
            case 'copy-link':
                return <CopyContactRequestLinkTab getURLForContactRequestPage={getURLForContactRequestPage}/>
            case 'send-by-email':
                return <SendByEmailContactRequestTab transactionalListingId={id} setIsOpen={props.setIsOpen} />;
            case 'manual':
                return <ManualContactRequestTab getURLForContactRequestPage={getURLForContactRequestPage}/>
            default:
                return null;
        }
    }

    return (
        <Modal
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            isNonScrollable={true}
            onCloseButtonClick={() => props.setIsGenerateContactRequestLinkModalOpen(false)}
            canOverflow={true}
            icon={'ri-link'}
            iconColor={ColorEnum.Primary600}
            iconBackgroundColor={ColorEnum.Primary100}
        >
            <div className={styles.modal}>
                <div>
                    <div className={`${styles.titleSpacing}`}>
                        <Text
                            size={FontSizeEnum.Lg}
                            weight={FontWeightEnum.Bold}
                            color={ColorEnum.Gray800}
                        >
                            {t('listing.details.generateContactRequestLinkModal.title')}
                        </Text>
                    </div>
                </div>
                <div>
                    <div className={styles.horizontalNavigationTabsWrapper}>
                        <HorizontalNavigationTabs>
                            <NavigationTab
                                name={'copy-link'}
                                title={t('listing.details.generateContactRequestLinkModal.tab.copyLink.label')}
                                selectedName={selectedTab} onClick={setSelectedTab}/>
                            <NavigationTab
                                name={'send-by-email'}
                                title={t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.label')}
                                selectedName={selectedTab} onClick={setSelectedTab}/>
                            <NavigationTab
                                name={'manual'}
                                title={t('listing.details.generateContactRequestLinkModal.tab.manual.label')}
                                selectedName={selectedTab} onClick={setSelectedTab}/>
                        </HorizontalNavigationTabs>
                    </div>
                    <div>
                        {getSelectedTabContents()}
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default GenerateContactRequestLinkModal;
