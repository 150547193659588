import {useEffect} from 'react';
import {Routes, Route, Navigate, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useFeature} from '@growthbook/growthbook-react';
import useAuth from './hooks/useAuth';
import {updateMomentLocale} from './components/formattedDateContent/dateUtils';
import styles from './App.module.css';
import LoginPage from './pages/login/LoginPage';
import SSOLoginPage from './pages/login/SSOLoginPage';
import LogoutPage from './pages/LogoutPage';
import ListingsOverview from './pages/dashboard/listings/ListingsOverview';
import ListingDetails from './pages/dashboard/listings/ListingDetails';
import EnrichingFormPage from './pages/enriching-form/EnrichingFormPage';
import CandidateProfileContainer from './pages/dashboard/candidates/CandidateProfileContainer';
import BasicNotification from './components/notifications/BasicNotification';
import AgentsOverview from './pages/admin/AgentsOverview';
import AgentDetails from './pages/admin/agents/AgentDetails';
import SubmitViewingInvitationPage from './pages/viewing/SubmitViewingInvitationPage';
import ViewingStatusInvitationPage from './pages/viewing/ViewingStatusInvitationPage';
import CookieDeclarationPage from './pages/cookies/CookieDeclarationPage';
import MarketingMessagesSubscribePage from './pages/marketingMessagesSubscribe/MarketingMessagesSubscribePage';
import MarketingMessagesUnsubscribePage from './pages/marketingMessagesUnsubscribe/MarketingMessagesUnsubscribePage';
import EmailAddressConfirmationPage from './pages/emailAddressConfirmation/EmailAddressConfirmationPage';
import MergeDuplicateTransactionalListingsPage from './pages/admin/mergeDuplicateTransactionalListings/MergeDuplicateTransactionalListingsPage';
import DiscardedLeadsPage from './pages/admin/discardedLeads/DiscardedLeadsPage';
import ContactRequestPage from './pages/contactRequestPage/ContactRequestPage';
import SettingsPage from './pages/settings/SettingsPage';
import ScheduleViewingPage from './componentsNew/pages/scheduleViewing/ScheduleViewingPage';
import ViewingInvitiationAcceptedPage from './componentsNew/pages/viewingInvitationAccepted/ViewingInvitiationAcceptedPage';
import ViewingInvitationDeclinedPage
    from './componentsNew/pages/viewingInvitationDeclined/ViewingInvitationDeclinedPage';

const App = () => {
    const {isAuthenticated} = useAuth()
    const navigate = useNavigate()
    const {notifications} = useSelector((state: any) => state.ui)
    const REDIRECT_ON_LOGIN = useFeature('redirect_on_login').value

    useEffect(() => {
        updateMomentLocale()
    }, [])

    useEffect(() => {
        if (isAuthenticated && window.ps) {
            window.ps.addMeta({isAuthenticated: true})
        }
    }, []);

    useEffect(() => {
        if (REDIRECT_ON_LOGIN) {
            const redirectToAfterLogin = sessionStorage.getItem('redirectToAfterLogin') || '/dashboard/advertenties/'
            if (isAuthenticated && redirectToAfterLogin) {
                navigate(redirectToAfterLogin, {replace: true})
            }
        }
    }, [isAuthenticated])

    return (
        <div className={styles.App}>
            <Routes>
                <Route path="/cookies" element={<CookieDeclarationPage/>}/>
                <Route path="/logout" element={<LogoutPage/>}/>
                {isAuthenticated ? (
                    <>
                        <Route path="/admin" element={<AgentsOverview/>}/>
                        <Route path="/admin/agents/:id" element={<AgentDetails/>}/>
                        <Route path="/admin/dubbele-advertenties" element={<MergeDuplicateTransactionalListingsPage/>}/>
                        <Route path="/admin/onverwerkbare-leads/:agentId" element={<DiscardedLeadsPage/>}/>
                        <Route path="/admin/onverwerkbare-leads" element={<DiscardedLeadsPage/>}/>
                        <Route path="/applicant-form/:id" element={<EnrichingFormPage/>}/>
                        <Route path="/dashboard/listings" element={<ListingsOverview/>}/>
                        <Route path="/dashboard/listings/:id" element={<ListingDetails/>}/>
                        <Route path="/dashboard/advertenties" element={<ListingsOverview/>}/>
                        <Route path="/dashboard/advertenties/:id" element={<ListingDetails/>}/>
                        <Route path="/dashboard/advertenties/:id/:status" element={<ListingDetails/>}/>
                        <Route path="/dashboard/advertenties/:id/kandidaten/:pid" element={<CandidateProfileContainer/>}/>
                        <Route path="/settings" element={<SettingsPage/>}/>
                        <Route path="/instellingen" element={<SettingsPage/>}/>
                        <Route path="*" element={<Navigate to="/dashboard/advertenties"/>}/>
                    </>
                ) : (
                    <>
                        <Route path="login" element={<LoginPage />} />
                        <Route path="sso-login" element={<SSOLoginPage />} />
                        <Route path="/applicant-form/:id" element={<EnrichingFormPage />} />
                        <Route path="/viewing/:id" element={<SubmitViewingInvitationPage />} />
                        <Route path="/viewing/:id/:status" element={<ViewingStatusInvitationPage />} />
                        <Route path="/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/accepted" element={<ViewingInvitiationAcceptedPage/>}/>
                        <Route path="/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/declined" element={<ViewingInvitationDeclinedPage/>}/>
                        <Route path="/homeseeker/:homeseekerApplicationId/viewing/:viewingId/timeslot/schedule" element={<ScheduleViewingPage />} />
                        <Route path="/subscribe-to-marketing-messages/:id" element={<MarketingMessagesSubscribePage />} />
                        <Route path="/subscribe-to-marketing-messages/:id/:listingCity" element={<MarketingMessagesSubscribePage />} />
                        <Route path="/unsubscribe-from-marketing-messages/:id" element={<MarketingMessagesUnsubscribePage />} />
                        <Route path="/email-address-confirmation/:id" element={<EmailAddressConfirmationPage />} />
                        <Route path="/email-address-confirmation/:id/:listingCity" element={<EmailAddressConfirmationPage />} />
                        <Route path="*" element={<Navigate to={localStorage.getItem('isLoginSSO') === 'true' ? '/sso-login' : '/login'} />} />
                    </>
                )}
                <Route path="/contact-request/:id" element={<ContactRequestPage/>}/>
                <Route path="/contact-verzoek/:id" element={<ContactRequestPage/>}/>
            </Routes>
            {notifications.length > 0 && (
                <div className="fixed inset-0 flex flex-col px-4 py-6 pointer-events-none z-50 justify-end items-end">
                    <>
                        {notifications.map((notif, index) => (
                            <BasicNotification
                                key={index}
                                message={notif.message}
                                id={notif.id}
                                type={notif.type}
                            />
                        ))}
                    </>
                </div>
            )}
        </div>
    )
}

export default App
