import isNumber from './isNumber';

const formatPrice = (price?: number, round = true): string => {
    if (!isNumber(price)) {
        return '-';
    }

    const currency = new Intl.NumberFormat('nl-NL', {
        maximumFractionDigits: round ? 0 : 2,
    });

    return currency.format(price);
}

export default formatPrice;
