import React from 'react'
import PropTypes from 'prop-types'
import { LISTING_STATES } from '../../../utils/constants'

/**
 * @deprecated Only used by Deprecated_ListingsTable.
 * In the new Table ArchiveToggleButton is used.
 */
function Deprecated_ArchiveButton(props) {
    const { id, status, disabled, onClick, openIcon, archivedIcon } = props;
    const isStatusAvailable = status === LISTING_STATES.AVAILABLE;
    return (
        <button
            onClick={() => onClick(id, status)}
            type="button"
            disabled={disabled}
            className="inline-flex items-center px-3 py-1 border
            border-gray-300 shadow-sm
            font-medium rounded text-gray-700 bg-white
            hover:bg-gray-50 focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
            disabled:text-gray-400 disabled:hover:bg-white disabled:cursor-not-allowed"
        >
            {isStatusAvailable ? openIcon : archivedIcon }
        </button>
    );
}

Deprecated_ArchiveButton.propTypes = {
    onClick: PropTypes.func,
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    openIcon: PropTypes.node,
    archivedIcon: PropTypes.node
}

export default Deprecated_ArchiveButton
