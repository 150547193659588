import React from 'react';
import styles from './Logo.module.css';
import {classNames} from '../../utils';
import LogoWideSVG from './assets/LogoWideSVG';

interface Props {
    readonly size?: 'md';
}

const Logo = ({size = 'md'}: Props) => {
    return (
        <div className={classNames(styles.container, styles[size])}>
            <LogoWideSVG />
        </div>
    );
};

export default Logo;
