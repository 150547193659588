import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AgentModel from '../../../../../models/agent/agentModel'
import Alert from '../../../../alert/Alert'
import Spinner from '../../../../spinner/Spinner'
import TextInput from '../../../textInput/TextInput'
import AgentDetailsFormServiceInterface from '../../logic/service/agentDetailsFormServiceInterface'

export interface AgentInfoProps {
    readonly agentId: string
    readonly service: AgentDetailsFormServiceInterface
}

export default function AgentInfo(props: AgentInfoProps) {

    const [model, setModel] = useState<AgentModel>(AgentModel.createEmpty())
    const [isLoading, setIsLoading] = useState(true)

    const { t } = useTranslation()

    useEffect(() => {
        setIsLoading(true)
        props.service.getAgent(props.agentId).then((res) => {
            setModel(res)
            setIsLoading(false)
        }).catch(() => {
            setModel(AgentModel.createEmpty())
            setIsLoading(false)
        })
    }, [props.agentId])

    function renderInfo() {
        return model.isEmpty() ?
            <Alert testId="alert" message={t('agentinfo.message.error')} /> :
            <>
                <TextInput
                    testId="name"
                    label={'Kantoor'}
                    name="leadApiAgentName"
                    value={model.getCommaSeparatedNames()}
                    isDisabled
                />
                <TextInput
                    testId="platform"
                    label={'Platform'}
                    name="leadApiAgentPlatform"
                    value={model.platform}
                    isDisabled
                />
            </>
    }

    return (
        <div className="border-grey-400 rounded-md border-2 p-4 mt-4">
            <div className="flex-col space-y-3">
                {isLoading ? <Spinner testId='spinner' /> : renderInfo()}
            </div>
        </div >
    )
}
