import CandidateScreeningRequestStatusEnum from '../../../../enums/candidateScreeningRequestStatusEnum';
import api from '../../../../utils/api';
import CandidateModel from '../../../../models/candidate/candidateModel';

export default class CandidateScreeningReportDownloadLinkModel {
    public constructor(
        private readonly hasDoneScreeningRequest: boolean
    ) {}

    static createFromCandidate(candidate: CandidateModel): CandidateScreeningReportDownloadLinkModel {
        if(candidate.hasDoneScreening()) {
            return new CandidateScreeningReportDownloadLinkModel(
                candidate.hasDoneScreening()

            )
        }
    }

    public showDownloadReportText(): string {
        if(this.hasDoneScreeningRequest) {
            return 'candidate.status.screening.completed.report.download.link.text'
        }

        return ''
    }

    public static async downloadReport(candidate: CandidateModel) {
        if (candidate.screeningRequestStatus && candidate.screeningRequestStatus != CandidateScreeningRequestStatusEnum.Invited) {
            const reportUrl = await api.get(`api/get-screening-request-report/${candidate.screeningRequestId}`).then(result => {
                return result.result
            })

            if (reportUrl != null) {
                this.downloadReportFromUrl(reportUrl)
            }
        }
    }

    private static downloadReportFromUrl(screeningReportUrl: string) {
        const alink = document.createElement('a');
        alink.target = '_blank';
        alink.href = screeningReportUrl;
        alink.click();
    }
}

