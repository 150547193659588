import React from 'react';
import styles from '../Icon.module.css';
import { classNames } from '../../../utils';

export interface IconProps {
    className?: string;
}

const DutchFlagIcon = ({ className }: IconProps) => {
    return (
        <div className={classNames(styles.icon, className ?? className)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
            >
                <g clipPath="url(#clip0_6000_153866)">
                    <path d="M0 0H24V24H0V0Z" fill="#21468B" />
                    <path d="M0 0H24V15.9984H0V0Z" fill="white" />
                    <path d="M0 0H24V8.00156H0V0Z" fill="#AE1C28" />
                </g>
                <defs>
                    <clipPath id="clip0_6000_153866">
                        <rect width="24" height="24" rx="12" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default DutchFlagIcon;
