export default class AgentModel {
    constructor(
        public readonly names: ReadonlyArray<string>,
        public readonly platform: string
    ) {}

    public static createEmpty(): AgentModel {
        return new AgentModel([], '')
    }

    public isEmpty(): boolean {
        return this.names.length === 0 && this.platform === ''
    }

    public getCommaSeparatedNames(): string {
        return this.names.join(', ')
    }
}
