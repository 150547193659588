import {CheckCircleIcon} from '@heroicons/react/solid';
import HorizontalDivider from '../../../../components/generics/horizontalDivider/HorizontalDivider';
import {HuurwoningenLogo} from '../../../../assets/svg/HuurwoningenLogo';
import ParariusLogo from '../../../../assets/svg/ParariusLogo';
import {Trans, useTranslation} from 'react-i18next';
import styles from './SuccessPageFragment.module.css';
import Confetti from '../../../../components/effects/confetti';
import Text from '../../../../components/typography/text/Text';
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../../../enums/colorEnum';
import React from 'react'

interface SuccessPageFragmentProps {
    readonly listingCity: string
}

export default function SuccessPageFragment(props: SuccessPageFragmentProps) {
    const {t} = useTranslation()

    return (
        <div data-testid="successPage">
            <Confetti/>
            <div className={styles.titles}>
                <CheckCircleIcon
                    width="48"
                    height="48"
                    className="text-success-600"
                />
                <h1 className="text-2xl font-bold text-primary-700">{t('marketingMessagesSubscribe.success.header')}</h1>
                <HorizontalDivider/>
                <h2 className="text-xl font-bold text-primary-700">{t('marketingMessagesSubscribe.success.part1.subHeader')}</h2>
            </div>
            <div className={styles.bulletPoints}>
                <i className="ri-checkbox-circle-fill text-success-600 text-2xl" style={{ margin: -3 }}></i>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    {t('marketingMessagesSubscribe.success.part1.body.bulletPoint1.text')}
                </Text>

                <i className="ri-checkbox-circle-fill text-success-600 text-2xl" style={{ margin: -3 }}></i>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    <Trans i18nKey="marketingMessagesSubscribe.success.part1.body.bulletPoint2.text"/>
                </Text>

                <i className="ri-checkbox-circle-fill text-success-600 text-2xl" style={{ margin: -3 }}></i>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    {props.listingCity !== undefined ?
                        <Trans i18nKey="marketingMessagesSubscribe.success.part1.body.bulletPoint3.city_text" values={{city: props.listingCity.replace(/\+/g, ' ')}}/> :
                        t('marketingMessagesSubscribe.success.part1.body.bulletPoint3.text')
                    }
                </Text>
            </div>

            <div className={styles.logoContainer}>
                <HuurwoningenLogo/>
                <span className={styles.logoSeparator}>•</span>
                <ParariusLogo/>
            </div>

            <HorizontalDivider/>

            <p className="font-bold text-primary-700">{t('marketingMessagesSubscribe.success.part2.body.title')}</p>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                <Trans
                    i18nKey="marketingMessagesSubscribe.success.part2.body.text"
                    components={{Link: <a />}}
                />
            </Text>
        </div>
    )
}
