import React from 'react';

export function CalendarStackIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.4994 1.9286H10.928V0.642883H9.6423V1.9286H5.78516V0.642883H4.49944V1.9286H1.92801C1.75752 1.9286 1.594 1.99633 1.47344 2.11689C1.35289 2.23745 1.28516 2.40096 1.28516 2.57145V12.8572C1.28516 13.0277 1.35289 13.1912 1.47344 13.3117C1.594 13.4323 1.75752 13.5 1.92801 13.5H13.4994C13.6699 13.5 13.8335 13.4323 13.954 13.3117C14.0746 13.1912 14.1423 13.0277 14.1423 12.8572V2.57145C14.1423 2.40096 14.0746 2.23745 13.954 2.11689C13.8335 1.99633 13.6699 1.9286 13.4994 1.9286ZM5.78516 3.21431H9.6423V4.50003H10.928V3.21431H12.8566V5.78574H2.57087V3.21431H4.49944V4.50003H5.78516V3.21431ZM2.57087 7.07145H12.8566V12.2143H2.57087V7.07145Z"
                fill="currentColor"
            />
            <path
                d="M3.8569 15.4287V13.5001H5.14261V14.7858H15.4283V9.64299H14.1426V8.35727H15.4283V5.78584H14.1426V4.50013H16.0712C16.2417 4.50013 16.4052 4.56786 16.5258 4.68842C16.6463 4.80898 16.714 4.97249 16.714 5.14299V15.4287C16.714 15.5992 16.6463 15.7627 16.5258 15.8833C16.4052 16.0038 16.2417 16.0716 16.0712 16.0716H4.49976C4.32926 16.0716 4.16575 16.0038 4.04519 15.8833C3.92463 15.7627 3.8569 15.5992 3.8569 15.4287Z"
                fill="currentColor"
            />
        </svg>
    );
}
