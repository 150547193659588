import Text from '../typography/text/Text';
import FontSizeEnum from '../typography/enums/fontSizeEnum';
import styles from './NavigationTab.module.css';
import FontWeightEnum from '../typography/enums/fontWeightEnum';
import Badge from '../generics/badge/Badge';

interface NavigationTabProps {
    title: string;
    name: string;
    selectedName: string;
    icon?: string;
    badgeLabel?: string;
    onClick?: (name: string) => void;
}

export default function NavigationTab(props: NavigationTabProps) {

    const isSelected = props.name === props.selectedName

    return (
        <>
            <div onClick={() => props.onClick(props.name)} className={`${styles.tab} ${isSelected ? `${styles.selected}` : ''}`}>
                {props.icon &&
                <i
                    className={`${styles.icon} ${props.icon}`}
                ></i>
                }
                <Text size={FontSizeEnum.Sm} weight={FontWeightEnum.Semibold}>{props.title}</Text>
                {props.badgeLabel &&
                    <Badge label={props.badgeLabel} />
                }
            </div>
        </>
    );
}
