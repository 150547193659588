import {Transition} from '@headlessui/react'
import {Fragment, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import EnumType from '../../enums/general/enumType'
import ModalModel from './logic/models/modalModel'
import style from './Modal.module.css'
import Button from '../buttons/button/Button'
import ButtonType from '../buttons/button/logic/models/buttonType'
import FontSizeEnum from '../typography/enums/fontSizeEnum'
import ColorEnum, {colorEnumAsCssVar} from '../../enums/colorEnum'
import FontWeightEnum from '../typography/enums/fontWeightEnum'
import Text from '../typography/text/Text'
import FlowyFigure from '../generics/modalBackgroundIcon/FlowyFigure'
import convertModalTypeToModalTheme from './logic/convertModalTypeToModalTheme'
import ModalType from '../../enums/modalType'

export interface ModalProps<T extends ModalModel<E>, E extends EnumType> {
    readonly model: T
    readonly children: React.ReactNode
    readonly onButtonClick?: (actionType: E) => void
    readonly onCloseButtonClick?: () => void
}

/**
 * @deprecated Use {@link ../../components/organisms/modal/Modal} instead
 */
export default function Modal<T extends ModalModel<E>, E extends EnumType>(props: ModalProps<T, E>) {
    const [model, setModel] = useState(props.model)

    const {t} = useTranslation()

    const theme = convertModalTypeToModalTheme(props.model.type ? props.model.type : ModalType.Info)

    useEffect(() => {
        setModel(props.model)
    }, [props.model])

    return (
        <Transition appear show={model.isOpen}>
            <div>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25 z-30"/>
                </Transition.Child>

                <div className="fixed inset-0 w-full z-50">
                    <div className="flex min-h-full items-center justify-center">

                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <div
                                className={style.mainContainer + ' md:w-[704px] relative rounded-2xl bg-white shadow-xl'}>
                                <div className={style.closeButton}>
                                    <button
                                        onClick={() => props.onCloseButtonClick?.()}
                                        type="button">
                                        <i className="ri-close-line ri-lg"></i>
                                    </button>
                                </div>

                                <FlowyFigure color={theme.mainColor}/>
                                <div className={style.contentContainer}>
                                    {model.icon ? <div className={style.iconContainer}>
                                        <i
                                            className={style.icon + ' ' + model.icon + ' text-xl'}
                                            style={{
                                                color: colorEnumAsCssVar(theme.iconColor),
                                                backgroundColor: colorEnumAsCssVar(theme.mainColor)
                                            }}
                                        ></i>
                                    </div> : null}
                                    <div className="space-y-2">
                                        {model.title &&
                                            <Text
                                                size={FontSizeEnum.Lg}
                                                color={ColorEnum.Primary700}
                                                weight={FontWeightEnum.Bold}
                                            >
                                                {t(model.title.key, model.title.vars)}
                                            </Text>
                                        }
                                        {props.children}
                                    </div>
                                </div>

                                <div className={style.buttonContainer}>
                                    {
                                        model.buttons?.map((button, index) =>
                                            <Button
                                                key={index}
                                                isDisabled={button.isDisabled}
                                                isHidden={button.isHidden}
                                                title={t(button.label)}
                                                buttonType={button.isPrimary ? ButtonType.Primary : ButtonType.Secondary}
                                                onClick={() => props.onButtonClick?.(button.actionType)}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Transition>
    )
}
