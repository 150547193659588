import React from 'react';
import PropTypes from 'prop-types';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function LeadflowDropdownOption(props) {
    const { value, label, hidden, onChange } = props;

    return (
        <button
            data-testid="candidate-sorting-dropdown-option"
            name={value}
            value={value}
            onClick={() => onChange(value)}
            className={classNames('text-gray-900',
                'block px-4 py-2 text-md hover:bg-gray-100 cursor-pointer text-left w-full',
                hidden ? 'hidden' : null)}
        >
            {label}
        </button>
    );
}

LeadflowDropdownOption.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    hidden: PropTypes.bool,
    onChange: PropTypes.func
};

export default LeadflowDropdownOption;
