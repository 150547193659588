import moment from 'moment';

export const updateMomentLocale = () => {
    moment.updateLocale('nl', {
        relativeTime: {
            future: 'in %s',
            past: '%s geleden',
            s: '%d s',
            ss: '%d s',
            m: '1 min',
            mm: '%d min',
            h: '1 u',
            hh: '%d u',
            d: '1 d',
            dd: '%d d',
            w: '1 wk',
            ww: '%d wk',
            M: '1 mnd',
            MM: '%d mnd',
            y: '1 jr',
            yy: '%d jr'
        }
    })
}
