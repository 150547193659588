import AddressModel from './addressModel'
import ImmutableModel from '../../../../models/immutableModel/immutableModel'

interface MergeDuplicateTransactionalListingsPageModelProps {
    readonly addresses: ReadonlyArray<AddressModel>
    readonly isConfirmModalOpen: boolean
    readonly listingInputValue: string
    readonly listingIdSelectedAsPrimaryForMerging?: string
    readonly errorMessage: string
    readonly isModalShowingErrorMessage: boolean
}

export default class MergeDuplicateTransactionalListingsPageModel extends ImmutableModel<MergeDuplicateTransactionalListingsPageModel, MergeDuplicateTransactionalListingsPageModelProps> {

    public static create(): MergeDuplicateTransactionalListingsPageModel {
        return new MergeDuplicateTransactionalListingsPageModel({
            addresses: [],
            isConfirmModalOpen: false,
            listingInputValue: '',
            errorMessage: '',
            isModalShowingErrorMessage: false
        })
    }

    public addAddress(newAddress: AddressModel): MergeDuplicateTransactionalListingsPageModel {
        if (this.value.addresses.length === 2) {
            throw new Error('Cannot add more than two addresses')
        }

        if (this.value.addresses.find(a => a.value.listingId === newAddress.value.listingId)) {
            throw new Error('Address with this listingId already exists')
        }

        const newAddresses = [...this.value.addresses, newAddress]

        return this.with({addresses: newAddresses, listingInputValue: ''})
    }

    public removeAddress(listingId: string): MergeDuplicateTransactionalListingsPageModel {
        const newAddresses = this.value.addresses.filter(address => address.value.listingId !== listingId)

        return this.with({addresses: newAddresses})
    }

    public isAddButtonDisabled(): boolean {
        return this.value.listingInputValue === '' || this.value.addresses.length === 2
    }

    public areListingsMergeable(): boolean {
        return this.value.addresses.length === 2
    }

    public isErrorMessageVisible(): boolean {
        return this.value.errorMessage !== ''
    }

    public getPrimaryAddress(): AddressModel | undefined {
        return this.value.addresses.find(a => a.value.listingId === this.value.listingIdSelectedAsPrimaryForMerging)
    }

    public getRedundantAddress(): AddressModel {
        return this.value.addresses.find(a => a.value.listingId !== this.value.listingIdSelectedAsPrimaryForMerging)
    }

    public removeRedundatAddress(): MergeDuplicateTransactionalListingsPageModel {
        return this.removeAddress(this.getRedundantAddress().value.listingId)
    }

    public getIdFromListingInputValue(): string | null {
        if (this.value.listingInputValue === '') {
            return null
        }
        try {
            const url = new URL(this.value.listingInputValue)
            const pathSegments = url.pathname.split('/')
            return pathSegments[3] || null
        } catch(e) {
            return this.value.listingInputValue
        }
    }
}
