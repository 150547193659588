import React from 'react';
import styles from './TopHeader.module.css';
import {Logo} from '../../atoms/logo';
import { LanguageSelect } from '../language-select';

interface Props {
    readonly hasTranslationSelector?: boolean;
}

const TopHeader = ({ hasTranslationSelector = false }: Props) => {
    return (
        <header className={styles.container}>
            <Logo />
            {hasTranslationSelector &&
                <LanguageSelect/>
            }
        </header>
    );
};

export default TopHeader;
