import api from '../../../utils/api';
import {UI_ACTIONS} from '../../../utils/constants';

export const getListingDetails = (token, id) => dispatch => {
    dispatch({type: UI_ACTIONS.START_LOADING});

    api.get(`api/transactional_listings/${id}`)
        .then((response) => {
            dispatch({
                type: 'GET_LISTING_DETAILS',
                payload: response,
            });
        })
        .catch((e) => {
            dispatch({type: UI_ACTIONS.STOP_LOADING});
            console.error(e);
        });
};
