import TranslationModel from '../../../models/translationModel';
import style from './InfoIcon.module.css';
import {useTranslation} from 'react-i18next';
import Tooltip from '../../tooltip/Tooltip';
import MaxWidthEnum from '../../../enums/maxWidthEnum'

export interface InfoIconProps {
    readonly tooltipText?: TranslationModel
}

export function InfoIcon(props: InfoIconProps) {
    const {t} = useTranslation()

    return (
        <Tooltip
            text={props.tooltipText ? t(props.tooltipText.key, props.tooltipText.vars) : ''}
            dataTestId="info-tooltip"
            isDisabled={props.tooltipText === undefined}
            size={MaxWidthEnum.Lg}
        >
            <i className={`ri-information-line ${style.icon}`}></i>
        </Tooltip>
    )
}
