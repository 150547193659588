import PropTypes from 'prop-types'
import BulkActionDropdown from '../dropdowns/BulkActionDropdown'
import SkeletonComponent from '../SkeletonComponent'
import moment from 'moment'
import React, {useState} from 'react'
import ListingStatus from '../../enums/listingStatus'
import {useFeature} from '@growthbook/growthbook-react'
import Deprecated_ArchiveButton from '../buttons/archive/Deprecated_ArchiveButton'
import AddressContent from './listingsTable/components/addressContent/AddressContent'
import AddressContentModel from './listingsTable/components/addressContent/logic/models/addressContentModel'
import ListingPropertiesModel from '../listings/listingProperties/logics/model/listingPropertiesModel'
import AddressModel from '../../models/listing/addressModel'
import ListingStatusTimerModel from '../../models/listing/listingStatusTimerModel'
import UnarchiveListingModal from '../modals/unarchiveListingModal/./UnarchiveListingModal'
import UnarchiveListingModalModel from '../modals/unarchiveListingModal/logic/models/unarchiveListingModalModel'
import UnarchiveListingModalActionType
    from '../modals/unarchiveListingModal/logic/models/unarchiveListingModalActionType'
import ArchiveListingModal from '../modals/archiveListingModal/ArchiveListingModal'
import ArchiveListingModalModel from '../modals/archiveListingModal/logic/models/archiveListingModalModel'
import ArchiveListingModalActionType from '../modals/archiveListingModal/logic/models/archiveListingModalActionType'
import {toggleAutomaticEnriching} from '../../redux/actions/listings/setAutoEnriching'
import EnrichmentModeEnum from '../../enums/enrichmentModeEnum'
import api from '../../utils/api'
import {useDispatch} from 'react-redux'
import {stringToEnum} from '../../utils/enumType'
import {useTranslation} from 'react-i18next'
import Tooltip from '../tooltip/Tooltip'
import fontWeightEnum from '../typography/enums/fontWeightEnum'
import fontSizeEnum from '../typography/enums/fontSizeEnum'
import colorEnum from '../../enums/colorEnum'
import Text from '../../components/typography/text/Text'
import styles from './Deprecated_ListingsTable.module.css'
import Checkbox from '../forms/checkbox/Checkbox'
import CandidateFilterStatus from '../../enums/candidateFilterStatus'
import {Link, useNavigate} from 'react-router-dom'


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


/**
 * @deprecated To be replaced with new ListingsTable (completely refactored Table).
 * Use @link src/components/tables/ListingsTable.tsx instead.
 */
function Deprecated_ListingsTable(props) {
    const {
        data, isLoading, changeBulkListingStatus, changeSingleListingStatus,
        selectedListings, setSelectedListings, indeterminate,
        setIndeterminate, checked, setChecked, listingStatus
    } = props

    const {t} = useTranslation()

    const [archiveModalModel, setArchiveModalModel] = useState(ArchiveListingModalModel.createEmpty())
    const [unarchiveModalModel, setUnarchiveModalModel] = useState(UnarchiveListingModalModel.createEmpty())
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const FEATURE_ARCHIVE_TRANSACTION_MODAL = useFeature('archive_transaction_modal').value

    function toggleAll() {
        setSelectedListings(checked || indeterminate ? [] : data.map((listing) => listing.id))
        setChecked(!checked && !indeterminate)
        setIndeterminate(false)
    }

    function handleArchiveButtonClick(listingId: string, address: string, status: ListingStatus, enrichmentMode: EnrichmentModeEnum) {
        if (status === ListingStatus.Archived) {
            if (FEATURE_ARCHIVE_TRANSACTION_MODAL === true) {
                setUnarchiveModalModel(prev => prev
                    .withListingId(listingId)
                    .withTitle(prev.title.withVars({address}))
                    .withIsOpen(true)
                    .withIsAutoEnrichmentEnabled(enrichmentMode === EnrichmentModeEnum.Auto)
                )
            } else {
                changeSingleListingStatus(listingId, ListingStatus.Open)
            }
        } else {
            if (FEATURE_ARCHIVE_TRANSACTION_MODAL === true) {
                setArchiveModalModel(prev => prev
                    .withListingId(listingId)
                    .withTitle(prev.title.withVars({address}))
                    .withIsOpen(true)
                )
            } else {
                changeSingleListingStatus(listingId, ListingStatus.Archived)
            }
        }
    }

    function handleArchiveModalButtonClick(listingId: string, action: ArchiveListingModalActionType) {
        if (action === ArchiveListingModalActionType.Archive) {
            changeSingleListingStatus(listingId, ListingStatus.Archived)
            setArchiveModalModel(prev => prev.withIsOpen(false))
            return
        }
        setArchiveModalModel(prev => prev.withIsOpen(false))
    }

    async function handleUnArchiveModalButtonClick(listingId: string, enrichmentMode: EnrichmentModeEnum, action: UnarchiveListingModalActionType) {
        if (action === UnarchiveListingModalActionType.Unarchive) {
            await unarchiveListing(listingId, enrichmentMode)
            return
        }
        setUnarchiveModalModel(prev => prev.withIsOpen(false))
    }

    async function unarchiveListing(listingId: string, enrichmentMode: EnrichmentModeEnum) {
        try {
            changeSingleListingStatus(listingId, ListingStatus.Open)
            const token = await api.getToken()
            dispatch(toggleAutomaticEnriching(token, listingId, enrichmentMode))
        } catch (err) {
            console.error(err)
        }
        setUnarchiveModalModel(prev => prev.withIsOpen(false))
    }

    function isArchivedButtonEnabled(listing) {
        if (listing.status === 'archived') {
            return (listing.closedAt === undefined || listing.closedAt === null) || moment(listing.closedAt).isAfter(moment.now())
        }
        if (listing.status === 'closed') {
            return false
        }
        return true
    }

    return (
        <div className={styles.listingsTableContainer}>
            <div className={styles.listingsTableWrapper}>
                <BulkActionDropdown
                    type="listings"
                    hidden={selectedListings.length < 2}
                    selectedListings={selectedListings}
                    onArchiveClick={() => changeBulkListingStatus([...props.selectedListings], ListingStatus.Archived)}
                    onUnarchiveClick={() => changeBulkListingStatus([...props.selectedListings], ListingStatus.Open)}
                    listingStatus={listingStatus}
                />
            </div>
            <div className={styles.tableContainer}>
                <table className="table-fixed min-w-full min-h-full divide-y divide-gray-100">
                    <thead>
                        <tr>
                            <th className={`${styles.checkboxContainer} relative w-12 sm:w-16 sm:px-4`}>
                                <div className={styles.checkbox}>
                                    <Checkbox
                                        isChecked={checked ? true : false}
                                        onChange={toggleAll}
                                        onClick={e => e.stopPropagation()}
                                    />
                                </div>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.address')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.createdAt')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.available.from')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.new')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.screen')}
                                </Text>
                            </th>
                            <th>
                                <Text
                                    size={fontSizeEnum.Xsm}
                                    color={colorEnum.Gray500}
                                    weight={fontWeightEnum.Medium}
                                >
                                    {t('listings.header.suitable')}
                                </Text>
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white w-full">
                        {isLoading ?
                            <>
                                {Array.from({length: 10}, (_, i) => i + 1).map((item) => (
                                    <tr
                                        key={item}
                                    >
                                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                            <input
                                                type="checkbox"
                                                className="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary-300 sm:left-6"
                                                value={item}
                                            />
                                        </td>
                                        <td
                                            className={classNames(
                                                'whitespace-nowrap py-4 pr-3 text-sm font-medium'
                                            )}
                                        >
                                            <a>
                                                <h4 className="text-lg font-bold text-primary">
                                                    <SkeletonComponent width={320}/></h4>
                                            </a>

                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">

                                            <SkeletonComponent width={80}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <SkeletonComponent width={50}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <SkeletonComponent width={50}/>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            <SkeletonComponent width={50}/>
                                        </td>
                                    </tr>
                                ))}
                            </>
                            :
                            <>
                                {data.map((listing) => {
                                    const {
                                        id, createdAt, availableAt,
                                        status, numberOfLeadsToAssess, numberOfSuitableLeads,
                                        numberOfUnassessedLeads, surface, rooms, price, city,
                                        houseNumber, postalCode, street, closedAt, enrichment
                                    } = listing
                                    const NO_LISTING_INFORMATION = !surface && !rooms && price === 0
                                    const address = `${street} ${houseNumber}, ${city}`

                                    const addressModel = new AddressModel(city, houseNumber, street, postalCode)
                                    const listingPropertiesModel = new ListingPropertiesModel(price, surface, rooms)
                                    const listingStatusTimer = new ListingStatusTimerModel(status, closedAt ? new Date(closedAt) : null)
                                    const addressContentModel = new AddressContentModel(addressModel, listingStatusTimer, listingPropertiesModel)

                                    return <tr
                                        key={id}
                                        className={selectedListings.includes(id) ? 'bg-gray-50' : 'hover:bg-gray-50 hover:shadow-sm'}
                                        onClick={() => navigate(`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                            {selectedListings.includes(id) && (
                                                <div
                                                    className="absolute inset-y-0 left-0 w-0.5 bg-primary"/>
                                            )}
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <div className={styles.checkboxButton}>
                                                <Checkbox
                                                    value={id}
                                                    isChecked={selectedListings.includes(id)}
                                                    onChange={(e) =>
                                                        setSelectedListings(
                                                            e.target.checked
                                                                ? [...selectedListings, id]
                                                                : selectedListings.filter((p) => p !== id)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                        <td
                                            className={`
                                                ${styles.listingContainer}
                                                ${classNames('whitespace-nowrap py-6 text-sm font-medium relative', selectedListings.includes(id) ? 'text-primary' : 'text-gray-900')}
                                            `}
                                        >
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <AddressContent model={addressContentModel}/>
                                        </td>

                                        <td className="text-sm text-gray-500 cursor-pointer hover:bg-gray-50 relative">
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                <span className="py-0.5 text-sm text-primary">
                                                    {moment(createdAt).isValid() ? moment(createdAt).fromNow() : '-'}
                                                </span>
                                            </Link>
                                        </td>

                                        <td className="whitespace-nowrap py-6 text-sm text-primary relative">
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                {moment(availableAt).isValid() ? moment(availableAt).fromNow() : '-'}
                                            </Link>
                                        </td>

                                        <td className="w-0 pr-2 text-sm text-primary relative">
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={`
                                                    inline-flex items-center border border-gray-300
                                                    shadow-sm text-md font-medium rounded text-gray-700 bg-white hover:bg-gray-50
                                                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                                    ${styles.candidateSituationBox}
                                                `}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                <i className="ri-user-add-line ri-lg mr-2"></i> {numberOfUnassessedLeads > 999 ? '999+' : numberOfUnassessedLeads}
                                            </Link>
                                        </td>
                                        <td className="w-0 pr-2 text-sm text-primary relative">
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.Assessment}`}
                                                className={`
                                                    inline-flex items-center border border-gray-300
                                                    shadow-sm text-md font-medium rounded text-gray-700 bg-white hover:bg-gray-50
                                                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                                    ${styles.candidateSituationBox}
                                                `}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                <i className="ri-user-search-line ri-lg mr-2"></i> {numberOfLeadsToAssess > 999 ? '999+' : numberOfLeadsToAssess}
                                            </Link>
                                        </td>
                                        <td className="w-0 pr-2 text-sm text-primary relative">
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.Suitable}`}
                                                className={`
                                                    inline-flex items-center  border border-gray-300
                                                    shadow-sm text-md font-medium rounded text-gray-700 bg-white hover:bg-gray-50
                                                    focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300
                                                    ${styles.candidateSituationBox}
                                                `}
                                                onClick={e => e.stopPropagation()}
                                            >
                                                <i className="ri-check-line ri-lg mr-2"></i> {numberOfSuitableLeads > 999 ? '999+' : numberOfSuitableLeads}
                                            </Link>
                                        </td>
                                        <td className={`${styles.archiveContainer} text-gray-500`}
                                            onClick={e => e.stopPropagation()}>
                                            <Link
                                                to={`/dashboard/advertenties/${id}?status=${CandidateFilterStatus.None}`}
                                                className={styles.link}
                                                onClick={e => e.stopPropagation()}
                                            ></Link>

                                            <Tooltip
                                                text={t('listings.ads.cannotReopen')}
                                                isDisabled={isArchivedButtonEnabled(listing)}
                                            >
                                                <div className={styles.archiveButton}>
                                                    <Deprecated_ArchiveButton
                                                        id={id}
                                                        disabled={!isArchivedButtonEnabled(listing)}
                                                        status={status === 'archived' || status === 'closed' ? 'available' : 'archived'}
                                                        onClick={() => handleArchiveButtonClick(id, address, status, stringToEnum(enrichment, EnrichmentModeEnum))}
                                                        openIcon={<i className="ri-inbox-line text-xl"/>}
                                                        archivedIcon={<i className="ri-archive-line text-xl"/>}
                                                    />
                                                </div>
                                            </Tooltip>
                                        </td>
                                    </tr>
                                })}
                            </>
                        }
                    </tbody>
                </table>
            </div>
            <ArchiveListingModal
                model={archiveModalModel}
                onButtonClick={(id, actionType) => handleArchiveModalButtonClick(id, actionType)}
                onCloseButtonClick = {() => setArchiveModalModel(prev => prev.withIsOpen(false))}
            />
            <UnarchiveListingModal
                model={unarchiveModalModel}
                onButtonClick={(id, enrichmentMode, actionType) => handleUnArchiveModalButtonClick(id, enrichmentMode, actionType)}
                onCloseButtonClick = {() => setUnarchiveModalModel(prev => prev.withIsOpen(false))}
            />
        </div>
    )
}

Deprecated_ListingsTable.propTypes = {
    data: PropTypes.array.isRequired,
    totalItems: PropTypes.number.isRequired,
    isLoading: PropTypes.bool.isRequired,
    'selectedListings.includes': PropTypes.bool,
    'selectedListings.length': PropTypes.number,
    checked: PropTypes.bool,
    setSelectedListings: PropTypes.func,
    changeSingleListingStatus: PropTypes.func,
    changeBulkListingStatus: PropTypes.func,
    setChecked: PropTypes.func,
    setIndeterminate: PropTypes.func,
    indeterminate: PropTypes.any,
    selectedListings: PropTypes.array,
    listingStatus: PropTypes.string
}

export default Deprecated_ListingsTable
