import React, {useEffect} from 'react';
import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import { Trans, useTranslation } from 'react-i18next';
import styles from './SendByEmailContactRequestTab.module.css';
import Button from '../../buttons/button/Button';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import ButtonSize from '../../buttons/button/logic/models/buttonSize';
import IconType from '../../buttons/button/logic/models/iconType';
import FormError from '../../banners/FormError';
import api from '../../../utils/api';
import {useDispatch} from 'react-redux';
import {setNotification} from '../../../redux/actions/ui/setNotification';

interface SendByEmailContactRequestTabProps {
    readonly transactionalListingId: string;
    readonly setIsOpen: (boolean: boolean) => void;
}

export default function SendByEmailContactRequestTab(props: SendByEmailContactRequestTabProps) {
    const { t } = useTranslation();
    const [emails, setEmails] = React.useState('');
    const [isValidEmails, setIsValidEmails] = React.useState(false);
    const [isMaxEmailsReached, setIsMaxEmailsReached] = React.useState(false);
    const [isEnrichApplicationInvitationLoading, setIsEnrichApplicationInvitationLoading] = React.useState(false);
    const dispatch = useDispatch();
    const maxEmails = 20;

    useEffect(() => {
        const splitEmails = emails.trim().replace(/,\s+/g, ',').split(',');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const valid = splitEmails.every(email => emailRegex.test(email));

        setIsValidEmails(valid);

        const maxReached = splitEmails.length > maxEmails;

        setIsMaxEmailsReached(maxReached);

    }, [emails]);

    function submitEmails() {
        if (!isValidEmails || isMaxEmailsReached) {
            return;
        }

        setIsEnrichApplicationInvitationLoading(true);

        const splitEmails = emails.trim().replace(/,\s+/g, ',').split(',');

        api.post('api/homeseeker/application/enriched-application-invitation', {
            transactionalListingId: props.transactionalListingId,
            emails: splitEmails
        })
            .then(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'success',
                    message: t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.toast.success')
                }));
                setIsEnrichApplicationInvitationLoading(false);
                setEmails('');
                props.setIsOpen(false);
            })
            .catch(() => {
                setIsEnrichApplicationInvitationLoading(false);
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'failed',
                    message: t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.toast.failed')
                }));
            });
    }

    return (
        <>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                <Trans
                    i18nKey="listing.details.generateContactRequestLinkModal.tab.sendByEmail.description"
                    components={{
                        p: <p style={{ marginBottom: '0.5rem' }} />,
                        ul: <ul style={{ textAlign: 'left', listStyle: 'initial', marginLeft: '1rem' }} />,
                        li: <li style={{ marginBottom: 8 }} />,
                    }}
                />
            </Text>
            <div className={styles.inputWrapper}>
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    {t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.emails.label')}
                </Text>
                <textarea
                    className={`${styles.textarea} ${((!isValidEmails || isMaxEmailsReached) && emails !== '') ? styles.error : ''}`}
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                />
                {!isValidEmails && emails !== '' && (
                    <FormError
                        message={t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.emails.errors.invalid')}
                    />
                )}
                {isMaxEmailsReached && emails !== '' && (
                    <FormError
                        message={t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.emails.errors.maxReached')}
                    />
                )}
            </div>
            <div className={`${styles.sendButton}`}>
                <Button
                    title={t('listing.details.generateContactRequestLinkModal.tab.sendByEmail.button.title')}
                    buttonType={ButtonType.Primary}
                    size={ButtonSize.Lg}
                    icon={'ri-mail-send-line'}
                    iconType={IconType.Leading}
                    isDisabled={!isValidEmails || isMaxEmailsReached || isEnrichApplicationInvitationLoading}
                    onClick={submitEmails}
                />
            </div>
        </>
    );
}
