import { request } from '../../../utils/api';
import { API_URIS } from '../../../utils/constants';

export const createAgentLogin = (token, login, agentId) => (dispatch) => {
    const data = {
        login: login,
        isEnabled: true,
        agent: `/api/agents/${agentId}`,
    };

    request(token)
        .post(API_URIS.AGENT_LOGINS_URI, data)
        .then((response) => {
            dispatch({
                type: 'ADD_AGENT_LOGIN',
                payload: response.data,
            });
        })
        .catch((e) => console.log(e));
};
