import Text from '../../../../typography/text/Text';
import FontSizeEnum from '../../../../typography/enums/fontSizeEnum';
import ColorEnum from '../../../../../enums/colorEnum';
import FontWeightEnum from '../../../../typography/enums/fontWeightEnum';
import {Trans, useTranslation} from 'react-i18next';
import styles from './ScreeningCandidateFragment.module.css';
import api from '../../../../../utils/api';
import SaveableInput from '../../../../molecules/saveableInput/SaveableInput'
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import ScreeningModalServiceInterface from '../../service/screeningModalServiceInterface';
import Checkbox from '../../../../forms/checkbox/Checkbox';
import HighlightBlock from '../../../../../componentsNew/molecules/highlightBlock/HighlightBlock';

export interface ScreeningCandidateFragmentProps {
    readonly candidateName: string
    readonly isExtraExplanationShowing: boolean
    readonly onRentalPriceEdit?: () => void
    readonly onRentalPriceSave?: () => void
    readonly onRememberToNotShowExtraExplanationCheckboxChange?: (val: boolean) => void
    readonly onAreCoTenantsAllowedChange?: (val: boolean) => void
    readonly service: ScreeningModalServiceInterface
}

export default function ScreeningCandidateFragment(props: ScreeningCandidateFragmentProps) {
    const {t} = useTranslation()

    const listingId = useSelector((state: any) => state.listings.selectedListing.id)
    const listingPrice = useSelector((state: any) => state.listings.selectedListing.details.price)

    const [remainingFreeScreeningAmount, setRemainingFreeScreeningAmount] = useState()
    const [isExtraExplanationShowing, setIsExtraExplanationShowing] = useState(props.isExtraExplanationShowing)

    useEffect(() => {
        api.get('api/get-agency-remaining-free-screening-requests-amount').then(result => {
            setRemainingFreeScreeningAmount(result.amount)
        })
    }, []);

    return (
        <>
            <div className="pb-2 pt-2">
                <Text size={FontSizeEnum.Lg} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                    <Trans
                        i18nKey="candidate.screening.modal.screen.title"
                    />
                </Text>
            </div>

            <div className="pb-2">
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                    {t('candidate.screening.modal.screen.description2')}
                </Text>
            </div>

            <div className="pb-2">
                <span className="font-medium">{t('candidate.table.title.candidate')}:</span> {props.candidateName}
            </div>
            <div className="flex items-center gap-2 pb-2">
                <div className="font-medium">{t('candidate.screening.modal.screen.rental_price')}:</div>
                <div className="flex-1">
                    <SaveableInput
                        service={props.service}
                        listingId={listingId}
                        value={listingPrice}
                        onFocus={() => props.onRentalPriceEdit?.()}
                        onBlur={() => props.onRentalPriceSave?.()}
                    />
                </div>
            </div>

            <Checkbox
                label={
                    <>
                        <div className="mb-1">
                            <Trans
                                i18nKey="candidate.screening.modal.allowCoTenants"
                                components={{Link: <a/>}}
                            />
                        </div>
                        <Text
                            size={FontSizeEnum.Md}
                            color={ColorEnum.Gray500}
                        >
                            {t('candidate.screening.modal.allowCoTenants.helpText')}
                        </Text>
                    </>}
                fontSize={FontSizeEnum.Md}
                fontWeight={FontWeightEnum.Medium}
                onChange={(e) => props.onAreCoTenantsAllowedChange?.(e.target.checked)}
            />

            {isExtraExplanationShowing &&
                <HighlightBlock
                    type='info'
                >
                    <div className={styles.noteText}>
                        <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800} weight={FontWeightEnum.Bold}>
                            {t('candidate.screening.modal.screen.note.title')}
                        </Text>
                        <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800}>
                            <Trans i18nKey={'candidate.screening.modal.screen.note.bulletPoints'} components={{ ul: <ul style={{ textAlign: 'left', listStyle: 'initial', marginLeft: 16 }}/>, li: <li style={{ marginBottom: 8 }}/> }} />
                        </Text>
                        <label className={styles.labelledCheckboxContainer}>
                            <Checkbox onChange={(ev) => props?.onRememberToNotShowExtraExplanationCheckboxChange(ev.target.checked)}/>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800}>
                                {t('candidate.screening.modal.screen.note.dontShowAgain')}
                            </Text>
                        </label>
                    </div>
                </HighlightBlock>
            }

            {remainingFreeScreeningAmount && remainingFreeScreeningAmount > 0 ?
                <div className={styles.freeRemainingAmountText}>
                    <Text size={FontSizeEnum.Sm} color={remainingFreeScreeningAmount === 1 ? ColorEnum.Error600 : ColorEnum.Gray800}>
                        <Trans
                            i18nKey="candidate.screening.modal.screen.remaining.free.screenings.amount"
                            values={{amount: remainingFreeScreeningAmount ? remainingFreeScreeningAmount : '…'}}
                            components={{Bold: <strong/>}}
                        />
                    </Text>
                </div>
                : null
            }
        </>
    )
}
