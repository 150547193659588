import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import style from './CheckIcon.module.css'

export interface CheckIconProps {
    readonly checkColor?: ColorEnum
}
export default function CheckIcon(props: CheckIconProps) {
    function getColorCssVar(): string {
        if (props.checkColor === undefined) {
            return colorEnumAsCssVar(ColorEnum.Gray800);
        }
        return colorEnumAsCssVar(props.checkColor);
    }

    return (
        <div className={style.checkIcon}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" viewBox="0 0 10 7" fill="none">
                <path
                    fill={getColorCssVar()}
                    d="M3.99987 5.586L8.59587 0.989502L9.30337 1.6965L3.99987 7L0.817871 3.818L1.52487 3.111L3.99987 5.586Z"
                />
            </svg>
        </div>
    )
}
