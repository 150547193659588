import TopNavigation from '../../../components/navigation/TopNavigation'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAgentDetails } from '../../../redux/actions/agents/getAgentDetails'
import { createAgentWithLogins } from '../../../redux/actions/agents/createAgentWithLogins'
import { updateAgentWithLogins } from '../../../redux/actions/agents/updateAgentWithLogins'
import AgentDetailsFormService from '../../../services/agentDetailsFormService'
import AgentDetailsForm from '../../../components/forms/agentDetails/AgentDetailsForm'

function AgentDetails() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const { token } = useSelector((state: any) => state.users)
    const agentData = useSelector((state: any) => state.agents.selectedAgent)

    const navigate = useNavigate()

    const formService = new AgentDetailsFormService()

    const fetchAgentOnLoad = () => {
        if (id !== 'new') dispatch(getAgentDetails(token, id, { pagination: false }))
    }

    useEffect(() => {
        if (token) fetchAgentOnLoad()
    }, [dispatch, token])

    function computeLoginUpdateOperations(oldLoginsState, newLoginsState) {
        const loginsToDelete = oldLoginsState.filter(login => {
            return newLoginsState.indexOf(login.login) === -1
        })

        const oldLoginsStateAddr = oldLoginsState.map(login => login.login)

        const loginsToCreate = newLoginsState.filter(login => {
            return oldLoginsStateAddr.indexOf(login) === -1
        })

        loginsToCreate.forEach(loginAddr => {
            if (loginsToDelete.indexOf(loginAddr) > -1) {
                loginsToDelete.splice(loginsToDelete.indexOf(loginAddr), 1)
                loginsToCreate.splice(loginsToCreate.indexOf(loginAddr), 1)
            }
        })

        return { toAdd: loginsToCreate, toDelete: loginsToDelete }
    }

    function onFormSubmit(data) {

        const loginOperations = computeLoginUpdateOperations(agentData?.logins || [], data.loginsPopulated)

        const adaptedData = {
            url: data.url,
            city: data.city,
            logo: data.logo,
            houseNumber: data.houseNumber,
            street: data.street,
            postalCode: data.postalCode,
            defaultEmail: data.defaultEmail,
            defaultPhone: data.defaultPhone,
            isEmailSecure: data.isEmailSecure,
            leadsAgentIds: data.leadsApiUuids,
            privacyPolicy: data.privacyPolicy,
            emailTemplateId: data.emailTemplateId,
            showHouseNumber: data.showHouseNumber,
            emailsForInquiries: data.inquiriesAddresses,
            rejectionTemplateId: data.rejectionTemplateId,
            enrichmentTemplateId: data.enrichmentTemplateId,
            name: data.name,
            parariusOfficeKey: data.parariusOfficeKey,
            allowCommercialContactToHomeseekers: data.allowCommercialContactToHomeseekers,
            commercialContactType: data.commercialContactType,
            zohoId: data.zohoId
        }

        if (id === 'new') dispatch(createAgentWithLogins(token, adaptedData, loginOperations.toAdd))
        else dispatch(updateAgentWithLogins(token, agentData.id, adaptedData, loginOperations.toAdd, loginOperations.toDelete))
        navigate('/admin', {
            replace: true // So that the store is updated when going back to the overview...
        })
    }



    return (
        <>
            <TopNavigation />
            <div className="min-h-full">
                <main className="pt-8 pb-16">
                    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                        <div className="px-4 sm:px-0">
                            <h1 className="text-3xl font-bold mb-6 ">
                                Settings van {agentData && agentData.name || 'Nieuwe Makelaar'}
                            </h1>
                            <AgentDetailsForm agentData={agentData} onSubmitHandler={onFormSubmit} agentDetailsFormService={formService} />
                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AgentDetails
