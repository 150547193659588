const setTokenToStore = (token) => (dispatch) => {
    dispatch({
        type: 'SET_TOKEN',
        payload: token,
    });
};
/* eslint-disable no-undef */
module.exports = {
    setTokenToStore,
};
