import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum';
import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import styles from './Badge.module.css';
import IconPositionEnum from './iconPositionEnum'
import {CSSProperties} from 'react';

export interface BadgeProps {
    readonly icon?: string
    readonly iconPosition?: IconPositionEnum
    readonly label: string
    readonly labelColor?: ColorEnum
    readonly iconBackgroundColor?: ColorEnum
    readonly backgroundColor?: ColorEnum
    readonly borderColor?: ColorEnum
}

function Badge(props: BadgeProps) {

    function getIconPosition() {
        return props.iconPosition ? props.iconPosition : IconPositionEnum.Left
    }

    function getIconColor() {
        return props.iconBackgroundColor ? ColorEnum.White : props.labelColor ? colorEnumAsCssVar(props.labelColor) : ''
    }

    function getIconBackgroundColor() {
        return props.iconBackgroundColor ? colorEnumAsCssVar(props.iconBackgroundColor) : 'transparent'
    }

    function getIconClass() {
        const obj: CSSProperties = {};
        const iconColor = getIconColor();
        const iconBackgroundColor = getIconBackgroundColor();

        if (iconColor) {
            obj.color = iconColor;
        }

        if (iconBackgroundColor) {
            obj.backgroundColor = iconBackgroundColor;
        }

        return obj;
    }

    return (
        <div
            style={{
                backgroundColor: props.backgroundColor ? colorEnumAsCssVar(props.backgroundColor) : '',
                borderColor: props.borderColor ? colorEnumAsCssVar(props.borderColor) : 'transparent',
            }}
            className={styles.root}
        >
            {props.icon && getIconPosition() === IconPositionEnum.Left &&
                <i
                    className={(props.iconBackgroundColor ? styles.iconBackground : '') + ` ${props.icon} ${styles.icon}`}
                    style={getIconClass()}
                />
            }
            <Text size={FontSizeEnum.Xsm} weight={FontWeightEnum.Medium} color={props.labelColor}>
                {props.label}
            </Text>
            {props.icon && getIconPosition() === IconPositionEnum.Right &&
                <i
                    className={`${props.icon} ${styles.icon}`}
                    style={{color: colorEnumAsCssVar(props.labelColor)}}
                />
            }
        </div>
    )
}

export default Badge
