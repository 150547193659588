import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import ButtonLink from '../generics/ButtonLink';
import CandidateViewingStatus from '../../enums/candidateViewingStatus';

function ViewingInvitationResponse({invitationStatus, toggleResponse}) {
    const {t} = useTranslation();

    return (
        <>
            <h3 className="text-lg font-medium leading-6 text-gray-900">
                {t(`viewing.invitation.${invitationStatus}.title`)}
            </h3>
            <p className='text-base text-gray-600 whitespace-pre-line'>
                {t(`viewing.invitation.${invitationStatus}.body`)}
                <br/><br/>
                <ButtonLink onClick={() => toggleResponse(invitationStatus)}>
                    {t(`viewing.invitation.${invitationStatus}.link.text`)}
                </ButtonLink>
            </p>
        </>
    );
}

ViewingInvitationResponse.propTypes = {
    invitationStatus: PropTypes.oneOf([
        CandidateViewingStatus.Accepted,
        CandidateViewingStatus.Rejected,
    ]).isRequired,
    toggleResponse: PropTypes.func.isRequired,
};

export default ViewingInvitationResponse;
