import ColorEnum from '../../../enums/colorEnum'
import Modal from './Modal'

interface ErrorModalProps {
    readonly isOpen: boolean
    readonly children: React.ReactNode
    readonly onCloseButtonClick?: () => void
}

export default function ErrorModal(props: ErrorModalProps) {
    return (
        <Modal
            isOpen={props.isOpen}
            icon={'ri-error-warning-line'}
            iconColor={ColorEnum.Error600}
            iconBackgroundColor={ColorEnum.Error100}
            flowyColor={ColorEnum.Error50}
            onCloseButtonClick={() => props.onCloseButtonClick?.()}
        >
            {props.children}
        </Modal>
    )
}
