import styles from './Tooltip.module.css';
import MaxWidthEnum from '../../enums/maxWidthEnum'
import Text from '../typography/text/Text'
import FontSizeEnum from '../typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'

export interface TooltipProps {
    readonly text: string | React.ReactNode
    readonly dataTestId?: string
    readonly isDisabled?: boolean
    readonly children: React.ReactNode
    readonly noHover?: boolean
    readonly isVisible?: boolean
    readonly size?: MaxWidthEnum
}

export default function Tooltip(props: TooltipProps) {
    function getTooltipCSSClassnames(): string {
        return `${styles.tooltip} ${props.isVisible ? styles.isVisible : ''} ${props.noHover ? styles.noHover : ''}`
    }

    function getTooltipBalloonCSSClassnames(): string {
        return `${styles.tooltipBalloon} ${styles[props.size ? props.size : MaxWidthEnum.Md]}`
    }

    return (
        <div className={styles.root}>
            <div className={getTooltipCSSClassnames()} data-testid={props.dataTestId}>

                {props.children}

                {(props.isDisabled === undefined || props.isDisabled === false) &&
                    <>
                        <div className={getTooltipBalloonCSSClassnames()}>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Primary50}>{props.text}</Text>
                            <div className={styles.tooltipTextArrow}></div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
