import api from '../../../utils/api';
import {START_LOADING, STOP_LOADING} from '../ui/types';


export const getAllListings = (token, params) => async (dispatch) => {
    dispatch({type: START_LOADING});

    let apiRoute = `api/transactional-listings-overview`
    let dispatchType = 'GET_LISTINGS'

    if (params.status === 'available') {
        params.status = ['available']
    } else if (params.status === 'archived') {
        params.status = ['archived', 'closed'];
    }

    api.getJsonLd(apiRoute, params)
        .then((response) => {
            dispatch({
                type: dispatchType,
                payload: response,
            });
        })
        .catch((e) => {
            dispatch({type: STOP_LOADING});
            console.log(e);
        })
        .finally(() => dispatch({type: STOP_LOADING}));
};
