import { request } from '../../../utils/api';
import { setNotification } from '../ui/setNotification';

export const requestExtraInformation = (token, ids, agentEmail) => dispatch => {
    dispatch({ type: 'START_LOADING' });
    request(token).post('/api/candidates/enriching', {
        values:
            agentEmail,
        candidatesIds: ids
    })
        .then(response => {
            if (response.status >= 200 && response.status < 300) {
                dispatch({ type: 'STOP_LOADING' });
                dispatch(setNotification({
                    id: Date.now(),
                    message: ids.length > 1 ? 'Informatieverzoeken opgestuurd.' : 'Informatieverzoek opgestuurd.',
                    type: 'success'
                }));
            }
        })
        .catch((e) => {
            dispatch({ type: 'STOP_LOADING' });
            console.log(e);
        })
        .finally(() => dispatch({ type: 'STOP_LOADING' }));

};
