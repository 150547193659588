import React, { CSSProperties } from 'react';
import { Card } from '../card';
import Text from '../../atoms/typography/Text';
import IconWithText from '../../atoms/typography/IconWithText';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import styles from './ListingCard.module.css';
import MoneyEuroCircleLineIcon from '../../atoms/icons/MoneyEuroCircleLineIcon';
import Ruler2LineIcon from '../../atoms/icons/Ruler2LineIcon';
import DoorOpenLineIcon from '../../atoms/icons/DoorOpenLineIcon';
import { useTranslation } from 'react-i18next';
import formatSurface from '../../utils/formatSurface';
import { formatPrice, formatRooms } from '../../utils';

interface ListingCardProps {
    readonly imageUrl: string;
    readonly street: string;
    readonly houseNumber: string;
    readonly postalCode: string;
    readonly city: string;
    readonly price: number;
    readonly surface: number;
    readonly rooms: number;
}

const ListingCard = ({
    imageUrl,
    street,
    houseNumber,
    postalCode,
    city,
    price,
    surface,
    rooms,
}: ListingCardProps) => {
    const { t } = useTranslation();

    const listingImageStyleAttr: CSSProperties = { };

    if (imageUrl !== '') {
        listingImageStyleAttr.backgroundImage = `url('${imageUrl}')`;
    }

    return (
        <Card
            elevated
            hasPadding={false}
        >
            <div className={styles.ListingDetails}>
                <div
                    className={styles.image}
                    style={listingImageStyleAttr}
                ></div>
                <div style={{ padding: '16px 16px 24px 16px' }}>
                    <div className={styles.grid}>
                        <Text
                            tag="h2"
                            size={FontSizeEnum.DisplayXs}
                            weight={FontWeightEnum.Bold}
                        >{`${street} ${houseNumber}`}</Text>
                        <Text
                            tag="h3"
                            color={ColorEnum.Gray400}
                            size={FontSizeEnum.Md}
                        >{`${postalCode}, ${city}`}</Text>
                        <div className={styles.details}>
                            <IconWithText
                                icon={<MoneyEuroCircleLineIcon/>}
                            >{formatPrice(price)}</IconWithText>
                            <IconWithText
                                icon={<Ruler2LineIcon/>}
                            >{formatSurface(surface)}</IconWithText>
                            <IconWithText
                                icon={<DoorOpenLineIcon/>}
                            >{formatRooms(rooms)}</IconWithText>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default ListingCard;
