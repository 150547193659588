import Text from '../../../../../typography/text/Text';
import FontSizeEnum from '../../../../../typography/enums/fontSizeEnum';
import fontWeightEnum from '../../../../../typography/enums/fontWeightEnum';
import colorEnum from '../../../../../../enums/colorEnum';
import logoProperize from '../../../../../../assets/img/logoproperize.png';
import HorizontalDivider from '../../../../../generics/horizontalDivider/HorizontalDivider';
import Title from '../../../../../typography/title/Title';
import ColorEnum from '../../../../../../enums/colorEnum';
import {Trans, useTranslation} from 'react-i18next';
import styles from './TrialPeriodInfoSegment.module.css';

export default function TrialPeriodInfoSegment() {
    const { t } = useTranslation();
    return (
        <div className={styles.root}>

            <div className={styles.headerContainer}>

                <div className={styles.header}>
                    <div className={styles.logo}>
                        <div className={styles.poweredBy}>
                            <Text size={FontSizeEnum.Sm} weight={fontWeightEnum.Medium} color={colorEnum.Primary600}>
                                {t('candidate.screening.modal.trialInfo.poweredBy')}
                            </Text>
                        </div>
                        <img src={logoProperize} alt="logo Properize"/>
                    </div>
                </div>

                <div className={styles.badgeContainer}>
                    <div className={styles.badge}>
                        <Text size={FontSizeEnum.Sm} weight={fontWeightEnum.Medium} color={colorEnum.Primary600}>
                            {t('candidate.screening.modal.trialInfo.badge')}
                        </Text>
                    </div>
                </div>

            </div>

            <HorizontalDivider/>

            <div className={styles.titles}>
                <Title size={FontSizeEnum.Xsm} weight={fontWeightEnum.Bold} color={ColorEnum.Primary700}>
                    {t('candidate.screening.modal.trialInfo.title')}
                </Title>
                <div className={styles.detail}>
                    <Text size={FontSizeEnum.Md} weight={fontWeightEnum.Regular} color={colorEnum.Primary700}>
                        <i> {t('candidate.screening.modal.trialInfo.details')}</i>
                    </Text>
                </div>
                <div className={styles.subtitle}>
                    <Text size={FontSizeEnum.Lg} weight={fontWeightEnum.Medium} color={colorEnum.Primary700}>
                        <Trans
                            i18nKey="candidate.screening.modal.trialInfo.description"
                            components={{Bold: <strong/>}}
                        />
                    </Text>
                </div>
            </div>

            <div className={styles.conditionsCard}>
                <div className={styles.conditions}>

                    <i className="ri-checkbox-circle-fill"></i>

                    <Text size={FontSizeEnum.Md} color={colorEnum.Gray800}>
                        {t('candidate.screening.modal.trialInfo.condition.1')}
                    </Text>

                    <i className="ri-checkbox-circle-fill"></i>

                    <Text size={FontSizeEnum.Md} color={colorEnum.Gray800}>
                        {t('candidate.screening.modal.trialInfo.condition.2')}
                    </Text>

                    <i className="ri-checkbox-circle-fill"></i>

                    <Text size={FontSizeEnum.Md} color={colorEnum.Gray800}>
                        {t('candidate.screening.modal.trialInfo.condition.3')}
                    </Text>

                    <i className="ri-checkbox-circle-fill"></i>

                    <Text size={FontSizeEnum.Md} color={colorEnum.Gray800}>
                        {t('candidate.screening.modal.trialInfo.condition.4')}
                    </Text>

                    <i className="ri-checkbox-circle-fill color"></i>

                    <Text size={FontSizeEnum.Md} color={colorEnum.Gray800}>
                        {t('candidate.screening.modal.trialInfo.condition.5')}
                    </Text>

                </div>
            </div>
            <div className={styles.proText}>

                <a href="https://properize.com/" target="_blank" rel="noopener noreferrer">

                    <Text size={FontSizeEnum.Md} weight={fontWeightEnum.Medium} color={colorEnum.Primary500}>
                        {t('candidate.screening.modal.titleInfo.properize')}
                        <i className={styles.proIcon + ' ri-external-link-line'}></i>
                    </Text>

                </a>
            </div>
        </div>
    )
}
