import SkeletonComponent from '../../SkeletonComponent';
import IconLabel from '../../generics/iconLabel/IconLabel';
import styles from './ListingProperties.module.css';
import ListingPropertiesModel from './logics/model/listingPropertiesModel';

export interface ListingPropertiesProps {
    readonly model?: ListingPropertiesModel
}

export function ListingProperties(props: ListingPropertiesProps) {
    return (
        <div className={styles.root}>
            {props.model ?
                <>
                    <IconLabel
                        label={props.model.getFormattedPrice()}
                        icon="ri-money-euro-circle-line text-lg"
                    />
                    <IconLabel
                        label={props.model.getFormattedSurface()}
                        icon="ri-ruler-2-line"
                    />
                    <IconLabel
                        label={props.model.getFormattedRooms()}
                        icon="ri-door-open-line"
                    />
                </>
                : <>
                    <SkeletonComponent width={75}/>
                    <SkeletonComponent width={75}/>
                    <SkeletonComponent width={75}/>
                </>
            }
        </div>
    )
}
