import moment from 'moment';
import { GUARANTOR, HOUSEHOLD_TYPE, RENTAL_PERIOD } from './constants';

export function renderHousehold(householdType, household) {

    let type = null;
    switch (householdType) {
        case HOUSEHOLD_TYPE.WITH_FAMILY:
            type = 'Gezin' + ` (${household || '-'})`;
            break;
        case HOUSEHOLD_TYPE.SINGLE:
            type = 'Alleen';
            break;
        case HOUSEHOLD_TYPE.WITH_FRIENDS:
            type = 'Vrienden' + ` (${household || '-'})`;
            break;
        case HOUSEHOLD_TYPE.WITH_PARTNER:
            type = 'Partner' + ` (${household || '-'})`;
            break;
        default:
            type = '-';
    }

    if (type) {
        return type;
    }
}

export function renderGuarantor(g) {
    switch (g) {
        case GUARANTOR.ABROAD:
            return 'Buitenland';
        case GUARANTOR.IN_NETHERLANDS:
            return 'Nederland';
        case GUARANTOR.NO_GUARANTOR:
            return 'Geen';
        default:
            return '-';
    }
}

export function renderPets(p) {
    switch (p) {
        case null:
            return '-';
        case undefined:
            return '-';
        case true:
            return 'Ja';
        case false:
            return 'Nee';
        default:
            return '-';
    }
}


export function renderOrigin(o) {
    switch (o) {
        case 'huurwoningen': return 'HUURWONINGEN';
        case 'pararius': return 'PARARIUS';
        case 'funda': return 'FUNDA';
        case 'easy-form': return 'EIGEN WEBSITE';
        case 'none': return 'ONBEKEND';
        default: return '-';
    }
}

export function renderIncome(i) {
    switch (parseInt(i)) {
        case 0: return 0;
        case 10001:
            return '10.000+';
        default: return `${i - 499} - ${i}`;
    }
}

export function renderGender(g) {
    switch (g) {
        case 'FEMALE': return 'Mevr.';
        case 'MALE': return 'Dhr.';
        default: return;
    }
}


export function renderWork(w) {
    switch (w) {
        case 'EMPLOYED_BY_EMPLOYER': return 'Werkzaam bij werkgever';
        case 'EMPLOYED': return 'Werkzaam bij werkgever';
        case 'EMPLOYER': return 'Werkzaam bij werkgever';
        case 'ENTREPRENEUR': return 'Ondernemer of ZZP-er';
        case 'STUDENT': return 'Student';
        case 'RETIRED': return 'Gepensioneerd';
        case 'UNEMPLOYED': return 'Niet werkzaam';
        default: return '-';
    }
}


export function renderTerm(t) {
    switch (t) {
        case RENTAL_PERIOD.INDEFINITE.value: return 'Onbepaalde tijd';
        case RENTAL_PERIOD.ZERO_THREE.value: return '0 - 3 mnd';
        case RENTAL_PERIOD.THREE_SIX.value: return '3 - 6 mnd';
        case RENTAL_PERIOD.SIX_TWELVE.value: return '6 - 12 mnd';
        case RENTAL_PERIOD.YEAR.value: return '1 - 2 years';
        default: '-';
    }
}

export function renderStartDate(d) {
    let date = moment(d);
    return date.isValid() ? date.fromNow() : '-';
}
