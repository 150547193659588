import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import LayoutLandingPage from '../../components/generics/LayoutLandingPage';
import LanguageSwitchButtons from '../../components/locales/LanguageSwitchButtons';
import ListingCard from '../../components/listings/ListingCard';
import {VIEWING_INVITATION_STATUS} from '../../utils/constants';
import api from '../../utils/api';
import ViewingStatusInvitationResponse from '../../components/viewings/ViewingStatusInvitationResponse';
import InlineTextLoader from '../../components/molecules/inlineLoader/InlineTextLoader';
import ViewingInvitationInvalid from '../../components/viewings/ViewingInvitationInvalid';
import {useFeature} from '@growthbook/growthbook-react';
import {setNotification} from '../../redux/actions/ui/setNotification';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';

export default function ViewingStatusInvitationPage() {
    const {id, status} = useParams();
    const [listing, setListing] = useState(null);
    const [agent, setAgent] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const {t} = useTranslation();

    const invitationStatus = status === 'accept'
        ? VIEWING_INVITATION_STATUS.ACCEPTED
        : VIEWING_INVITATION_STATUS.DECLINED

    const navigate = useNavigate();

    useEffect(() => {
        api.getWithoutAuth(`api/viewing/${id}/redirect-url`)
            .then(res => {
                const homeSeekerApplicationId = res.result.homeseeker_application_id

                if (status === 'accept') {
                    navigate(`/homeseeker/${homeSeekerApplicationId}/viewing/${id}/timeslot/schedule`);
                } else {
                    navigate(`/homeseeker/${homeSeekerApplicationId}/viewing/${id}/timeslot/declined`);
                }
            })
            .catch(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'failed',
                    message: t('api.request.failed')
                }));
            });
    }, [id]);

    return (
        <>
            <LayoutLandingPage>
                {!isLoading ?
                    <>
                        <div className="grid grid-cols-4 gap-3">
                            <div className="col-span-3"/>
                            <div className='col-span-1 flex flex-row justify-end items-center'>
                                <LanguageSwitchButtons/>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-8 md:gap-2">
                            <div className="md:col-span-3 ">
                                <div className="grid grid-cols-6 gap-2">
                                    <ListingCard
                                        listing={listing}
                                        agent={agent} className='col-span-6'
                                    />
                                </div>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-5">
                                <div className="overflow-hidden rounded-lg my-4">
                                    <div className="shadow overflow-hidden rounded-lg">
                                        <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                                            {listing.viewing_has_passed ?
                                                <ViewingInvitationInvalid viewing={listing}/>
                                                :
                                                <ViewingStatusInvitationResponse
                                                    invitationStatus={invitationStatus}
                                                    listing={listing}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </> :
                    <InlineTextLoader/>
                }
            </LayoutLandingPage>
        </>
    )
}
