import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

function formError(props) {
    const { message } = props;

    return (
        <>
            {message && (
                <div className=" my-2 flex items-center text-red-400">
                    <ExclamationCircleIcon className="h-5 w-5 mr-1" />
                    <p className="text-sm font-medium">{message}</p>
                </div>
            )}
        </>
    );
}



formError.propTypes = {
    message: PropTypes.string,
};

export default formError;
