import Title from '../../../../typography/title/Title';
import FontSizeEnum from '../../../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../../../typography/enums/fontWeightEnum';
import ColorEnum from '../../../../../enums/colorEnum';
import Text from '../../../../typography/text/Text';
import {useTranslation} from 'react-i18next';

export default function ScreeningErrorFragment() {

    const {t} = useTranslation()

    return (
        <div>
            <div className='pb-2 pt-2'>
                <Title size={FontSizeEnum.Xxsm} weight={FontWeightEnum.Semibold} color={ColorEnum.Gray800}>
                    {t('candidate.screening.modal.error.title')}
                </Title>
            </div>

            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                {t('candidate.screening.modal.error.description')}
            </Text>

            <br/>

            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                {t('candidate.screening.modal.error.description2')}
            </Text>
        </div>
    )
}
