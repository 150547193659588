import Badge from '../../generics/badge/Badge'
import CandidateScreeningRequestStatusEnum from '../../../enums/candidateScreeningRequestStatusEnum'
import {useEffect, useState} from 'react'
import CandidateScreeningStatusBadgeModel from './model/candidateScreeningStatusBadgeModel'
import {useTranslation} from 'react-i18next'

interface CandidateScreeningStatusBadgeProps {
    readonly screeningStatus: CandidateScreeningRequestStatusEnum
}

export default function CandidateScreeningStatusBadge(props: CandidateScreeningStatusBadgeProps) {

    const {t} = useTranslation()

    const [model, setModel] = useState(new CandidateScreeningStatusBadgeModel({ status: props.screeningStatus }))

    useEffect(() => {
        setModel(new CandidateScreeningStatusBadgeModel({ status: props.screeningStatus }))
    }, [props.screeningStatus]);

    return (
        <Badge
            label={t(model.getLabel())}
            labelColor={model.getLabelColor()}
            backgroundColor={model.getBackgroundColor()}
            borderColor={model.getBorderColor()}
        />
    )
}
