import EnumType from '../../../../enums/general/enumType';

export default class ModalButtonModel<E extends EnumType> {
    constructor (
        public readonly label: string,
        public readonly isPrimary: boolean,
        public readonly actionType: E,
        public readonly isDisabled: boolean = false,
        public readonly isHidden: boolean = false
    ) {}

    public withIsDisabled(val: boolean): ModalButtonModel<E> {
        return new ModalButtonModel(this.label, this.isPrimary, this.actionType, val);
    }

    public withIsHidden(val: boolean): ModalButtonModel<E> {
        return new ModalButtonModel(this.label, this.isPrimary, this.actionType, this.isDisabled, val);
    }
}
