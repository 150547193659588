import React, { ReactNode } from 'react';
import styles from './Dropdown.module.css';
import { classNames } from '../../utils';
import { Card } from '../card';

interface DropdownProps {
    isActive: boolean;
    readonly className?: string;
    readonly children: ReactNode
    readonly align?: 'left' | 'right';
}

const Dropdown = ({
    isActive = false,
    className,
    children,
    align = 'right'
}: DropdownProps) => {
    return (
        <div className={classNames(className && className, styles.Dropdown, isActive && styles.isActive, align && styles[align])}>
            <Card
                className={styles.dropdownCard}
                hasPadding={false}
                elevated
            >
                {children}
            </Card>
        </div>
    );
};

export default Dropdown;
