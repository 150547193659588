import {PageContainer} from '../../atoms/page-container';
import {TopHeader} from '../../organisms/top-header';
import {Card} from '../../organisms/card';
import {Main} from '../../organisms/main';
import Text from '../../atoms/typography/Text';
import {ColorEnum, FontSizeEnum, FontWeightEnum} from '../../enums';
import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import styles from './ViewingInvitationAcceptedPage.module.css';
import {Trans, useTranslation} from 'react-i18next';
import {ListingCard} from '../../organisms/listing-card';
import {classNames} from '../../utils';
import {useDispatch} from 'react-redux';
import {setNotification} from '../../../redux/actions/ui/setNotification';
import getAcceptedViewingWithTimeslot, {
    Agency,
    Invitee,
    Listing,
    Viewing
} from '../../utils/api/viewing/getAcceptedViewingWithTimeslot';
import ButtonNew from '../../molecules/button/Button';
import currentLanguage from '../../utils/currentLanguage';
import moment from 'moment/moment';
import SkeletonComponent from '../../../components/SkeletonComponent';

const ViewingInvitationAcceptedPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const locale = currentLanguage();
    const {homeseekerApplicationId, viewingId} = useParams();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [agency, setAgency] = useState<Agency | null>(null);
    const [listing, setListing] = useState<Listing | null>(null);
    const [invitee, setInvitee] = useState<Invitee | null>(null);
    const [viewing, setViewing] = useState<Viewing | null>(null);

    useEffect(() => {
        fetchAcceptedViewingWithTimeslot();
    }, []);

    const fetchAcceptedViewingWithTimeslot = async () => {
        try {
            await getAcceptedViewingWithTimeslot(homeseekerApplicationId, viewingId)
                .then((response) => {
                    setAgency(response.agency);
                    setListing(response.listing);
                    setViewing(response.viewing);
                    setInvitee(response.invitee);
                    setIsLoading(false);
                }
                ).catch((e) => {
                    throw e;
                });
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        }
    }

    const viewingDateWithTime = () => {
        if (!viewing || !invitee) {
            return '';
        }

        if (invitee.start_time !== null && invitee.end_time !== null) {
            return `${moment(viewing.start_date_time).locale(locale).format('dddd D MMMM,')} ${invitee.start_time} - ${invitee.end_time}`;
        } else {
            return `${moment(viewing.start_date_time).locale(locale).format('dddd D MMMM,')} ${moment(viewing.start_date_time).locale(locale).format('HH:mm')} - ${moment(viewing.end_date_time).locale(locale).format('HH:mm')} `;
        }
    }

    return (
        <PageContainer backgroundColor={ColorEnum.Gray100}>
            <TopHeader hasTranslationSelector={true}/>
            <Main>
                <div className={classNames(styles.cardsGrid, styles.gridXl)}>
                    {!isLoading ?
                        <>
                            <div className={styles.listingCard}>

                                {listing &&
                                    <ListingCard
                                        imageUrl={listing.image_url}
                                        street={listing.street}
                                        houseNumber={listing.house_number}
                                        postalCode={listing.postal_code}
                                        city={listing.city}
                                        price={listing.price}
                                        surface={listing.surface}
                                        rooms={listing.rooms}
                                    />
                                }
                            </div>
                            <div>
                                <Card
                                    elevated
                                >
                                    <div className="ta-left">
                                        <div className={styles.gridLg}>
                                            <Text
                                                tag="h1"
                                                color={ColorEnum.Gray900}
                                                size={FontSizeEnum.Lg}
                                            >{t('viewing.invitation.timeslot.accepted.title')}</Text>
                                            <Text
                                                tag="p"
                                                color={ColorEnum.Gray800}
                                                weight={FontWeightEnum.Light}
                                            >
                                                <Trans
                                                    i18nKey="viewing.invitation.timeslot.accepted.date.text"
                                                    values={{date: viewingDateWithTime()}}
                                                    components={{strong: <strong/>}}
                                                />
                                            </Text>
                                            <div>
                                                <Text
                                                    tag="p"
                                                    color={ColorEnum.Gray800}
                                                    weight={FontWeightEnum.Light}
                                                >{t(`viewing.invitation.timeslot.accepted.decline.link.text.prefix`)}
                                                </Text>
                                                <Link
                                                    to={`/homeseeker/${homeseekerApplicationId}/viewing/${viewingId}/timeslot/declined`}
                                                >
                                                    <ButtonNew
                                                        variant="link"
                                                    >{t(`viewing.invitation.timeslot.accepted.decline.link.text`)}</ButtonNew>
                                                </Link>
                                            </div>
                                            <div>
                                                <Text
                                                    tag="p"
                                                    color={ColorEnum.Gray800}
                                                    weight={FontWeightEnum.Light}
                                                >{t(`viewing.invitation.timeslot.accepted.schedule.link.text.prefix`)}
                                                </Text>
                                                <Link
                                                    to={`/homeseeker/${homeseekerApplicationId}/viewing/${viewingId}/timeslot/schedule`}
                                                >
                                                    <ButtonNew
                                                        variant="link"
                                                    >{t(`viewing.invitation.timeslot.accepted.schedule.link.text`)}</ButtonNew>
                                                </Link>
                                            </div>

                                            <Text
                                                tag="p"
                                                color={ColorEnum.Gray800}
                                                weight={FontWeightEnum.Light}
                                            >{t('viewing.invitation.timeslot.accepted.body')}</Text>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </>
                        :
                        <>
                            <SkeletonComponent height={300} width={300}/>
                            <SkeletonComponent height={400}/>
                        </>
                    }
                </div>
            </Main>
        </PageContainer>
    );
};

export default ViewingInvitationAcceptedPage;
