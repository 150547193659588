import ModalModel from '../../../../modal/logic/models/modalModel';
import ModalButtonModel from '../../../../modal/logic/models/modalButtonModel';
import ScreeningModalActionType from './screeningModalActionType';
import ScreeningModalFragmentEnum from './screeningModalFragmentEnum';
import CandidateModel from '../../../../../models/candidate/candidateModel';
import ModalType from '../../../../../enums/modalType';

export default class ScreeningModalModel implements ModalModel<ScreeningModalActionType> {

    private constructor(
        public readonly icon: string,
        public readonly isOpen: boolean,
        public readonly isLoading: boolean,
        public readonly areCoTenantsAllowed: boolean,
        public readonly candidate: CandidateModel,
        public readonly currentFragment: ScreeningModalFragmentEnum,
        public readonly buttons: ReadonlyArray<ModalButtonModel<ScreeningModalActionType>>,
        public readonly type?: ModalType,
        public readonly isExtraExplanationShowing: boolean = true,
    ) {}

    public static createEmpty(): ScreeningModalModel {
        return new ScreeningModalModel(
            undefined,
            false,
            false,
            false,
            null,
            ScreeningModalFragmentEnum.ScreeningActivation,
            []
        )
    }

    public static createWithScreeningActivationContent(candidate: CandidateModel): ScreeningModalModel {
        return new ScreeningModalModel(
            undefined,
            true,
            false,
            false,
            candidate,
            ScreeningModalFragmentEnum.ScreeningActivation,
            [
                new ModalButtonModel('actions.cancel', false, ScreeningModalActionType.Cancel),
                new ModalButtonModel('candidate.screening.modal.activate', true, ScreeningModalActionType.Activate, true)
            ]
        )
    }

    public static createWithActivateScreeningSuccessContent(candidate: CandidateModel): ScreeningModalModel {
        return new ScreeningModalModel(
            undefined,
            true,
            false,
            false,
            candidate,
            ScreeningModalFragmentEnum.ScreeningActivationSuccess,
            [
                new ModalButtonModel('candidate.screening.modal.success.continue', true, ScreeningModalActionType.Continue)
            ]
        )
    }

    public static createWithActivateCandidateContent(candidate: CandidateModel): ScreeningModalModel {
        return new ScreeningModalModel(
            'ri-contacts-line',
            true,
            false,
            false,
            candidate,
            ScreeningModalFragmentEnum.ScreeningCandidate,
            [
                new ModalButtonModel('actions.cancel', false, ScreeningModalActionType.Cancel),
                new ModalButtonModel('candidate.screening.modal.screen.button.screen', true, ScreeningModalActionType.ScreenCandidate)
            ]
        )
    }

    public static createWithScreeningError(candidate: CandidateModel): ScreeningModalModel {
        return new ScreeningModalModel(
            'ri-error-warning-line',
            true,
            false,
            false,
            candidate,
            ScreeningModalFragmentEnum.ScreeningError,
            [
                new ModalButtonModel('actions.cancel', false, ScreeningModalActionType.Cancel),
                new ModalButtonModel('candidate.screening.modal.screen.button.screen', true, ScreeningModalActionType.ScreenCandidate)
            ],
            ModalType.Error,
        )
    }

    public withIsLoading(val: boolean): ScreeningModalModel {
        return new ScreeningModalModel(this.icon, this.isOpen, val, this.areCoTenantsAllowed, this.candidate, this.currentFragment, this.buttons, this.type, this.isExtraExplanationShowing)
    }

    public withIsOpen(val: boolean): ScreeningModalModel {
        return new ScreeningModalModel(this.icon, val, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment, this.buttons, this.type, this.isExtraExplanationShowing)
    }

    public enableActivateButton(): ScreeningModalModel {
        const newButtons = this.withIsActivateButtonDisabled(false)
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment, newButtons, this.type, this.isExtraExplanationShowing)
    }

    public disableActivateButton(): ScreeningModalModel {
        const newButtons = this.withIsActivateButtonDisabled(true)
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment,  newButtons, this.type, this.isExtraExplanationShowing)
    }

    private withIsActivateButtonDisabled(val: boolean): ModalButtonModel<ScreeningModalActionType>[] {
        return this.buttons.map(b => {
            if (b.actionType === ScreeningModalActionType.Activate) {
                return b.withIsDisabled(val)
            }
            return b
        })
    }

    public enableScreenButton(): ScreeningModalModel {
        const newButtons = this.withIsScreenButtonDisabled(false)
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment, newButtons, this.type, this.isExtraExplanationShowing)
    }

    public disableScreenButton(): ScreeningModalModel {
        const newButtons = this.withIsScreenButtonDisabled(true)
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment,  newButtons, this.type, this.isExtraExplanationShowing)
    }

    public withAreCoTenantsAllowed(val: boolean): ScreeningModalModel {
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, val, this.candidate, this.currentFragment, this.buttons, this.type, this.isExtraExplanationShowing)
    }

    public withIsExtraExplanationShowing(val: boolean): ScreeningModalModel {
        return new ScreeningModalModel(this.icon, this.isOpen, this.isLoading, this.areCoTenantsAllowed, this.candidate, this.currentFragment, this.buttons, this.type, val)
    }

    private withIsScreenButtonDisabled(val: boolean): ModalButtonModel<ScreeningModalActionType>[] {
        return this.buttons.map(b => {
            if (b.actionType === ScreeningModalActionType.ScreenCandidate) {
                return b.withIsDisabled(val)
            }
            return b
        })
    }
}
