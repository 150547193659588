import { useTranslation } from 'react-i18next';
import isNumber from './isNumber';

const formatRooms = (rooms?: number): string => {
    if (!isNumber(rooms)) {
        return '-';
    }

    const { t } = useTranslation();

    if (rooms == 1) {
        return t('general.room.singular', { number: rooms });
    }

    return t('general.room.plural', { number: rooms });
}

export default formatRooms;
