import {Trans, useTranslation} from 'react-i18next';
import Button from '../../../../components/buttons/button/Button';
import ButtonType from '../../../../components/buttons/button/logic/models/buttonType';
import HorizontalDivider from '../../../../components/generics/horizontalDivider/HorizontalDivider';
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum';
import Text from '../../../../components/typography/text/Text';
import ColorEnum from '../../../../enums/colorEnum';

export interface ConfirmationFragmentProps {
    readonly onUnsubscribeButtonClick: () => void
}

export default function ConfirmationFragment(props: ConfirmationFragmentProps) {

    const {t} = useTranslation()

    return (
        <>
            <h1 className="text-2xl font-bold text-primary-700">
                {t('marketingMessagesUnsubscribe.confirmation.header')}
            </h1>

            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                <Trans
                    i18nKey="marketingMessagesUnsubscribe.confirmation.text"
                    components={{ strong: <strong/>}}
                />
            </Text>
            <Button
                isFullWidth
                buttonType={ButtonType.Primary}
                title={t('marketingMessagesUnsubscribe.confirmation.button')}
                onClick={() => props.onUnsubscribeButtonClick()}
            />

            <HorizontalDivider />

            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                {t('marketingMessagesUnsubscribe.smallText.part1')}
            </Text>

            <Text size={FontSizeEnum.Xsm} color={ColorEnum.Gray500}>
                {t('marketingMessagesUnsubscribe.smallText.part2')}
            </Text>
        </>
    )

}
