import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import ListingPropertiesModel from '../../../../components/listings/listingProperties/logics/model/listingPropertiesModel'

interface AddressModelProps {
    readonly listingId: string
    readonly value: string,
    readonly listingProperties: ListingPropertiesModel
}

export default class AddressModel extends ImmutableModel<AddressModel, AddressModelProps> {

    public getLinkToListing(): string {
        return `/dashboard/advertenties/${this.value.listingId}`
    }

}
