export default class AgencyDetailsModel {
    constructor(
        public readonly id: string,
        readonly name: string,
        readonly defaultEmail: string,
        readonly houseNumber: string,
        readonly street: string,
        readonly city: string,
        readonly postalCode: string
    ) {}

    public static createEmpty(): AgencyDetailsModel {
        return new AgencyDetailsModel('', '','','','','','')
    }
}
