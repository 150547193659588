import {VIEWING_INVITATION_STATUS_TRANSITIONS} from '../../../utils/constants';
import {request} from '../../../utils/api';
import {START_LOADING, STOP_LOADING} from '../ui/types';
import {ACCEPTED, DECLINED} from './types';

export const submitViewingInvitationResponse = (candidateId, hasAccepted = true) => dispatch => {
    const action = hasAccepted ?
        VIEWING_INVITATION_STATUS_TRANSITIONS.ACCEPT :
        VIEWING_INVITATION_STATUS_TRANSITIONS.DECLINE;
    const actionType = hasAccepted ? ACCEPTED : DECLINED;

    dispatch({ type: START_LOADING });
    request().post(`/api/viewing/${candidateId}/${action}`)
        .then(() => {
            dispatch({ type: actionType });
        })
        .catch((e) => {
            console.log(e);
        }).finally(() => dispatch({ type: STOP_LOADING }));
};
