import React, { ReactNode, useEffect, useRef, useState, MouseEvent } from 'react';
import styles from './HomeseekerSelector.module.css';
import { Dropdown } from '../dropdown';
import CheckLineIcon from '../../atoms/icons/CheckLineIcon';
import ArrowDownSLineIcon from '../../atoms/icons/ArrowDownSLineIcon';
import { Badge } from '../../molecules/badge';
import CloseIcon from '../../atoms/icons/CloseIcon';
import getAcceptedViewingWithTimeslot, { HomeseekerApplicationForSelector } from '../../utils/api/homeseeker-application/findHomeseekerApplicationsForSelector';
import { classNames } from '../../utils';
import { setNotification } from '../../../redux/actions/ui/setNotification';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import ButtonNew from '../../molecules/button/Button';
import Text from '../../atoms/typography/Text';
import { ColorEnum, FontSizeEnum, FontWeightEnum } from '../../enums';
import LoadingSpinner from '../../atoms/loading-spinner';

interface HomeseekerSelectorProps {
    transactionalListingId: string;
    preselectedHomeseekers?: HomeseekerApplicationForSelector[];
    onChange?: (e: HomeseekerApplicationForSelector[]) => void
}

const HomeseekerSelector = ({
    transactionalListingId,
    preselectedHomeseekers = [],
    onChange
}: HomeseekerSelectorProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const homeseekerSelectorDivRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownActive, setIsDropdownActive] = useState(false);
    const [selectedHomeseekers, setSelectedHomeseekers] = useState<HomeseekerApplicationForSelector[]>(preselectedHomeseekers);
    const [list, setList] = useState<HomeseekerApplicationForSelector[]>(preselectedHomeseekers);
    const [collapsedBadges, setCollapsedBadges] = useState(true);

    const maxCollapsedBadges = 5;

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideClick);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        onChange?.(selectedHomeseekers);
    }, [selectedHomeseekers]);

    const fetchHomeseekerApplications = async (inputValue = '') => {
        setIsLoading(true);

        const searchValue = inputValue.trim();
        try {
            const response = await getAcceptedViewingWithTimeslot(transactionalListingId, searchValue);

            setList([
                ...selectedHomeseekers.filter(item => {
                    if (searchValue === '') {
                        return true;
                    }

                    return item.fullName.toLowerCase().includes(searchValue.toLowerCase());
                }),
                ...response.filter(item => !selectedHomeseekers.find(findItem => findItem.id === item.id))
            ]);
        } catch {
            dispatch(setNotification({
                id: Date.now(),
                type: 'failed',
                message: t('api.request.failed')
            }));
        } finally {
            setIsLoading(false);
        }
    }

    const showCollapsableOptions = () => {
        return selectedHomeseekers.length > maxCollapsedBadges + 1;
    }

    const showMaxSelectedHomeseekers = () => {
        return (collapsedBadges && showCollapsableOptions()) ? maxCollapsedBadges : selectedHomeseekers.length;
    }

    const handleOutsideClick = (event: PointerEvent) => {
        if (homeseekerSelectorDivRef.current && !homeseekerSelectorDivRef.current.contains(event.target as Node)) {
            setIsDropdownActive(false);
        }
    };

    const renderList = (): ReactNode[] => {
        return list.map((listItem, index) => {
            return (
                <li
                    key={index}
                    className={classNames(styles.listItem, selectedHomeseekers.includes(listItem) && styles.selected)}
                    onClick={() => handleListItemClick(listItem)}
                >
                    {listItem.fullName}
                    <CheckLineIcon className={styles.checkLineIcon}/>
                </li>
            );
        })
    }

    const handleBadgeCloseIconClick = (e: MouseEvent, idToRemove: string) => {
        e.stopPropagation();
        setSelectedHomeseekers(prevItems => prevItems.filter(homeseekerApplication => homeseekerApplication.id !== idToRemove));
    }

    const handleListItemClick = (item: HomeseekerApplicationForSelector) => {
        const itemIsSelected = selectedHomeseekers.find(findItem => findItem.id === item.id);

        if (itemIsSelected) {
            setSelectedHomeseekers(prevItems => prevItems.filter(filterItem => filterItem.id !== item.id));
        } else {
            setSelectedHomeseekers(prevItems => [...prevItems, item]);
        }
    }

    const renderBadgeCloseIcon = (id: string): ReactNode => {
        return (
            <div
                className={styles.BadgeCloseIcon}
                onClick={(e) => handleBadgeCloseIconClick(e, id)}
            >
                <CloseIcon/>
            </div>
        );
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        fetchHomeseekerApplications(inputValue);
    }

    const handleInputFocus = () => {
        if (!isDropdownActive) {
            fetchHomeseekerApplications();
        }
    }

    const handleHomeseekerSelectorClick = () => {
        inputRef.current?.focus();

        setIsDropdownActive(true);
    }

    const handleSelectAllHomeseekersClick = () => {
        setSelectedHomeseekers(list);
    }

    const handleDeselectAllHomeseekersClick = () => {
        setSelectedHomeseekers([]);
    }

    const toggleCollapsedBadges = () => {
        setCollapsedBadges(!collapsedBadges)
    }

    return (
        <div
            ref={homeseekerSelectorDivRef}
            className={classNames(styles.HomeseekerSelector, isDropdownActive && styles.active)}
            onClick={handleHomeseekerSelectorClick}
        >
            <div className={styles.label}>
                <label
                    htmlFor="homeseeker-selector-input"
                >
                    <Trans
                        i18nKey="homeseeker.selector.label"
                        values={{ number: selectedHomeseekers.length }}
                        components={{ strong: <strong /> }}
                    />
                </label>
                <ButtonNew
                    variant='link'
                    onClick={handleDeselectAllHomeseekersClick}
                >{t('general.deselect.all')}</ButtonNew>
            </div>
            <div className={styles.wrapper}>
                <div className={styles.InputSelect}>
                    {selectedHomeseekers.slice(0, showMaxSelectedHomeseekers()).map((homeseeker, index) => {
                        return (
                            <Badge key={index} label={homeseeker.fullName} after={renderBadgeCloseIcon(homeseeker.id)} />
                        );
                    })}
                    {showCollapsableOptions() &&
                        <>
                            {collapsedBadges &&
                                <Badge label={`+${selectedHomeseekers.length - maxCollapsedBadges}`} />
                            }
                            <ButtonNew
                                variant='link-underline'
                                onClick={toggleCollapsedBadges}
                            >{collapsedBadges ? t('homeseeker.selector.view.more') : t('homeseeker.selector.view.less')}</ButtonNew>
                        </>
                    }
                    <input
                        id="homeseeker-selewctor-input"
                        ref={inputRef}
                        placeholder={t('homeseeker.selector.placeholder')}
                        onChange={handleInputChange}
                        onFocus={handleInputFocus}
                    />
                    <ArrowDownSLineIcon className={styles.ArrowDownIcon}/>
                </div>
                <Dropdown
                    isActive={isDropdownActive}
                >
                    <ul className={styles.list}>
                        <li className={classNames(styles.listItem, styles.listHeader)}>
                            <ButtonNew
                                variant='link'
                                onClick={handleSelectAllHomeseekersClick}
                            >{t('general.select.all')}</ButtonNew>
                        </li>
                        {isLoading ?
                            <li className={classNames(styles.listItem, styles.loadingListItem)}>
                                <LoadingSpinner/>
                            </li>
                            :
                            renderList()
                        }
                    </ul>
                </Dropdown>
            </div>
            <Text
                tag="p"
                color={ColorEnum.Gray500}
                size={FontSizeEnum.Sm}
                weight={FontWeightEnum.Light}
            >
                {t('candidate.action.invite.viewing.homeseekerSelector.subText')}
            </Text>
        </div>
    );
};

export default HomeseekerSelector;
