import {Menu, Transition} from '@headlessui/react';
import {Fragment} from 'react';
import PropTypes from 'prop-types';
import {ChevronDownIcon} from '@heroicons/react/solid';

/**
 * @deprecated Use components/buttonGroup/ButtonGroup instead!
 */
function ButtonGroup({children, dropDownItems, isDisabled = false, dropsUp = false}) {
    return (
        <div className="inline-flex rounded-md shadow-sm">
            {children}
            <Menu as="div" className="relative -ml-px block">
                <Menu.Button
                    as='button'
                    disabled={isDisabled}
                    className={`relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 
                    py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 
                    focus:outline-none focus:ring-1 focus:ring-indigo-500
                    ${isDisabled ? 'bg-transparent text-gray-200 hover:bg-gray-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}>
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className={`absolute right-0 z-10 mt-2 -mr-1 w-56 origin-top-right rounded-md bg-white
                     shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${dropsUp ? 'bottom-full mb-2' : ''}
                     `}>
                        <div className="py-1">
                            {dropDownItems}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
}

ButtonGroup.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    dropDownItems: PropTypes.node,
    isDisabled: PropTypes.bool,
    dropsUp: PropTypes.bool,
};

export default ButtonGroup;
