import {numberToEnum} from '../../../../../utils/enumType';

enum RentalPeriod {
    Indefinite = 0,
    ZeroMonthToThreeMonths = 3,
    ThreeMonthsToSixMonths = 6,
    SixMonthsToTwelveMonths = 12,
    MoreThanOneYear = 24
}

export function rentalPeriodEnumFromNumber(num: number): RentalPeriod {
    try {
        return numberToEnum(num, RentalPeriod)
    } catch {
        return RentalPeriod.Indefinite
    }
}

export default RentalPeriod
