import ActionType from '../../../../enums/actionType';

export default class ButtonGroupItemModel{
    
    constructor(
        public readonly label: string,
        public readonly actionType: ActionType,
        public readonly isDisabled = false,
        public readonly isHidden = false
    ) {}

    public withIsDisabled(val: boolean): ButtonGroupItemModel {
        return new ButtonGroupItemModel(this.label, this.actionType, val, this.isHidden)
    }

    public withIsHidden(val: boolean): ButtonGroupItemModel {
        return new ButtonGroupItemModel(this.label, this.actionType, this.isDisabled, val)
    }

}
