import { ReactNode } from 'react';
import styles from './HighlightBlock.module.css';
import classNames from '../../utils/classNames';

type HighLightBlockType = 'warning' | 'info';

interface Props {
    readonly type?: HighLightBlockType
    readonly children: ReactNode
}

const HighlightBlock = ({type, children}: Props) => {
    return (
        <div className={classNames(styles.highlightBlock, type ? styles[type] : null)}>
            <div className={styles.icon}>
                <i className='ri-information-line' />
            </div>
            <div>
                {children}
            </div>
        </div>
    )
}

export default HighlightBlock
