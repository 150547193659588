import {Component, ReactNode} from 'react'

interface ErrorBoundaryProps {
    readonly fallback: ReactNode
    readonly children: ReactNode
}

export default class ErrorBoundary extends Component<ErrorBoundaryProps, {hasError: boolean}> {

    constructor(props: ErrorBoundaryProps) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(error: Error) {
        console.error(error.name, error.message, error.stack)
        return { hasError: true }
    }

    componentDidCatch(error, info) {
        console.error(error, info.componentStack)
    }

    render() {
        if (this.state.hasError) {
            return this.props.fallback
        }
        return this.props.children
    }
}
