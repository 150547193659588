import React from 'react';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import {
    ChevronDownIcon,
} from '@heroicons/react/solid';
import LeadflowDropdownOption from './LeadflowDropdownOption';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function LeadflowDropdown(props) {
    const { value, options, onChange, position } = props;
    const renderLabelForValue = options.find(option => option.value === value).label;
    return (
        <Menu as="span" className="relative z-20 inline-block text-left ">
            <div>
                <Menu.Button
                    className={classNames('inline-flex justify-between items-center rounded-md border ' + 'px-4 py-2 w-[244px] w-full whitespace-nowrap',
                        'bg-white  text-gray-700 border-gray-300',
                        'focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300 w-auto',
                    )}>
                    <p>{renderLabelForValue}</p>
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className={classNames(`origin-top-${position} absolute ${position}-0 mt-2 w-auto`,
                    'rounded-md shadow-lg bg-white ring-1 ring-black ',
                    'ring-opacity-5 divide-y divide-gray-100 focus:outline-none')}>
                    {options.map((option, optionIndex) =>
                        <Menu.Item key={optionIndex} as="div">
                            <LeadflowDropdownOption
                                hidden={option.hidden}
                                label={option.label}
                                value={option.value}
                                onChange={onChange}
                            />
                        </Menu.Item>
                    )}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}

LeadflowDropdown.propTypes = {
    value: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        hidden: PropTypes.bool.isRequired
    })),
    onChange: PropTypes.func.isRequired,
    position: PropTypes.string.isRequired
};

export default LeadflowDropdown;
