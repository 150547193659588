import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../redux/actions/ui/removeNotification';
import styles from './BasicNotification.module.css'

function BasicNotification(props) {
    const { id, type, message } = props;
    const dispatch = useDispatch();

    setTimeout(() => {
        dispatch(removeNotification(id));
    }, 8000);

    return (
        <div
            key={message}
            className="w-full flex flex-col items-start justify-center"
        >
            <div
                className={`${styles.notification} ${
                    type === 'failed' ? styles.notificationFailed : styles.notificationSuccess
                }`}
            >
                <div className={styles.notificationContent}>
                    <div className={styles.iconContainer}>
                        {type === 'success' ? (
                            <CheckCircleIcon className={styles.iconSuccess} aria-hidden="true" />
                        ) : (
                            <ExclamationCircleIcon className={styles.iconFailed} aria-hidden="true" />
                        )}
                    </div>
                    <div className={styles.messageContainer}>
                        <p className={styles.message}>{message}</p>
                    </div>
                    <div className={styles.closeButtonContainer}>
                        <button
                            type="button"
                            className={`${styles.closeButton} ${
                                type === 'failed' ? styles.closeButtonFailed : styles.closeButtonSuccess
                            }`}
                            onClick={() => dispatch(removeNotification(id))}
                        >
                            <span className="sr-only">Close</span>
                            <XIcon className={styles.closeIcon} aria-hidden="true" />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

BasicNotification.propTypes = {
    id: PropTypes.number.isRequired,
    message: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
};

export default BasicNotification;
