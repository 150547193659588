import { XCircleIcon } from '@heroicons/react/outline'

export interface AlertProps {
    readonly testId?: string
    readonly message: string
}

export default function Alert(props: AlertProps) {
    return (
        <div className="rounded-md bg-red-50 p-4 mt-4 border-red-100 border-2" data-testid={props?.testId}>
            <div className="flex">
                <XCircleIcon className="h-5 w-5 text-red-400 " aria-hidden="true" />
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">{props.message}</h3>
                </div>
            </div>
        </div>
    )
}
