export default class TranslationModel {
    constructor(
        public readonly key: string,
        public readonly vars?: { [key: string]: string }
    ) { }

    public withVars(vars: { [key: string]: string }): TranslationModel {
        return new TranslationModel(this.key, vars);
    }
}
