import Title from '../../../../typography/title/Title';
import FontSizeEnum from '../../../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../../../typography/enums/fontWeightEnum';
import ColorEnum from '../../../../../enums/colorEnum';
import HorizontalDivider from '../../../../generics/horizontalDivider/HorizontalDivider';
import Text from '../../../../typography/text/Text';
import {useTranslation} from 'react-i18next';
import {CheckCircleIcon} from '@heroicons/react/solid';
import styles from './ScreeningActivationSuccessFragment.module.css';

export default function ScreeningActivationSuccessFragment() {

    const {t} = useTranslation()

    return (
        <div className={styles.root}>
            <CheckCircleIcon
                width="48"
                height="48"
                className="text-success-600"
            />

            <Title size={FontSizeEnum.Xsm} weight={FontWeightEnum.Bold} color={ColorEnum.Gray800}>
                {t('candidate.screening.modal.success.title')}
            </Title>

            <div className={styles.divider}>
                <HorizontalDivider />
            </div>

            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray900}>
                {t('candidate.screening.modal.success.description')}
            </Text>
        </div>
    )
}
