import BadgeType from '../../enums/BadgeType'

export default class BadgeModel {
    constructor(
        public readonly label: string,
        public readonly type: BadgeType,
        public readonly icon?: string,
        public readonly tooltip?: string
    ) {}
}
