import React from 'react';
import PropTypes from 'prop-types';

function LayoutLandingPage ({children}) {
    return (
        <>
            <div className='min-h-screen w-screen bg-gray-100'>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8 py-10">
                    <div className="px-4 sm:px-6 lg:px-8 ">
                        <div className="pt-10">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

LayoutLandingPage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

export default LayoutLandingPage;
