import FontSizeEnum from '../../../typography/enums/fontSizeEnum'
import FontWeightEnum from '../../../typography/enums/fontWeightEnum'
import fontWeightEnum from '../../../typography/enums/fontWeightEnum'
import ColorEnum from '../../../../enums/colorEnum'
import Text from '../../../typography/text/Text'
import {Trans} from 'react-i18next'
import Checkbox from '../../../forms/checkbox/Checkbox'
import styles from './AssignTenantFragment.module.css'


interface AssignTenantFragmentProps {
    readonly name: string
    readonly onArchiveCheckboxChange: (checked: boolean) => void
}

export default function AssignTenantFragment(props: AssignTenantFragmentProps) {


    return (
        <>
            <div className="pb-2 pt-2">
                <Text size={FontSizeEnum.Lg} color={ColorEnum.Primary700} weight={FontWeightEnum.Medium}>
                    <Trans
                        i18nKey="candidate.assignTenant.model.title"
                        values={{name: props.name}}
                        components={{Bold: <strong/>}}
                    />
                </Text>
            </div>
            <div className={styles.checkboxContainer}>
                <div className={styles.checkbox}>
                    <Checkbox
                        onChange={(e) => props.onArchiveCheckboxChange(e.target.checked)}
                    />
                </div>
                <div className={styles.textAreaContainer}>
                    <div className="mb-1">
                        <Text size={FontSizeEnum.Md} color={ColorEnum.Gray700} weight={fontWeightEnum.Medium}>
                            <Trans
                                i18nKey="candidate.assignTenant.model.form.archive"
                                components={{Link: <a/>}}
                            />
                        </Text>
                    </div>
                    <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500} weight={fontWeightEnum.Regular}>
                        <Trans
                            i18nKey="candidate.assignTenant.model.form.archive.description"
                            components={{Link: <a/>}}
                        />
                    </Text>
                    <br/>
                    <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500} weight={fontWeightEnum.Regular}>
                        <Trans
                            i18nKey="candidate.assignTenant.model.form.archive.description2"
                            components={{Link: <a/>}}
                        />
                    </Text>
                </div>
            </div>
        </>
    )
}
