import ColorEnum from '../../../../enums/colorEnum'
import CandidatePlace from '../../../../enums/candidatePlace'

interface BadgeModelCreationAccordingToStatusCombo {
    readonly statusCombo: CandidatePlace
    readonly createFunc: () => CandidateStatusComboBadgeModel
}

export default class CandidateStatusComboBadgeModel {

    private static readonly CREATION_ACCORDING_TO_STATUS_COMBO: ReadonlyArray<BadgeModelCreationAccordingToStatusCombo> = [
        {
            statusCombo: CandidatePlace.New,
            createFunc: CandidateStatusComboBadgeModel.createForNew
        },
        {
            statusCombo: CandidatePlace.BeingAssessed,
            createFunc: CandidateStatusComboBadgeModel.createForBeingAssessed
        },
        {
            statusCombo: CandidatePlace.OnWaitingList,
            createFunc: CandidateStatusComboBadgeModel.createForOnWaitingList
        },
        {
            statusCombo: CandidatePlace.Rejected,
            createFunc: CandidateStatusComboBadgeModel.createForRejected
        },
        {
            statusCombo: CandidatePlace.Approved,
            createFunc: CandidateStatusComboBadgeModel.createForApproved
        },
        {
            statusCombo: CandidatePlace.InvitedToViewing,
            createFunc: CandidateStatusComboBadgeModel.createForInvitedToViewing
        },
        {
            statusCombo: CandidatePlace.AcceptedInvitationToViewing,
            createFunc: CandidateStatusComboBadgeModel.createForAcceptedInvitationToViewing
        },
        {
            statusCombo: CandidatePlace.DeclinedInvitationToViewing,
            createFunc: CandidateStatusComboBadgeModel.createForDeclinedInvitationToViewing
        },
        {
            statusCombo: CandidatePlace.ReceivedExtraInfoForm,
            createFunc: CandidateStatusComboBadgeModel.createForReceivedExtraInfoForm
        },
        {
            statusCombo: CandidatePlace.FilledInExtraInfoForm,
            createFunc: CandidateStatusComboBadgeModel.createForFilledInExtraInfoForm
        },
        {
            statusCombo: CandidatePlace.ScreeningSent,
            createFunc: CandidateStatusComboBadgeModel.createForScreeningSent
        },
        {
            statusCombo: CandidatePlace.ScreeningSentWithCoTenants,
            createFunc: CandidateStatusComboBadgeModel.createForScreeningSentWithCoTenants
        },
        {
            statusCombo: CandidatePlace.ScreeningNotCompleted,
            createFunc: CandidateStatusComboBadgeModel.createForScreeningNotCompleted
        },
        {
            statusCombo: CandidatePlace.ScreeningNotCompletedWithCoTenants,
            createFunc: CandidateStatusComboBadgeModel.createForScreeningNotCompletedWithCoTenants
        },
        {
            statusCombo: CandidatePlace.IsListingTenant,
            createFunc: CandidateStatusComboBadgeModel.createForIsListingTenant
        }
    ]

    private constructor(
        public readonly label: string,
        public readonly labelColor: ColorEnum,
        public readonly backgroundColor: ColorEnum,
        public readonly borderColor?: ColorEnum,
        public readonly tooltipText?: string,
        public readonly icon?: string,
        public readonly iconBackgroundColor?: ColorEnum,
    ) {}

    public static createAccordingToStatusCombo(statusCombo: CandidatePlace): CandidateStatusComboBadgeModel {
        const item = CandidateStatusComboBadgeModel.CREATION_ACCORDING_TO_STATUS_COMBO.find(i => i.statusCombo === statusCombo)
        if (item) {
            return item.createFunc()
        }
        throw Error('CandidateStatusBadgeModel can not be resolved')
    }

    private static createForNew(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.none',
            ColorEnum.Primary700,
            ColorEnum.Primary50
        )
    }

    private static createForBeingAssessed(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.assessment',
            ColorEnum.Primary700,
            ColorEnum.Primary50
        )
    }

    private static createForOnWaitingList(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.waitinglist',
            ColorEnum.Warning900,
            ColorEnum.Warning50
        )
    }

    private static createForRejected(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.rejected',
            ColorEnum.Error700,
            ColorEnum.Error50
        )
    }

    private static createForApproved(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.suitable',
            ColorEnum.Success700,
            ColorEnum.Success50
        )
    }

    private static createForInvitedToViewing(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.invitation.sent',
            ColorEnum.Primary700,
            ColorEnum.Primary50
        )
    }

    private static createForAcceptedInvitationToViewing(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.invitation.confirmed',
            ColorEnum.Success700,
            ColorEnum.Success50
        )
    }

    private static createForDeclinedInvitationToViewing(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.invitation.rejected',
            ColorEnum.Error700,
            ColorEnum.Error50
        )
    }

    private static createForReceivedExtraInfoForm(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.extraInfo.requested',
            ColorEnum.Primary700,
            ColorEnum.Primary50
        )
    }

    private static createForFilledInExtraInfoForm(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.extraInfo.sent',
            ColorEnum.Success700,
            ColorEnum.Success50
        )
    }

    private static createForScreeningSent(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.screening.sent',
            ColorEnum.Primary600,
            ColorEnum.Primary50,
            ColorEnum.Primary600,
            'candidate.status.screening.sent.tooltip',
        )
    }

    private static createForScreeningSentWithCoTenants(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.screenings.sent',
            ColorEnum.Primary600,
            ColorEnum.Primary50,
            ColorEnum.Primary600,
            'candidate.status.screening.sentWithCotenants.tooltip',
            'ri-group-fill',
            ColorEnum.Primary600
        )
    }

    private static createForScreeningNotCompletedWithCoTenants(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.screenings.notCompleted',
            ColorEnum.Error700,
            ColorEnum.Error50,
            ColorEnum.Error700,
            'candidate.status.screening.notCompletedWithCotenants.tooltip',
            'ri-group-fill',
            ColorEnum.Error700
        )
    }

    private static createForScreeningNotCompleted(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.screening.notCompleted',
            ColorEnum.Error700,
            ColorEnum.Error50,
            ColorEnum.Error700,
            'candidate.status.screening.notCompleted.tooltip'
        )
    }

    private static createForIsListingTenant(): CandidateStatusComboBadgeModel {
        return new CandidateStatusComboBadgeModel(
            'candidate.status.tenant',
            ColorEnum.Success50,
            ColorEnum.Success700,
            undefined,
            undefined,
            'ri-user-star-line'
        )
    }
}
