import styles from './TextArea.module.css';
import Quill from 'quill';
import React, {useEffect, useRef, useState} from 'react';
import QuillResizeImage from 'quill-resize-image';
import 'quill/dist/quill.snow.css';
import ImageCompress from 'quill-image-compress';
import classNames from '../../componentsNew/utils/classNames';

Quill.register('modules/imageCompress', ImageCompress);
Quill.register('modules/resize', QuillResizeImage);

(window as any).QuillResizeImage = QuillResizeImage;

const H1 = 1;
const H2 = 2;
const H3 = 3;
const H4 = 4;
const p = false; // this value is false to enable the <p> tag. (Basically saying headers = false)

export interface TextAreaProps {
    readonly allowImage?: boolean;
    readonly value: string;
    readonly onChange: (val: string) => void;
    readonly id: string;
    readonly className?: string;
}

export default function TextArea({ className = 'defaultQuillEditor', id, ...props}: TextAreaProps) {
    const [editorContent, setEditorContent] = useState<string>(props.value);
    const editorRef = useRef(null);
    const quillRef = useRef<Quill>(null);

    const toolbarImportModules = ['link'];

    let image = '';
    if (props.allowImage == true) {
        image = 'image';
        toolbarImportModules.push(image);
    }

    const modules: Record<string, any> = {
        toolbar: [
            [{ 'header': [H1, H2, H3, H4, p] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ color: [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            toolbarImportModules
        ],
        imageCompress: {
            maxWidth: 600
        },
        resize: {
            locale: {},
        }
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color',
        'list', 'indent',
        'link'
    ];

    if (props.allowImage) {
        formats.push(image);
    }

    useEffect(() => {
        quillRef.current = new Quill(editorRef.current, {
            formats,
            modules,
            theme: 'snow',
        });

        quillRef.current.on('text-change', handleChange);

        return () => {
            quillRef.current.off('text-change', handleChange);
        };
    }, []);

    useEffect(() => {
        if (quillRef.current.root.innerHTML !== editorContent) {
            const editor = document.querySelector(`#${id} .ql-editor`);
            editor.innerHTML = editorContent;
        }
    }, [editorContent]);

    useEffect(() => {
        if (props.value !== editorContent) {
            setEditorContent(props.value);
        }
    }, [props.value]);

    const handleChange = () => {
        const editorContent = quillRef.current.root.innerHTML;

        setEditorContent(editorContent);

        props.onChange(editorContent);
    }

    return (
        <div className={classNames(styles.root, className && className)} id={id}>
            <div ref={editorRef}></div>
        </div>
    )
}
