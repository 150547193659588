import React from 'react';
import PropTypes from 'prop-types';

function ButtonLink({children, className, onClick, underline}) {
    return (
        <button
            onClick={() => onClick()}
            className={`appearance-none font-semibold text-primary ${underline ? 'underline' : ''} ${className}`}>
            {children}
        </button>
    );
}

ButtonLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    underline: PropTypes.bool
};

export default ButtonLink;
