import api from '../utils/api'

export default class EmailAddressConfirmationService {
    public async confirmHomeseekerEmail(homeseekerId: string): Promise<void> {
        await api.postWithoutAuth('api/confirm-homeseeker-email-address', {
            homeseekerId: homeseekerId
        })
    }

    public async subscribeHomeseekerToMarketingMessages(homeseekerId: string): Promise<void> {
        await api.postWithoutAuth('api/subscribe-homeseeker-to-marketing-messages', {
            homeseekerId: homeseekerId
        })
    }

    public async getRedactedHomeseekerEmail(homeseekerId: string): Promise<string> {
        const { redactedEmail } = await api.getWithoutAuth(`api/get-redacted-email-of-homeseeker/${homeseekerId}`)
        return redactedEmail
    }
}
