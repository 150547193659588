import styles from './LoginPage.module.css'
import Text from '../../components/typography/text/Text'
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import FontWeightEnum from '../../components/typography/enums/fontWeightEnum'
import ColorEnum from '../../enums/colorEnum'
import {Trans, useTranslation} from 'react-i18next'
import Button from '../../components/buttons/button/Button'
import ButtonType from '../../components/buttons/button/logic/models/buttonType'
import InfoBanner from '../../components/molecules/banner/infoBanner/InfoBanner'

interface LoginChangesBannerProps {
    readonly isCloseButtonHidden: boolean
    readonly onCloseButtonClick?: () => void
}

export default function LoginChangesBanner(props: LoginChangesBannerProps) {

    const {t} = useTranslation()

    return (
        <InfoBanner
            isCloseButtonHidden={props.isCloseButtonHidden}
            onCloseButtonClick={() => props.onCloseButtonClick?.()}
        >
            <div className={styles.infoBannerContent}>
                <div>
                    <Text
                        size={FontSizeEnum.Md}
                        weight={FontWeightEnum.Bold}
                        color={ColorEnum.Gray800}
                    >
                        Let op
                    </Text>
                    <Text
                        size={FontSizeEnum.Sm}
                        color={ColorEnum.Gray800}
                    >
                        <Trans
                            i18nKey="loginPage.banner.text"
                            components={{Bold: <strong/>}}
                        />
                    </Text>
                </div>
                <div>
                    <Button
                        title={t('loginPage.banner.button')}
                        buttonType={ButtonType.Primary}
                        isFullWidth={true}
                        onClick={() => window.open('https://help.leadflow.rent/portal/nl/kb/articles/inloggen-met-het-treehouse-account')}
                    />
                </div>
            </div>
        </InfoBanner>
    )
}
