import {useTranslation} from 'react-i18next'
import useStylingForBadgeType from './hooks/useStylingForBadgeType'
import BadgeModel from './logic/models/badgeModel'
import styles from './Badge_Deprecated.module.css'
import Tooltip from '../../tooltip/Tooltip'
import MaxWidthEnum from '../../../enums/maxWidthEnum'

export interface Badge_DeprecatedProps {
    readonly model: BadgeModel
}

function Badge_Deprecated(props: Badge_DeprecatedProps) {

    const {t} = useTranslation()
    const badgeStyle = useStylingForBadgeType(props.model.type)

    return (
        <div className={`has-tooltip ${styles.container}`}>
            <Tooltip text={t(props.model.tooltip)} dataTestId={'badge-tooltip'} isDisabled={false}>
                <div
                    data-testid="badge-label"
                    className={`inline-flex items-center rounded-full whitespace-nowrap px-2.5 my-2 py-0.5 text-xs-medium space-x-1 ${badgeStyle}`}
                >
                    {props.model.icon && (
                        <div>
                            <i
                                data-testid="badge-icon"
                                className={props.model.icon}
                            />
                        </div>
                    )}
                    <div>
                        {t(props.model.label)}
                    </div>
                </div>
            </Tooltip>
        </div>
    )
}

export default Badge_Deprecated
