const TOGGLE_LOADING = 'TOGGLE_LOADING';
const SET_NOTIFICATION = 'SET_NOTIFICATION';
const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
const START_LOADING = 'START_LOADING';
const STOP_LOADING = 'STOP_LOADING';
const SHOW_FORM_SUCCESS = 'SHOW_FORM_SUCCESS';
/* eslint-disable no-undef */
module.exports = {
    TOGGLE_LOADING,
    SET_NOTIFICATION,
    REMOVE_NOTIFICATION,
    START_LOADING,
    STOP_LOADING,
    SHOW_FORM_SUCCESS
};
