import PropTypes from 'prop-types';

/**
 * @deprecated Part of old ButtonGroup. When working with ButtonGroup use components/buttonGroup/ButtonGroup instead of _archivedComponents/_buttonGroup!
 */
function DisabledStatusButton(props) {
    const { onClick, children, type } = props;
    return (
        <button
            onClick={() => onClick()}
            type={type}
            disabled
            className="bg-transparent text-gray-200 hover:bg-gray-50 cursor-not-allowed h-12 w-12 inline-flex items-center justify-center rounded-full border"
        >
            {children}
        </button>
    );
}

DisabledStatusButton.propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    onClick: PropTypes.func,
    type: PropTypes.string
};

export default DisabledStatusButton;
