import {ReactNode} from 'react'
import Banner from '../Banner'
import ColorEnum from '../../../../enums/colorEnum'

interface InfoBannerProps {
    readonly children: ReactNode
    readonly isCloseButtonHidden?: boolean
    readonly onCloseButtonClick?: () => void
}

export default function InfoBanner(props: InfoBannerProps) {
    return (
        <Banner
            backgroundColor={ColorEnum.Primary100}
            borderColor={ColorEnum.Gray200}
            icon={'ri-information-line'}
            iconColor={ColorEnum.Primary600}
            isCloseButtonHidden={props.isCloseButtonHidden}
            onCloseButtonClick={() => props.onCloseButtonClick?.()}
        >
            {props.children}
        </Banner>
    )
}
