import Tooltip from '../../tooltip/Tooltip'
import Badge from '../../generics/badge/Badge'
import IconPositionEnum from '../../generics/badge/iconPositionEnum'
import ColorEnum from '../../../enums/colorEnum'
import {Trans} from 'react-i18next'
import CandidateScreeningRatingBadgeModel from './models/candidateScreeningRatingBadgeModel'

interface CandidateScreeningRatingBadgeProps {
    readonly model: CandidateScreeningRatingBadgeModel
}

export default function CandidateScreeningRatingBadge(props: CandidateScreeningRatingBadgeProps) {
    const rating = props.model.getRating()

    if(rating !== null || rating !== 'undefined') {
        return <>
            <Tooltip
                text={<Trans i18nKey={props.model.getTooltipText()} components={{ ul: <ul style={{ textAlign: 'left', listStyle: 'initial', marginLeft: 8 }}/>, li: <li/> }} />}
            >
                <Badge
                    icon="ri-star-fill"
                    iconPosition={IconPositionEnum.Right}
                    label={props.model.getRating()}
                    labelColor={ColorEnum.White}
                    backgroundColor={ColorEnum.Primary600}
                />
            </Tooltip>
        </>
    }
}
