import React from 'react'
import CandidateSituationProfileModel from './logic/models/candidateSituationProfileModel'
import FormattedDateLabel from '../../formattedDateContent/FormattedDateLabel'
import FormattedDateLabelModel from '../../formattedDateContent/logic/models/formattedDateLabelModel'
import CandidateSituationProfileArrangementEnum from './logic/models/candidateSituationProfileArrangementEnum'
import styles from './CandidateSituationProfile.module.css'
import Tooltip from '../../tooltip/Tooltip'
import Text from '../../typography/text/Text'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'
import ColorEnum from '../../../enums/colorEnum'
import colorEnum from '../../../enums/colorEnum'
import { useTranslation } from 'react-i18next'

export interface CandidateProfileInfoProps {
    readonly model: CandidateSituationProfileModel
    readonly arrangement: CandidateSituationProfileArrangementEnum
}

export default function CandidateSituationProfile(props: CandidateProfileInfoProps) {

    const {t} = useTranslation()

    function getMainContainerCssClassName(): string {
        if (props.arrangement === CandidateSituationProfileArrangementEnum.ThreeColumns) {
            return `${styles.mainContainer} ${styles.threeColumns}`
        }
        return `${styles.mainContainer} ${styles.twoColumns}`
    }

    return (
        <div className="w-full">
            {props.model.isPrivate() ? (
                <>
                    <div className={getMainContainerCssClassName()}>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.income')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-money-euro-circle-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        {props.model.income > 10000 ? '10.000+' : props.model.income}
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.startdate')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-time-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        <FormattedDateLabel model={new FormattedDateLabelModel(props.model.availableSince)}/>
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.household')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-group-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        {props.model.getHouseHoldLabelText()}
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.guarantor')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-hand-coin-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        {props.model.getGuarantorTypeLabelText()}
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.rentterm')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-calendar-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        {props.model.getRentalPeriodLabelText()}
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="col-span-1">
                            <Tooltip text={t('candidate.situation.profile.tooltip.pets')}>
                                <div className={styles.iconLabelContainer}>
                                    <i className={`ri-baidu-line ${styles.icon}`}></i>
                                    <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray800} weight={FontWeightEnum.Medium}>
                                        {props.model.getPetsLabelText()}
                                    </Text>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </>
            ) : (
                <div className={getMainContainerCssClassName()}>
                    <div className={styles.disabledTextContainer}>
                        <Text size={FontSizeEnum.Sm} color={colorEnum.Gray500}>
                            {t('candidate.situation.profile.disabled')}
                        </Text>
                    </div>
                    <div className="col-span-1">
                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-money-euro-circle-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300} weight={FontWeightEnum.Medium}>
                                {props.model.income > 10000 ? '10.000+' : (props.model.income === 0 ? '-' : props.model.income)}
                            </Text>
                        </div>

                    </div>
                    <div className="col-span-1">

                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-time-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300} weight={FontWeightEnum.Medium}>
                                <FormattedDateLabel
                                    model={new FormattedDateLabelModel(props.model.availableSince)}/>
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-group-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300}
                                weight={FontWeightEnum.Medium}>
                                {props.model.getHouseHoldLabelText()}
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-hand-coin-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300}
                                weight={FontWeightEnum.Medium}>
                                {props.model.getGuarantorTypeLabelText()}
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-calendar-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300}
                                weight={FontWeightEnum.Medium}>
                                {props.model.getRentalPeriodLabelText()}
                            </Text>
                        </div>
                    </div>
                    <div className="col-span-1">
                        <div className={styles.iconLabelContainer}>
                            <i className={`ri-baidu-line ${styles.iconDisabled}`}></i>
                            <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray300}
                                weight={FontWeightEnum.Medium}>
                                {props.model.getPetsLabelText()}
                            </Text>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
