import api from '../../../../utils/api';

export interface Agency {
    readonly default_email: string;
    readonly logo: string;
    readonly name: string;
}

export interface Listing {
    readonly city: string;
    readonly house_number: string;
    readonly image_url: string;
    readonly postal_code: string;
    readonly price: number;
    readonly rooms: number;
    readonly show_house_number: boolean;
    readonly street: string;
    readonly surface: number;
}

export interface Viewing {
   readonly end_date_time: string;
   readonly id: string;
   readonly maximum_invitees_per_timeslot: number;
   readonly minutes_per_timeslot: number;
   readonly start_date_time: string;
   readonly viewing_has_passed: boolean;
}

export interface Invitee {
    readonly id: string;
    readonly start_time: string;
    readonly end_time: string;
    readonly status: string;
}

export interface GetAcceptedViewingWithTimeslotResponse {
    readonly agency: Agency;
    readonly listing: Listing
    readonly viewing: Viewing,
    readonly invitee: Invitee
}

const getDeclinedViewingWithTimeslot = async (homeseekerApplicationId: string, viewingId: string): Promise<GetAcceptedViewingWithTimeslotResponse> => {
    return await api.getWithoutAuth(`api/homeseeker/${homeseekerApplicationId}/viewing/${viewingId}/timeslot/declined`);
};

export default getDeclinedViewingWithTimeslot;
