import CandidateScreeningRequestOutcomeEnum from '../../../../enums/candidateScreeningRequestOutcomeEnum'

export default class CandidateScreeningRatingBadgeModel {
    public constructor(
       private readonly rating: number,
       private readonly outcome: CandidateScreeningRequestOutcomeEnum
    ) {}

    public getTooltipText(): string {
        switch(this.outcome) {
            case CandidateScreeningRequestOutcomeEnum.FraudDetected: return 'candidate.status.screening.outcome.fraudDetected'
            case CandidateScreeningRequestOutcomeEnum.FakeDocument: return 'candidate.status.screening.outcome.fakeDocument'
            case CandidateScreeningRequestOutcomeEnum.NegativeEdrScore: return 'candidate.status.screening.outcome.negativeEdrScore'
            case CandidateScreeningRequestOutcomeEnum.EdrFailed: return 'candidate.status.screening.outcome.EdrFailed'
            case CandidateScreeningRequestOutcomeEnum.IdIsDriversLicense: return 'candidate.status.screening.outcome.idIsDriversLicense'
            case CandidateScreeningRequestOutcomeEnum.ForeignIncome: return 'candidate.status.screening.outcome.foreignIncome'
            case CandidateScreeningRequestOutcomeEnum.AverageFraudRisk: return 'candidate.status.screening.outcome.averageFraudRisk'
            case CandidateScreeningRequestOutcomeEnum.IncomeLessThanTwiceRentalPrice: return 'candidate.status.screening.outcome.incomeLessThanTwiceRentalPrice'
            case CandidateScreeningRequestOutcomeEnum.IncomeTwiceToTripleRentalPrice: return 'candidate.status.screening.outcome.incomeTwiceToTripleRentalPrice'
            case CandidateScreeningRequestOutcomeEnum.IncomeHigherThanTripleRentalPrice: return 'candidate.status.screening.outcome.incomeHigherThanTripleRentalPrice'
            default: return ''
        }
    }

    public getRating(): string {
        return `${this.rating}/5`
    }
}
