import ColorEnum from '../../../enums/colorEnum'
import {Trans} from 'react-i18next'
import FontSizeEnum from '../../typography/enums/fontSizeEnum'
import FontWeightEnum from '../../typography/enums/fontWeightEnum'
import Text from '../../typography/text/Text'
import React from 'react'
import CandidateScreeningDocumentsDownloadLinkModel from './models/candidateScreeningDocumentsDownloadLinkModel'
import CandidateModel from '../../../models/candidate/candidateModel'
import styles from '../../organisms/candidateDetailsSidePanel/CandidateDetailsSidePanel.module.css'

interface CandidateScreeningDocumentsDownloadLink {
    readonly candidateModel: CandidateModel
}

export default function CandidateScreeningDocumentsDownloadLink(props: CandidateScreeningDocumentsDownloadLink) {
    const documentsDownloadLinkModel = CandidateScreeningDocumentsDownloadLinkModel.createFromCandidate(props.candidateModel)

    return <>
        {props.candidateModel.hasDoneScreening() ?
            <div className={styles.activeCandidateScreeningDocumentsDownloadLink}>
                <i className="ri-download-2-line"></i>
                <Text size={FontSizeEnum.Sm} color={ColorEnum.Primary600} weight={FontWeightEnum.Medium}>
                    {<Trans i18nKey={documentsDownloadLinkModel.showDownloadDocumentsText()}/>}
                </Text>
            </div> : ''
        }
    </>
}
