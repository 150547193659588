type EnumType = Record<string, string | number>
type EnumValue<T> = T[keyof T]

export function stringToEnum<T extends EnumType>(str: string, ofEnum: T): EnumValue<T> {
    const enumValues = Object.values(ofEnum)

    if (!enumValues.includes(str)) {
        throw new Error(`${str} is not a valid value of ${ofEnum}`)
    }

    return str as EnumValue<T>
}

export function numberToEnum<T extends EnumType>(num: number, ofEnum: T): EnumValue<T> {
    const enumValues = Object.values(ofEnum).map(v => +v)

    if (!enumValues.includes(num)) {
        throw new Error(`${num} is not a valid value of ${ofEnum}`)
    }

    return num as EnumValue<T>
}
