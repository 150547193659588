import React, { ReactNode } from 'react';
import styles from './Card.module.css';
import { ColorEnum } from '../../enums';
import { getColorCssVar } from '../../enums/ColorEnum';
import { classNames } from '../../utils';

interface CardProps {
    readonly className?: string;
    readonly children: ReactNode;
    readonly hasPadding?: boolean;
    readonly elevated?: boolean;
    readonly backgroundColor?: ColorEnum;
}

const Card = ({
    className,
    children,
    hasPadding = true,
    elevated = false,
    backgroundColor = ColorEnum.White
}: CardProps) => {
    return (
        <div
            className={classNames(className && className, styles.Card, hasPadding && styles.hasPadding, elevated && styles.elevated)}
            style={{ backgroundColor: getColorCssVar(backgroundColor) }}
        >
            {children}
        </div>
    );
};

export default Card;
