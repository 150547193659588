import {request} from '../../../utils/api';
import {createAgentLogin} from './createAgentLogin';
import { API_URIS } from '../../../utils/constants';

export const createAgentWithLogins = (token, agentData, loginsToCreate) => (dispatch) => {
    request(token).post(API_URIS.AGENTS_URI, agentData).then((response) => {
        dispatch({type: 'SET_AGENT_DETAILS', payload: response.data});
        return response;
    })
        .then(res => {
            const agentId = res.data.id;
            loginsToCreate.forEach(login => { 
                dispatch(createAgentLogin(token, login, agentId));
            });
        }).catch((e) => console.log(e));
};
