export default function LeadflowLogo() {
    return (
        <svg viewBox="0 0 108 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M107.59 30.9812C108.183 27.6187 108.108 24.1723 107.369 20.8389C106.63 17.5055 105.242 14.3502 103.283 11.5533C101.325 8.75645 98.8346 6.37269 95.9549 4.53815C93.0753 2.70361 89.8625 1.45423 86.5 0.861328L84.055 14.7279C85.5965 14.9997 87.0694 15.5724 88.3895 16.4135C89.7097 17.2545 90.8513 18.3473 91.7491 19.6296C92.6469 20.9118 93.2834 22.3583 93.6222 23.8865C93.961 25.4147 93.9954 26.9946 93.7236 28.5361L107.59 30.9812Z"
                fill="#B8E8DC"/>
            <path d="M0 24.5315H3.47387V7H0V24.5315Z" fill="#032E63"/>
            <path
                d="M12.1068 24.8094C13.4037 24.8094 14.5385 24.5315 15.5112 23.9757C16.4839 23.4044 17.1632 22.6556 17.5492 21.7292L14.7701 20.4091C14.5231 20.8414 14.1757 21.1811 13.7279 21.4281C13.2956 21.6752 12.7707 21.7987 12.1531 21.7987C11.304 21.7987 10.6092 21.5594 10.0688 21.0808C9.52842 20.6021 9.21191 19.9691 9.11928 19.1817H17.9661C18.0278 19.0119 18.0664 18.8112 18.0819 18.5796C18.1127 18.3325 18.1282 18.0855 18.1282 17.8385C18.1282 16.6651 17.8812 15.6152 17.3871 14.6888C16.9085 13.747 16.206 12.9982 15.2796 12.4424C14.3532 11.8866 13.2184 11.6087 11.8752 11.6087C10.6709 11.6087 9.59018 11.8943 8.63293 12.4656C7.67569 13.0368 6.91144 13.8165 6.34018 14.8046C5.78436 15.7928 5.50645 16.9198 5.50645 18.1859C5.50645 19.4056 5.77664 20.5172 6.31702 21.5208C6.8574 22.5243 7.62165 23.3272 8.60977 23.9293C9.5979 24.516 10.7636 24.8094 12.1068 24.8094ZM11.8752 14.3878C12.5854 14.3878 13.1721 14.5962 13.6353 15.0131C14.0985 15.4299 14.3687 15.9703 14.4459 16.6342H9.18875C9.35859 15.9086 9.67509 15.3527 10.1383 14.9667C10.6169 14.5808 11.1959 14.3878 11.8752 14.3878Z"
                fill="#032E63"/>
            <path
                d="M24.0877 24.8094C25.6625 24.8094 26.9285 24.323 27.8858 23.3504V24.5315H31.1281V16.1942C31.1281 15.2833 30.8965 14.4881 30.4333 13.8088C29.9855 13.114 29.3525 12.5736 28.5342 12.1876C27.7159 11.8017 26.7741 11.6087 25.7088 11.6087C24.3656 11.6087 23.1845 11.9175 22.1655 12.535C21.1619 13.1526 20.4749 13.9863 20.1043 15.0362L22.8834 16.3563C23.0996 15.8005 23.4392 15.3527 23.9024 15.0131C24.381 14.6734 24.9291 14.5036 25.5467 14.5036C26.1952 14.5036 26.7047 14.6657 27.0752 14.9899C27.4612 15.3141 27.6542 15.7156 27.6542 16.1942V16.5416L24.1572 17.0974C22.6441 17.3444 21.517 17.8153 20.7759 18.5101C20.0348 19.2049 19.6643 20.0849 19.6643 21.1502C19.6643 22.2928 20.058 23.1882 20.8454 23.8367C21.6483 24.4852 22.729 24.8094 24.0877 24.8094ZM23.254 21.0344C23.254 20.6176 23.3852 20.3011 23.6477 20.0849C23.9101 19.8533 24.2884 19.6912 24.7825 19.5986L27.6542 19.0891V19.4828C27.6542 20.2548 27.3917 20.9109 26.8668 21.4513C26.3418 21.9917 25.6471 22.2619 24.7825 22.2619C24.3502 22.2619 23.9873 22.1538 23.694 21.9376C23.4006 21.7215 23.254 21.4204 23.254 21.0344Z"
                fill="#032E63"/>
            <path
                d="M39.6276 24.8094C40.3996 24.8094 41.1176 24.6859 41.7814 24.4388C42.4608 24.1764 43.0243 23.8058 43.4721 23.3272V24.5315H46.7143V7H43.2405V12.9519C42.345 12.0564 41.133 11.6087 39.6045 11.6087C38.4002 11.6087 37.3117 11.902 36.3391 12.4887C35.3664 13.0754 34.5944 13.8705 34.0231 14.8741C33.4519 15.8622 33.1662 16.9739 33.1662 18.209C33.1662 19.4287 33.4442 20.5404 34 21.5439C34.5712 22.5475 35.3432 23.3426 36.3159 23.9293C37.2886 24.516 38.3925 24.8094 39.6276 24.8094ZM40.0445 21.6829C39.0873 21.6829 38.2999 21.3587 37.6823 20.7102C37.0647 20.0618 36.7559 19.228 36.7559 18.209C36.7559 17.2055 37.0647 16.3795 37.6823 15.731C38.2999 15.0671 39.0873 14.7352 40.0445 14.7352C40.9863 14.7352 41.7506 15.0594 42.3373 15.7078C42.9394 16.3563 43.2405 17.19 43.2405 18.209C43.2405 19.2435 42.9394 20.0849 42.3373 20.7334C41.7506 21.3664 40.9863 21.6829 40.0445 21.6829Z"
                fill="#032E63"/>
            <path
                d="M50.7869 24.5315H54.2608V14.8973H58.7537V24.5315H62.2275V7H58.7537V11.8866H54.2608V11.7013C54.2608 10.5742 54.917 10.0107 56.2293 10.0107C56.4455 10.0107 56.7002 10.0338 56.9936 10.0802V7.1158C56.6385 7.0386 56.2293 7 55.7661 7C54.1913 7 52.9639 7.40914 52.0838 8.22743C51.2192 9.04572 50.7869 10.2037 50.7869 11.7013V11.8866H48.6563V14.8973H50.7869V24.5315Z"
                fill="#032E63"/>
            <path
                d="M71.0494 24.8094C72.2999 24.8094 73.4347 24.5238 74.4538 23.9525C75.4728 23.3812 76.2833 22.6015 76.8855 21.6134C77.503 20.6099 77.8118 19.4751 77.8118 18.209C77.8118 16.9276 77.503 15.7928 76.8855 14.8046C76.2833 13.8165 75.4728 13.0368 74.4538 12.4656C73.4347 11.8943 72.2999 11.6087 71.0494 11.6087C69.7988 11.6087 68.6562 11.8943 67.6218 12.4656C66.6028 13.0368 65.7845 13.8165 65.1669 14.8046C64.5648 15.7928 64.2637 16.9276 64.2637 18.209C64.2637 19.4751 64.5648 20.6099 65.1669 21.6134C65.7845 22.6015 66.6028 23.3812 67.6218 23.9525C68.6562 24.5238 69.7988 24.8094 71.0494 24.8094ZM71.0494 21.6829C70.1076 21.6829 69.3356 21.3587 68.7334 20.7102C68.1467 20.0618 67.8534 19.228 67.8534 18.209C67.8534 17.2055 68.1467 16.3795 68.7334 15.731C69.3356 15.0671 70.1076 14.7352 71.0494 14.7352C71.9912 14.7352 72.7554 15.0671 73.3421 15.731C73.9288 16.3795 74.2222 17.2055 74.2222 18.209C74.2222 19.228 73.9288 20.0618 73.3421 20.7102C72.7554 21.3587 71.9912 21.6829 71.0494 21.6829Z"
                fill="#032E63"/>
            <path
                d="M82.9671 24.5315H85.9778L88.919 16.4258L91.837 24.5315H94.8477L99.2248 11.8866H95.612L93.1571 19.228L90.4243 11.8866H87.4136L84.6577 19.228L82.2028 11.8866H78.6132L82.9671 24.5315Z"
                fill="#032E63"/>
        </svg>
    )
}
