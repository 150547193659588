import ErrorModal from '../../../components/organisms/modal/ErrorModal'
import style from '../../../components/organisms/modal/ErrorModal.module.css'
import Text from '../../../components/typography/text/Text'
import FontSizeEnum from '../../../components/typography/enums/fontSizeEnum'
import FontWeightEnum from '../../../components/typography/enums/fontWeightEnum'
import ColorEnum from '../../../enums/colorEnum'
import {useTranslation} from 'react-i18next'
import Button from '../../../components/buttons/button/Button'
import ButtonType from '../../../components/buttons/button/logic/models/buttonType'
import React from 'react'

interface ViewingInviteErrorModal {
    readonly isOpen: boolean
    readonly onConfirm: () => void
    readonly onCancel: () => void
    readonly onCloseButtonClick?: () => void
}

export default function ViewingInviteErrorModal(props: ViewingInviteErrorModal) {

    const {t} = useTranslation()

    return (
        <ErrorModal isOpen={props.isOpen} onCloseButtonClick={() => props.onCloseButtonClick?.()}>
            <div className={style.root}>
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Semibold}
                    color={ColorEnum.Gray800}
                >
                    {t('viewing.invitation.failure.modal.title')}
                </Text>
                <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Gray800}
                >
                    {t('viewing.invitation.failure.modal.body')}
                </Text>
                <div className={style.buttonContainer}>
                    <Button
                        buttonType={ButtonType.SecondaryGray}
                        title={t('viewing.invitation.failure.modal.button.cancel')}
                        onClick={() => props.onCancel()}
                    />
                    <Button
                        buttonType={ButtonType.Primary}
                        title={t('viewing.invitation.failure.modal.button.retry')}
                        onClick={() => props.onConfirm()}
                    />
                </div>
            </div>
        </ErrorModal>
    )
}
