import PropTypes from 'prop-types';

/**
 * @deprecated Part of old ButtonGroup. When working with ButtonGroup use components/buttonGroup/ButtonGroup instead of _archivedComponents/_buttonGroup!
 */
function ButtonGroupButton({children, onClick, isDisabled=false, type = 'button'}) {
    return (
        <button
            disabled={isDisabled}
            type={type as any}
            onClick={() => onClick()}
            className={`relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm
             font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none 
             focus:ring-1 focus:ring-indigo-500 ${isDisabled ? 'bg-transparent text-gray-200 hover:bg-gray-50 cursor-not-allowed' : 'hover:bg-gray-50'}`}
        >
            {children}
        </button>
    );
}

ButtonGroupButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    type: PropTypes.oneOf(['submit', 'reset', 'button']),
};

export default ButtonGroupButton;
