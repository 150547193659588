import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import styles from './SettingsPage.module.css'
import TopNavigation from '../../components/navigation/TopNavigation'
import { PageContainer } from '../../componentsNew/atoms/page-container';
import Typography from '../../componentsNew/atoms/typography/Typography'
import PageHeader from '../../componentsNew/molecules/pageHeader/PageHeader'
import { useFeature } from '@growthbook/growthbook-react'
import { Navigate } from 'react-router-dom'
import TextArea from '../../components/textArea/TextArea'
import HighlightBlock from '../../componentsNew/molecules/highlightBlock/HighlightBlock'
import { useTranslation } from 'react-i18next'
import Text from '../../components/typography/text/Text'
import FontSizeEnum from '../../components/typography/enums/fontSizeEnum'
import ColorEnum from '../../enums/colorEnum'
import Button from '../../components/buttons/button/Button'
import ButtonType from '../../components/buttons/button/logic/models/buttonType'
import api from '../../utils/api'
import { setNotification } from '../../redux/actions/ui/setNotification'
import { useDispatch } from 'react-redux';
import SkeletonComponent from '../../components/SkeletonComponent'
import useAuth from '../../hooks/useAuth'
import FormError from '../../components/banners/FormError'
import { Select } from '../../componentsNew/organisms/select';
import { ListItem } from '../../componentsNew/organisms/select/Select';
import {InputField} from '../../componentsNew/organisms/input-field';

const SettingsPage = () => {
    const {t} = useTranslation()
    const {user} = useAuth()
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(true)
    const [agencyOptions, setAgencyOptions] = useState<ListItem[]>([])
    const [selectedAgencyId, setSelectedAgencyId] = useState('')
    const [emailSignature, setEmailSignature] = useState('')
    const [isSignatureValid, setIsSignatureValid] = useState(true)

    const FEATURE_SETTINGS_PAGE = useFeature('settings-page').value

    useEffect(() => {
        api.get(`api/agency/settings/mailing`)
            .then(res => {
                const agencies: undefined | ListItem[] = res?.result?.map((agency) => {
                    return {
                        value: agency.id,
                        label: agency.name
                    }
                })
                if (agencies && agencies[0]) {
                    setAgencyOptions(agencies)
                    setSelectedAgencyId(agencies[0].value)
                }
            }).catch(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'failed',
                    message: t('settingsPage.signature.failed')
                }))
            }).finally(() => {
                setIsLoading(false)
            })
    }, []);

    useEffect(() => {
        if (selectedAgencyId) {
            api.get(`api/agency/${selectedAgencyId}/settings/mailing`).then(res => {
                setEmailSignature(res.result.settings.emailSignature ?? '')
            }).catch(() => {
                dispatch(setNotification({
                    id: Date.now(),
                    type: 'failed',
                    message: t('settingsPage.signature.failed')
                }))
            })
        }
    }, [selectedAgencyId])

    useEffect(() => {
        if (!emailSignature || emailSignature === '<p><br></p>') {
            setIsSignatureValid(false)
        } else {
            setIsSignatureValid(true)
        }
    }, [emailSignature]);

    function onSignatureChange(e) {
        setEmailSignature(e)
    }

    function onRejectionTemplateChange(e) {
        console.log('The function for changing reject email template will be put here');
    }

    function onEnrichmentTemplateChange(e) {
        console.log('The function for changing enrichment email template will be put here');
    }

    async function saveMailingSettings() {
        await api.post(`api/agency/settings/mailing/save`, {
            agencyId: selectedAgencyId,
            emailSignature,
            //todo: here we need to include the rejection email and enrichment email when the functions are ready
        }).then(() => {
            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('settingsPage.signature.saved')
            }))
        }).catch(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('settingsPage.signature.error'),
                type: 'failed'
            }))
        })
    }

    async function sendManualTestEmail() {
        await api.post(`api/agency/mailing/send-manual-test-email`, {emailSignature}).then(() => {
            dispatch(setNotification({
                id: Date.now(),
                type: 'success',
                message: t('settingsPage.signature.testMail.send.success')
            }))
        }).catch(() => {
            dispatch(setNotification({
                id: Date.now(),
                message: t('settingsPage.signature.testMail.send.failed'),
                type: 'failed'
            }))
        })
    }

    const handleChangeAgency = (listItem: ListItem) => {
        setSelectedAgencyId(listItem.value)
    }

    const navigate = useNavigate()

    return (
        <>
            {FEATURE_SETTINGS_PAGE ?
                <PageContainer>
                    <TopNavigation/>
                    <main className={styles.container}>
                        <button
                            className={styles.backButton}
                            onClick={() => navigate('/')}
                        >
                            <i className="ri-arrow-left-s-line"
                                style={{marginRight: '8px', color: 'var(--gray-500)', fontSize: '20px'}}
                            ></i>
                            <Text
                                size={FontSizeEnum.Sm}
                                color={ColorEnum.Gray500}
                            >
                                {t('settingsPage.backButton.title')}
                            </Text>
                        </button>
                        <PageHeader title={'settingsPage.title'}/>
                        <div className={styles.formRow}>
                            <HighlightBlock>
                                <Text
                                    size={FontSizeEnum.Sm}
                                    color={ColorEnum.Gray800}
                                >
                                    {t('settingsPage.signature.info')}
                                </Text>
                            </HighlightBlock>
                        </div>
                        <div className={styles.contentContainer}>
                            {agencyOptions.length > 1 &&
                                <>
                                    <div className={styles.contentSection}>
                                        <div className={styles.descriptionPanel}>
                                            <Typography type="h6">
                                                {t('settingsPage.agencySelect.title')}
                                            </Typography>
                                            <Typography type="p">
                                                {t('settingsPage.agencySelect.description')}
                                            </Typography>
                                        </div>
                                        <div className={styles.settingsPanel}>
                                            {!isLoading ?
                                                <form onSubmit={(e) => e.preventDefault()}>
                                                    <div className={styles.formRow}>
                                                        <Select
                                                            selected={selectedAgencyId}
                                                            list={agencyOptions}
                                                            onChange={handleChangeAgency}
                                                        />
                                                    </div>
                                                </form>
                                                :
                                                <>
                                                    <SkeletonComponent height={40} width={300}/>
                                                </>
                                            }
                                        </div>
                                    </div>
                                    <hr/>
                                </>
                            }
                            <div className={styles.contentSection}>
                                <div className={styles.descriptionPanel}>
                                    <Typography type="h6">
                                        {t('settingsPage.rejection.template.title')}
                                    </Typography>
                                    <Typography type="p">
                                        {t('settingsPage.rejection.template.description')}
                                    </Typography>
                                </div>
                                <div className={styles.settingsPanel}>
                                    {!isLoading ?
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className={styles.formRow}>
                                                {t('settingsPage.input.subject')} *
                                                <InputField
                                                />
                                            </div>
                                            <div className={styles.formRow}>
                                                <TextArea
                                                    allowImage={true}
                                                    value={'The content of the rejection template will be set here'}
                                                    onChange={onRejectionTemplateChange}
                                                    className={'quillTextArea'}
                                                    id="settingsPageRejectionEmailtemplateEditor"
                                                />
                                            </div>
                                            <div className={styles.formRow}>
                                                <div className={styles.contentSectionFooter}>
                                                    <div className={styles.alignRight}>
                                                        <div className={styles.fullWidth}>
                                                            <Typography type="st1">
                                                                {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                            </Typography>
                                                        </div>
                                                        <Button
                                                            title={t('settingsPage.signature.agencySelector.testEmail.button')}
                                                            icon="ri-mail-send-line"
                                                            buttonType={ButtonType.SecondaryGray}
                                                            isDisabled={!isSignatureValid}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        :
                                        <>
                                            <SkeletonComponent height={40} width={300}/>
                                            <SkeletonComponent height={20} width={300}/>
                                            <SkeletonComponent height={160} width={300}/>
                                        </>
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className={styles.contentSection}>
                                <div className={styles.descriptionPanel}>
                                    <Typography type="h6">
                                        {t('settingsPage.enrichment.template.title')}
                                    </Typography>
                                    <Typography type="p">
                                        {t('settingsPage.enrichment.template.description')}
                                    </Typography>
                                </div>
                                <div className={styles.settingsPanel}>
                                    {!isLoading ?
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className={styles.formRow}>
                                                {t('settingsPage.input.subject')} *
                                                <InputField/>
                                            </div>
                                            <div className={styles.formRow}>
                                                <TextArea
                                                    allowImage={true}
                                                    value={'The content of the rejection template will be set here'}
                                                    onChange={onEnrichmentTemplateChange}
                                                    className={'quillTextArea'}
                                                    id="settingsEnrichmentEmailtemplateEditor"
                                                />
                                            </div>
                                            <div className={styles.formRow}>
                                                <div className={styles.contentSectionFooter}>
                                                    <div className={styles.alignRight}>
                                                        <div className={styles.fullWidth}>
                                                            <Typography type="st1">
                                                                {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                            </Typography>
                                                        </div>
                                                        <Button
                                                            title={t('settingsPage.signature.agencySelector.testEmail.button')}
                                                            icon="ri-mail-send-line"
                                                            buttonType={ButtonType.SecondaryGray}
                                                            isDisabled={!isSignatureValid}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        :
                                        <>
                                            <SkeletonComponent height={40} width={300}/>
                                            <SkeletonComponent height={20} width={300}/>
                                            <SkeletonComponent height={160} width={300}/>
                                        </>
                                    }
                                </div>
                            </div>
                            <hr/>
                            <div className={styles.contentSection}>
                                <div className={styles.descriptionPanel}>
                                    <Typography type="h6">
                                        {t('settingsPage.signature.title')}
                                    </Typography>
                                    <Typography type="p">
                                        {t('settingsPage.signature.description')}
                                    </Typography>
                                </div>
                                <div className={styles.settingsPanel}>
                                    {!isLoading ?
                                        <form onSubmit={(e) => e.preventDefault()}>
                                            <div className={styles.formRow}>
                                                <TextArea
                                                    allowImage={true}
                                                    value={emailSignature}
                                                    onChange={onSignatureChange}
                                                    className="quillTextArea"
                                                    id="settingsPageSignatureEditor"
                                                />
                                            </div>
                                            {!isSignatureValid &&
                                                <FormError message={t('settingsPage.signature.invalid')}/>
                                            }
                                            <div className={styles.formRow}>
                                                <div className={styles.contentSectionFooter}>
                                                    <div className={styles.alignRight}>
                                                        <div className={styles.fullWidth}>
                                                            <Typography type="st1">
                                                                {t('settingsPage.signature.testMail.notification', {agentEmail: user.email})}
                                                            </Typography>
                                                        </div>
                                                        <Button
                                                            title={t('settingsPage.signature.agencySelector.testEmail.button')}
                                                            icon="ri-mail-send-line"
                                                            buttonType={ButtonType.SecondaryGray}
                                                            isDisabled={!isSignatureValid}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                        :
                                        <>
                                            <SkeletonComponent height={40} width={300}/>
                                            <SkeletonComponent height={20} width={300}/>
                                            <SkeletonComponent height={160} width={300}/>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                    </main>
                    <footer className={styles.saveButton}>
                        <Button
                            title={'Opslaan'}
                            buttonType={ButtonType.Primary}
                            onClick={saveMailingSettings}
                            isDisabled={isLoading || !isSignatureValid}
                        />
                    </footer>
                </PageContainer>
                :
                <Navigate to={'dashboard/advertenties'}/>
            }
        </>
    );
};

export default SettingsPage
