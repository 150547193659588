export default class AddressModel {
    constructor(
        public readonly city: string,
        public readonly houseNumber: string,
        public readonly street: string,
        public readonly postalCode: string
    ) { }

    public getAddressWithoutPostalCode(): string {
        return `${this.street} ${this.houseNumber}, ${this.city}`
    }
}
