import ButtonGroupItemModel from './buttonGroupItemModel';

export default class ButtonGroupMenuModel {
    private constructor(
        public readonly items: ReadonlyArray<ButtonGroupItemModel>,
        public readonly isDisabled = false
    ) {}

    public static create(items: ButtonGroupItemModel[], isDisabled = false): ButtonGroupMenuModel {
        return new ButtonGroupMenuModel(
            items.map(i => i.withIsDisabled(isDisabled)),
            isDisabled
        )
    }

    public updateIsDisabled(val: boolean): ButtonGroupMenuModel {
        const items = this.items.map(i => i.withIsDisabled(val))
        return new ButtonGroupMenuModel(items, val)
    }
}
