import React from 'react'
import styles from './Label.module.css'

interface Props {
    readonly htmlFor: string
    readonly children: string
}

const Label = ({htmlFor, children}: Props) => {
    return (
        <label htmlFor={htmlFor} className={styles.label}>
            {children}
        </label>
    )
}

export default Label
