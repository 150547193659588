import TranslationModel from '../../../../../models/translationModel';
import ModalButtonModel from '../../../../modal/logic/models/modalButtonModel';
import ModalModel from '../../../../modal/logic/models/modalModel';
import ArchiveListingModalActionType from './archiveListingModalActionType';

export default class ArchiveListingModalModel implements ModalModel<ArchiveListingModalActionType> {

    private constructor(
        public readonly listingId: string,
        public readonly title: TranslationModel,
        public readonly icon: string,
        public readonly isOpen: boolean,
        public readonly buttons: ReadonlyArray<ModalButtonModel<ArchiveListingModalActionType>>
    ) {}

    public static createEmpty(): ArchiveListingModalModel {
        return new ArchiveListingModalModel(
            '',
            new TranslationModel('listings.archive.modal.title', { address: '' }),
            'ri-error-warning-line',
            false,
            [
                new ModalButtonModel('actions.cancel', false, ArchiveListingModalActionType.Cancel),
                new ModalButtonModel('actions.archive', true, ArchiveListingModalActionType.Archive)
            ]
        )
    }

    public withListingId(val: string): ArchiveListingModalModel {
        return new ArchiveListingModalModel(val, this.title, this.icon, this.isOpen, this.buttons)
    }

    public withTitle(val: TranslationModel): ArchiveListingModalModel {
        return new ArchiveListingModalModel(this.listingId, val, this.icon, this.isOpen, this.buttons)
    }

    public withIsOpen(val: boolean): ArchiveListingModalModel {
        return new ArchiveListingModalModel(this.listingId, this.title, this.icon, val, this.buttons)
    }
}
