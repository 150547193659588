import { useTranslation } from 'react-i18next';

export const availableLanguages = ['nl', 'en'];

const currentLanguage = (): string => {
    const { i18n } = useTranslation();

    let language = i18n.language || 'nl';

    if (!availableLanguages.includes(language)) {
        language = 'en';
    }

    return language;
}

export default currentLanguage;
