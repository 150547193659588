import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import enGB from './locales/en/translation.json';
import nlNL from './locales/nl/translation.json';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'nl',
        fallbackLng: 'nl',
        debug: false,
        resources: {
            nl: {
                translation: nlNL
            },
            en: {
                translation: enGB
            }
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    });


export default i18n;
