import MessageModel from '../models/candidate/messageModel'
import moment from 'moment/moment'

interface MessageJSON {
    readonly details: { body: string, subject: string }
    readonly createdAt: string
}

export default class MessageJsonToModelConverter {
    public static convert(obj: MessageJSON): MessageModel {
        return new MessageModel(
            obj.details.subject,
            obj.details.body,
            moment(obj.createdAt)
        )
    }
}
