enum FontWeightEnum {
    Light = 'light', // 400
    Regular = 'regular', // 500
    Semibold = 'semibold', // 600
    Bold = 'bold', // 700
}

export function fontWeightEnumAsCssVar(fontWeightEnum: FontWeightEnum): string {
    return `var(--font-weight-${fontWeightEnum})`;
}

export default FontWeightEnum
