import CandidatePlace from '../../enums/candidatePlace';
import CandidateStatus from '../../enums/candidateStatus';
import CandidateViewingStatus from '../../enums/candidateViewingStatus';
import CandidatePlaceResolver from './candidatePlaceResolver';
import CandidateScreeningRequestStatusEnum from '../../enums/candidateScreeningRequestStatusEnum';
import CandidateScreeningRequestOutcomeEnum from '../../enums/candidateScreeningRequestOutcomeEnum'
import MessageModel from './messageModel'
import MessageJsonToModelConverter from '../../converters/messageJsonToModelConverter'
import NoteModel from './noteModel'
import NoteModelToJsonConverter from '../../converters/noteModelToJsonConverter'
import GenderEnum from '../../enums/genderEnum'
import moment from 'moment'
import ViewingModel from './viewingModel'
import ViewingModelToJsonConverter from '../../converters/viewingModelToJsonConverter'

export default class CandidateModel {
    constructor(
        public readonly id: string,
        public readonly firstName: string,
        public readonly lastName: string,
        public readonly email: string,
        public readonly phone: string,
        public readonly locale: string,
        public readonly guarantor: string,
        public readonly employment: string,
        public readonly gender: GenderEnum,
        public readonly income: number,
        public readonly household: string,
        public readonly householdType: string,
        public readonly pets: boolean | null,
        public readonly term: number,
        public readonly availableSince: string, // date later!
        public readonly extraInfoFormLastSentOn: string | null, // date later
        public readonly birthday: string, // date later
        public readonly messages: ReadonlyArray<MessageModel> | null,
        public readonly notes: ReadonlyArray<NoteModel> | null,
        public readonly viewings: ReadonlyArray<ViewingModel> | null,
        public readonly status: CandidateStatus,
        public readonly listing: string,
        public readonly createdAt: string, // date later
        public readonly updatedAt: string, // date later
        public readonly fullName: string,
        public readonly hasAcceptedAgentPrivacyPolicy: boolean,
        public readonly screeningRequestId: string | null,
        public readonly screeningRequestStatus: CandidateScreeningRequestStatusEnum | null,
        public readonly screeningRequestRating: number | null,
        public readonly screeningRequestOutcome: CandidateScreeningRequestOutcomeEnum | null,
        public readonly isListingTenant: boolean | false,
        public readonly hasCoTenants: boolean = false,
        public readonly hasCoTenantsWithInvitedScreeningRequests: boolean = false,
        public readonly upcomingOrMostRecentViewing: ViewingModel | null,
        private readonly placeResolver = new CandidatePlaceResolver(),
    ) { }

    public static fromJson(val): CandidateModel {
        const messages = val.messages?.map(m => MessageJsonToModelConverter.convert(m))
        const notes = val.notes?.map(n => NoteModelToJsonConverter.convert(n))
        const viewings = val.viewings?.map(n => ViewingModelToJsonConverter.convert(n))
        const upcomingOrMostRecentViewing = val.upcomingOrMostRecentViewing ? ViewingModelToJsonConverter.convert(val.upcomingOrMostRecentViewing) : null

        return new CandidateModel(
            val.id,
            val.firstName,
            val.lastName,
            val.email,
            val.phone,
            val.locale,
            val.guarantor,
            val.employment,
            val.gender,
            val.income,
            val.household,
            val.householdType,
            val.pets,
            val.term,
            val.availableSince,
            val.extraInfoFormLastSentOn,
            val.birthday,
            messages,
            notes,
            viewings,
            val.status,
            val.listing,
            val.createdAt,
            val.updatedAt,
            val.fullName,
            val.hasAcceptedAgentPrivacyPolicy,
            val.screeningRequestId,
            val.screeningRequestStatus,
            val.screeningRequestRating,
            val.screeningRequestOutcome,
            val.isListingTenant,
            val.hasCoTenants,
            val.hasCoTenantsWithInvitedScreeningRequests,
            upcomingOrMostRecentViewing,
            val.placeResolver
        )
    }

    public withStatus(val: CandidateStatus): CandidateModel {
        console.log('string:', val)
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email, this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, val, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withUpcomingOrMostRecentViewing(val: ViewingModel): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            val, this.placeResolver
        )
    }

    public withIncome(val: number): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            val, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withGuarantor(val: string): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, val, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withExtraInfoFormLastSentOn(val: string | null): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, val,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withAcceptedAgentPrivacyPolicy(val: boolean): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, val,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withScreeningRequestStatus(val: CandidateScreeningRequestStatusEnum): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, val, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withScreeningRequestOutcome(val: CandidateScreeningRequestOutcomeEnum): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, val, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withGender(val: GenderEnum): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, val,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            this.birthday, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public withBirthday(val: string): CandidateModel {
        return new CandidateModel(this.id, this.firstName, this.lastName, this.email,
            this.phone, this.locale, this.guarantor, this.employment, this.gender,
            this.income, this.household, this.householdType, this.pets, this.term, this.availableSince, this.extraInfoFormLastSentOn,
            val, this.messages, this.notes, this.viewings, this.status, this.listing, this.createdAt, this.updatedAt, this.fullName, this.hasAcceptedAgentPrivacyPolicy,
            this.screeningRequestId, this.screeningRequestStatus, this.screeningRequestRating, this.screeningRequestOutcome, this.isListingTenant, this.hasCoTenants, this.hasCoTenantsWithInvitedScreeningRequests,
            this.upcomingOrMostRecentViewing, this.placeResolver
        )
    }

    public getPlace(): CandidatePlace {
        return this.placeResolver.resolve(this)
    }

    public isInvitedToViewingPending(): boolean {
        return this.upcomingOrMostRecentViewing?.status === CandidateViewingStatus.Pending
    }

    public hasAcceptedInvitationToViewing(): boolean {
        return this.upcomingOrMostRecentViewing?.status === CandidateViewingStatus.Accepted
    }

    public hasDeclinedInvitationToViewing(): boolean {
        return this.upcomingOrMostRecentViewing?.status === CandidateViewingStatus.Declined
    }

    public hasReceivedExtraInfoForm(): boolean {
        return this.extraInfoFormLastSentOn !== null
    }

    public hasFilledInExtraInfoForm(): boolean {
        return this.hasAcceptedAgentPrivacyPolicy === true
    }

    public isInvitedForScreening(): boolean {
        return this.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Invited
    }

    public hasScreeningOutcome(): boolean {
        return this.screeningRequestOutcome !== null
    }

    public hasDoneScreening(): boolean {
        return (
            this.screeningRequestOutcome !== null
            && this.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Completed
        )
            || this.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Expired
            || this.screeningRequestStatus === CandidateScreeningRequestStatusEnum.Failed
    }

    public getSalutation(): string {
        if (this.gender === GenderEnum.Male) {
            return 'candidate.salutation.male'
        } else if (this.gender === GenderEnum.Female) {
            return 'candidate.salutation.female'
        } else {
            return ''
        }
    }

    public formatDateAndAge(): string {
        if (!this.birthday) {
            return '-'
        }

        const birthDate = moment(this.birthday, 'YYYY-MM-DD')
        const formattedDate = birthDate.format('DD-MM-YYYY')

        const today = moment()
        const age = today.diff(birthDate, 'years')

        return `${formattedDate} (${age})`
    }
}
