import React from 'react'


interface SearchBarProps {
    readonly onChange: (val: string) => void;
    readonly value: string;
}

export default function SearchBar(props: SearchBarProps) {
    return (
        <div className="relative text-gray-900 focus-within:text-gray-500 w-[244px]">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center ">
                <i className="ri-search-line text-lg"></i>
            </div>
            <input
                type="search"
                placeholder="Zoeken"
                className="w-full rounded-md pl-10 border border-gray-300
                focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300 placeholder-gray-400"
                value={props.value}
                onChange={(e) => props.onChange(e.target.value)}
            />
        </div>
    );
}
