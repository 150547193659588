import React from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
// import EmailPreviewTabs from '../tabs/EmailPreviewTabs';
import PropTypes from 'prop-types';
import styles from './ExtraInformationModal.module.css'
import {useTranslation} from 'react-i18next'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

function ExtraInformationModal(props) {
    const {
        extraInfoModalOpen,
        onCloseButtonClick,
        selectedPeople,
        setSelectedPeople,
        handleRequestExtraInfo,
    } = props;

    const {t} = useTranslation()

    const closeExtraInformationModal = () => {
        if (selectedPeople[0]?.id) {
            onCloseButtonClick();
        } else {
            if (selectedPeople.length === 1) {
                setSelectedPeople([]);
            } else {
                setSelectedPeople(selectedPeople);
            }
        }
        onCloseButtonClick(false);
    };

    return (
        <Transition appear show={extraInfoModalOpen === true}>
            <Dialog as="div" className="relative z-50" onClose={() => onCloseButtonClick(false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="flex min-h-full items-center justify-center p-4 text-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className={`w-full md:w-[600px]
                                transform overflow-hidden
                                rounded-2xl bg-white p-6 text-left align-middle
                                shadow-xl transition-all ${styles.flowyContainer}`}>
                                <div className={styles.mainContainer}>
                                    <div className={styles.iconContainer}>
                                        <div className={styles.icon}>
                                            <i className="ri-survey-line"></i>
                                        </div>
                                    </div>
                                    <div className={styles.contentContainer}>
                                        <Dialog.Title className={classNames('font-bold text-1xl py-3 text-gray-900')}>
                                            {`${t('candidate.extra.information.modal.title')}`}
                                            <div className={styles.closeButton}>
                                                <button
                                                    onClick={() => props.onCloseButtonClick()}
                                                    type="button">
                                                    <i className="ri-close-line ri-lg"></i>
                                                </button>
                                            </div>

                                        </Dialog.Title>
                                        <Dialog.Description className={classNames('text-md py-2 text-gray-800')}>
                                            {`${selectedPeople?.length === 1
                                                ? `${selectedPeople[0].firstName} `
                                                : `${selectedPeople.length} kandidaten `}
                                            ${t('candidate.extra.information.modal.description')}`}
                                        </Dialog.Description>

                                        <p className="py-5 text-gray-800">
                                            {t('candidate.extra.information.modal.explanation')}
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <button
                                        onClick={() => (
                                            closeExtraInformationModal()
                                        )}
                                        className="inline-flex items-center px-4 py-3 border
                                    border-gray-300 shadow-sm text-md leading-4 font-medium
                                    rounded-md text-gray-700 bg-white hover:bg-gray-50
                                    ">
                                        {t('candidate.extra.information.modal.button.cancel')}
                                    </button>
                                    <button
                                        className="inline-flex items-center px-4 py-3
                                    border border-transparent text-md leading-4
                                    font-medium rounded-md text-white bg-primary
                                    hover:bg-primary-dark mx-3"
                                        onClick={() => (handleRequestExtraInfo(), onCloseButtonClick(false))}>
                                        {t('candidate.extra.information.modal.button.send')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition >
    );
}

ExtraInformationModal.propTypes = {
    extraInfoModalOpen: PropTypes.bool.isRequired,
    onCloseButtonClick: PropTypes.func.isRequired,
    selectedPeople: PropTypes.array.isRequired,
    setSelectedPeople: PropTypes.func,
    handleRequestExtraInfo: PropTypes.func,
};

export default ExtraInformationModal;
