import styles from './Title.module.css';
import {ReactNode} from 'react';
import ColorEnum from '../../../enums/colorEnum';
import FontWeightEnum from '../enums/fontWeightEnum';
import FontSizeEnum from '../enums/fontSizeEnum';

export interface TitleProps {
    readonly children: ReactNode
    readonly size: FontSizeEnum
    readonly weight: FontWeightEnum
    readonly color: ColorEnum
}

export default function Title(props: TitleProps) {

    function getSizeCSSClassName(): string {
        return `${styles[props.size]}`
    }

    function getColorCssVar(): string {
        return `var(--${props.color})`
    }

    function getWeightCssVar(): string {
        return `var(--${props.weight})`
    }

    return (
        <h1
            className={getSizeCSSClassName()}
            style={{color: getColorCssVar(), fontWeight: getWeightCssVar()}}
        >
            {props.children}
        </h1>
    )
}
