import styles from './Modal.module.css'
import ColorEnum, {colorEnumAsCssVar} from '../../../enums/colorEnum'
import FlowyFigure from '../../generics/modalBackgroundIcon/FlowyFigure'
import {useEffect, useRef, useState} from 'react'
import CloseButton from '../../atoms/closeButton/CloseButton'
import PropTypes from 'prop-types';

export interface ModalProps {
    readonly icon?: string
    readonly iconColor?: ColorEnum
    readonly iconBackgroundColor?: ColorEnum
    readonly flowyColor: ColorEnum
    readonly children: React.ReactNode
    readonly isOpen: boolean
    readonly isNonScrollable?: boolean
    readonly onCloseButtonClick?: () => void
    readonly canOverflow?: boolean
}

export default function Modal(props: ModalProps) {
    const [isVisible, setIsVisible] = useState(props.isOpen);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (props.isOpen) {
            setIsVisible(true);
            setIsClosing(false);
        } else if (isVisible) {
            setIsClosing(true);
            setTimeout(() => {
                setIsClosing(false);
                setIsVisible(false);
            }, 200);
        }
    }, [props.isOpen]);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    function handleClose() {
        if (!isClosing) {
            props.onCloseButtonClick?.();
        }
    }

    if (!isVisible) {
        return null;
    }

    return (
        <div
            className={classNames(
                styles.backdrop,
                isClosing ? styles.backdropHidden : styles.backdropVisible,
                props.isNonScrollable === true ? styles.nonScrollable : ''
            )}
        >
            <div className="fixed inset-0 w-full z-50">
                <div className={`${styles.mainContainer} ${props.canOverflow ? styles.canOverflow : ''}`}>
                    <div
                        className={classNames(
                            styles.innerContainer,
                            isClosing ? styles.hidden : styles.visible
                        )}
                    >
                        <div className={styles.closeButton}>
                            <CloseButton onClick={handleClose}/>
                        </div>
                        <div className={styles.flowyFigureContainer}>
                            <FlowyFigure color={props.flowyColor}/>
                        </div>
                        <div className={styles.contentContainer}>
                            {props.icon ? (
                                <div className={styles.iconContainer}>
                                    <i
                                        className={`${styles.icon} ${props.icon} text-xl`}
                                        style={{
                                            color: colorEnumAsCssVar(props.iconColor),
                                            backgroundColor: colorEnumAsCssVar(props.iconBackgroundColor),
                                        }}
                                    ></i>
                                </div>
                            ) : null}
                            <div
                                className={classNames(
                                    styles.childrenContainer,
                                    props.isNonScrollable === true ? styles.nonScrollable : ''
                                )}
                            >
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
