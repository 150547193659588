import React from 'react';
import styles from './Icon.module.css';
import { classNames } from '../../utils';

export interface IconProps {
    className?: string;
}

const ArrowDownSLineIcon = ({ className }: IconProps) => {
    return (
        <div className={classNames(styles.icon, className ?? className)}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
            >
                <path d="M11.9999 13.1714L16.9497 8.22168L18.3639 9.63589L11.9999 15.9999L5.63599 9.63589L7.0502 8.22168L11.9999 13.1714Z"></path>
            </svg>
        </div>
    );
};

export default ArrowDownSLineIcon;
