import isNumber from './isNumber';

const formatSurface = (surface?: number): string => {
    if (!isNumber(surface)) {
        return '-';
    }

    return `${surface} m²`
}

export default formatSurface;
