
import { request } from '../../../utils/api';
import { createAgentLogin } from './createAgentLogin';
import { deleteAgentLogin } from './deleteAgentLogin';
import { API_URIS } from '../../../utils/constants';

export const updateAgentWithLogins = (token, agentId, agentData, loginsToCreate, loginsToDelete) => (dispatch) => {
    request(token).put(`${API_URIS.AGENTS_URI}/${agentId}`, agentData)
        .then((response) => {
            dispatch({
                type: 'SET_AGENT_DETAILS',
                payload: response.data,
            });
            return response;
        })
        .then(res => {
            const agentId = res.data.id;
            loginsToDelete.forEach(login => {
                dispatch(deleteAgentLogin(token, login));
            });
            loginsToCreate.forEach(login => {
                dispatch(createAgentLogin(token, login, agentId));
            });
        })
        .catch((e) => console.log(e));
};
