import React, { ReactNode, MouseEvent } from 'react';
import styles from './InputField.module.css';
import { classNames } from '../../utils';

interface InputFieldProps {
    disabled?: boolean;
    readonly placeholder?: string;
    readonly iconBefore?: ReactNode;
    readonly iconAfter?: ReactNode;
    readonly onClick?: (e: MouseEvent) => void
}

const InputField = ({
    placeholder,
    disabled = false,
    iconBefore,
    iconAfter,
    onClick
}: InputFieldProps) => {
    const handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    const handleInputClick = (e: MouseEvent) => {
        e.stopPropagation();
        onClick?.(e);
    }

    return (
        <>
            <div
                onClick={handleClick}
                className={classNames(styles.InputField, iconBefore && styles.hasIconBefore, iconAfter && styles.hasIconAfter)}
            >
                {iconBefore &&
                    <span className={classNames(styles.iconBefore)}>
                        {iconBefore}
                    </span>
                }
                <input
                    placeholder={placeholder}
                    disabled={disabled}
                    onClick={handleInputClick}
                />
                {iconAfter &&
                    <span className={classNames(styles.iconAfter)}>
                        {iconAfter}
                    </span>
                }
            </div>
        </>
    );
};

export default InputField;
