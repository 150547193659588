import {request} from '../../../utils/api';

export const toggleAutomaticEnriching = (token, id, enrichingMode) => async (dispatch) => {
    dispatch({type: 'START_LOADING'});

    const url = enrichingMode === 'instant' ?
        '/api/transactional_listings/set_enrichment_mode_to_automatic' :
        '/api/transactional_listings/set_enrichment_mode_to_manual';

    request(token).post(url, {
        listingId: id
    })
        .then((response) => {
            if (response.status === 202) {
                dispatch({type: 'SET_AUTO_ENRICHING_MODE', payload: enrichingMode});
            }
        })
        .catch((e) => {
            dispatch({type: 'STOP_LOADING'});
            console.error(e);
        })
        .finally(() => dispatch({type: 'STOP_LOADING'}));
};
