import FormattedDateLabelModel from './logic/models/formattedDateLabelModel'
import styles from './FormattedDateLabel.module.css'

export interface DateContentProps {
    readonly model: FormattedDateLabelModel
}

export default function FormattedDateLabel(props: DateContentProps) {
    return (
        <div className={`truncate ${styles.root}`}>
            {props.model.getTimeFromNow()}
        </div>
    )
}
