import ActionType from '../enums/actionType'

export function useActionTypeToIcon(): (actionType: ActionType) => string {
    return (actionType: ActionType): string => {
        switch (actionType) {
            case ActionType.Approve: return 'ri-thumb-up-line'
            case ActionType.Invite: return 'ri-calendar-line'
            case ActionType.MoveToWaitingList: return 'ri-pause-circle-line'
            case ActionType.Reject: return 'ri-thumb-down-line'
            case ActionType.RejectWithoutMessage: return 'ri-mail-close-line'
            case ActionType.SendMessage: return 'ri-mail-line'
            case ActionType.RequestExtraInfo: return 'ri-survey-line'
            case ActionType.Screen: return 'ri-contacts-line'
            case ActionType.AssignTenant: return 'ri-user-star-line'
            default: return ''
        }
    }
}
