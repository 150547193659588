import AgencyDetailsModel from '../../models/agent/agencyDetailsModel'

export interface AgencyDetailsModelJson {
    readonly id: string
    readonly name: string
    readonly defaultEmail: string
    readonly houseNumber: string
    readonly street: string
    readonly city: string
    readonly postalCode: string
}

export default class AgencyDetailsModelToJsonConverter {
    public static convert(obj: AgencyDetailsModelJson): AgencyDetailsModel {
        return new AgencyDetailsModel(
            obj.id,
            obj.name,
            obj.defaultEmail,
            obj.houseNumber,
            obj.street,
            obj.city,
            obj.postalCode
        )
    }
}
