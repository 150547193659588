import NoteModel from '../models/candidate/noteModel'
import moment from 'moment'

interface NoteJSON {
    readonly id: string,
    readonly note: string,
    readonly createdAt: string
}

export default class NoteModelToJsonConverter {
    public static convert(obj: NoteJSON) {
        return new NoteModel(obj.id, obj.note, moment(obj.createdAt))
    }
}
