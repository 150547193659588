export default class SavableInputModel {
    constructor(
        public readonly defaultValue: string,
        public readonly value: string,
        public readonly isLoading: boolean = false,
        public readonly isSaving: boolean = false
    ) {}

    public startLoading(): SavableInputModel {
        return new SavableInputModel(this.defaultValue, this.value, true, this.isSaving)
    }

    public finishLoadingSuccess(): SavableInputModel {
        return new SavableInputModel(this.value, this.value, false, this.isSaving)
    }

    public finishLoadingFailed(): SavableInputModel {
        return new SavableInputModel(this.defaultValue, this.defaultValue, false, this.isSaving)
    }

    public withValue(value: string): SavableInputModel {
        return new SavableInputModel(this.defaultValue, value, this.isLoading, this.isSaving)
    }

    public withSaving(value: boolean): SavableInputModel {
        return new SavableInputModel(this.defaultValue, this.value, this.isLoading, value)
    }
}
