import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useFeature } from '@growthbook/growthbook-react'
import CandidateStatus from '../../enums/candidateStatus'
import styles from './RejectionConfirmationModal.module.css'
import Checkbox from '../forms/checkbox/Checkbox'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function RejectionConfirmationModal(props) {
    const { isRejectionModalOpen, toggleRejectionModal, handleBulkStatusChange, status, isRejectingAll, selectedPeopleAmount } = props

    const { t } = useTranslation()
    const FEATURE_FLAG_REJECT_WITHOUT_MESSAGE = useFeature('reject_without_message').value

    const { numberOfLeadsToAssess,
        numberOfSuitableLeads,
        numberOfRejectedLeads,
        numberOfNotSuitableLeads,
        numberOfUnassessedLeads } = useSelector((state: any) => state.listings.selectedListing)

    const returnAmountOfCandidatesWithSpecificStatus = (candidateStatus) => {
        switch (candidateStatus) {
            case CandidateStatus.Suitable: return numberOfSuitableLeads
            case CandidateStatus.NotSuitable: return numberOfNotSuitableLeads
            case CandidateStatus.Assessment: return numberOfLeadsToAssess
            case CandidateStatus.Rejected: return numberOfRejectedLeads
            case CandidateStatus.None: return numberOfUnassessedLeads
        }
    }

    const [isWithoutMessageChecked, setIsWithoutMessageChecked] = useState(false)

    useEffect(() => {
        setIsWithoutMessageChecked(false)
    }, [isRejectionModalOpen])

    return (

        <Transition appear show={isRejectionModalOpen}>
            <Dialog as="div" className="relative z-50 w-[600]" onClose={() => toggleRejectionModal(false, false)}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto w-full">
                    <div className="flex min-h-full items-center justify-center text-center w-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95">
                            <Dialog.Panel
                                className={`w-full md:w-[705px]
                                transform overflow-hidden
                                rounded-2xl bg-white text-left align-middle
                                shadow-xl transition-all
                                ${styles.flowyContainer}`}>

                                <div className={styles.mainContainer}>
                                    <div className={styles.iconContainer}>
                                        <div className={styles.icon}>
                                            <i className="ri-thumb-down-line text-3xl"></i>
                                        </div>
                                    </div>
                                    <div className={styles.contentContainer}>
                                        <Dialog.Title className={styles.title}>
                                            {t('rejectConfirmation.main', { amountOfCandidates: (!isRejectingAll ? selectedPeopleAmount : returnAmountOfCandidatesWithSpecificStatus(status)) })}
                                        </Dialog.Title>

                                        <p className={styles.description}>
                                            {t(FEATURE_FLAG_REJECT_WITHOUT_MESSAGE ? 'rejectConfirmation.info' : 'rejectConfirmation.info_LEGACY')}
                                        </p>
                                        {FEATURE_FLAG_REJECT_WITHOUT_MESSAGE && <div className='grid grid-cols-[30px_auto] items-center'>
                                            <Checkbox
                                                isChecked={isWithoutMessageChecked}
                                                onChange={(ev) => setIsWithoutMessageChecked(ev.target.checked)}
                                            />
                                            <label><p>{t('rejectConfirmation.withoutMessage')}</p></label>
                                            <div></div>
                                            <p className={styles.rejectWithoutMessageInfo}>
                                                {t('rejectConfirmation.withoutMessage.info')}
                                            </p>
                                        </div>}
                                    </div>
                                </div>
                                <div className='flex flex-row items-center justify-end py-4 px-2'>
                                    <button
                                        onClick={() => (
                                            toggleRejectionModal(false, false)
                                        )}
                                        className="inline-flex items-center px-4 py-3 border

                                border-gray-300 shadow-sm text-md leading-4 font-medium
                                rounded-md text-gray-700 bg-white hover:bg-gray-50 cursor-pointer
                                ">
                                        Annuleren
                                    </button>
                                    <button
                                        className="inline-flex items-center px-4 py-3
                                border border-transparent text-md leading-4
                                font-medium rounded-md text-white bg-red-500
                                hover:bg-primary-dark mx-3 cursor-pointer"
                                        onClick={() => {
                                            handleBulkStatusChange('reject', FEATURE_FLAG_REJECT_WITHOUT_MESSAGE ? !isWithoutMessageChecked : true)
                                            toggleRejectionModal(false, false)
                                        }}>
                                        Afwijzen
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition >
    )
}

RejectionConfirmationModal.propTypes = {
    selectedPeopleAmount: PropTypes.number.isRequired,
    toggleRejectionModal: PropTypes.func.isRequired,
    isRejectionModalOpen: PropTypes.bool.isRequired,
    handleBulkStatusChange: PropTypes.func.isRequired,
    status: PropTypes.string,
    isRejectingAll: PropTypes.bool.isRequired
}

export default RejectionConfirmationModal
