import WarningModal from '../../../../components/organisms/modal/WarningModal'
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum'
import Text from '../../../../components/typography/text/Text'
import FontWeightEnum from '../../../../components/typography/enums/fontWeightEnum'
import ColorEnum, {colorEnumAsCssVar} from '../../../../enums/colorEnum'
import Button from '../../../../components/buttons/button/Button'
import ButtonType from '../../../../components/buttons/button/logic/models/buttonType'
import style from './ConfirmMergeModal.module.css'
import {Trans, useTranslation} from 'react-i18next'

interface ConfirmMergeModalProps {
    readonly isOpen: boolean
    readonly isShowingErrorMessage: boolean
    readonly primaryAddress: string
    readonly redundantAddress: string
    readonly onConfirm: () => void
    readonly onCancel: () => void
    readonly onCloseButtonClick?: () => void
}

export default function ConfirmMergeModal(props: ConfirmMergeModalProps) {

    const {t} = useTranslation()

    return (
        <WarningModal isOpen={props.isOpen} onCloseButtonClick={() => props.onCloseButtonClick?.()}>
            <div className={style.root}>
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray800}
                >
                    {t('mergeDuplicateTransactionalListingsPage.modal.title')}
                </Text>

                <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Gray800}
                >
                    <Trans
                        i18nKey={'mergeDuplicateTransactionalListingsPage.modal.description'}
                        components={{Bold: <strong/>}}
                    />
                </Text>

                <div className={style.address}>
                    <i
                        style={{
                            fontSize: 24,
                            color: colorEnumAsCssVar(ColorEnum.Success600)
                        }}
                        className={'ri-checkbox-circle-line'}
                    />
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Bold}
                    >
                        {t('mergeDuplicateTransactionalListingsPage.modal.description.listingThatWillStay')}
                    </Text>

                    <div />
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                    >
                        {props.primaryAddress}
                    </Text>

                </div>

                <div className={style.address}>
                    <i
                        style={{
                            fontSize: 24,
                            color: colorEnumAsCssVar(ColorEnum.Error600)
                        }}
                        className={'ri-close-circle-line'}
                    />
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                        weight={FontWeightEnum.Bold}
                    >
                        {t('mergeDuplicateTransactionalListingsPage.modal.description.listingThatWillBeRemoved')}
                    </Text>

                    <div />
                    <Text
                        size={FontSizeEnum.Md}
                        color={ColorEnum.Gray800}
                    >
                        {props.redundantAddress}
                    </Text>
                </div>

                <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Gray800}
                >
                    {t('mergeDuplicateTransactionalListingsPage.modal.description.areYouSure')}
                </Text>

                {props.isShowingErrorMessage && <Text
                    size={FontSizeEnum.Md}
                    color={ColorEnum.Error600}
                >
                    {t('mergeDuplicateTransactionalListingsPage.modal.error')}
                </Text>}

                <div className={style.buttonContainer}>
                    <Button
                        title={t('mergeDuplicateTransactionalListingsPage.modal.button.cancel')}
                        onClick={() => props.onCancel()}
                    />
                    <Button
                        buttonType={ButtonType.Primary}
                        title={t('mergeDuplicateTransactionalListingsPage.modal.button.merge')}
                        onClick={() => props.onConfirm()}
                    />
                </div>
            </div>
        </WarningModal>
    )
}
