import Modal from '../organisms/modal/Modal'
import ColorEnum from '../../enums/colorEnum'
import HTMLRenderer from '../../componentsNew/atoms/htmlRenderer/HTMLRenderer'

interface DiscardedLeadsModalProps {
    readonly isOpen: boolean
    readonly onCloseButtonClick: () => void,
    readonly content: string,
}

export default function DiscardedLeadsModal(props: DiscardedLeadsModalProps) {
    return (
        <>
            <Modal
                iconBackgroundColor={ColorEnum.Primary100}
                flowyColor={ColorEnum.White}
                isOpen={props.isOpen}
                onCloseButtonClick={() => props.onCloseButtonClick()}
            >
                <HTMLRenderer
                    html={props.content}
                />
            </Modal>
        </>
    )
}
