import BadgeType from '../enums/BadgeType'

export default function useStylingForBadgeType(type: BadgeType) {
    switch(type) {
        case BadgeType.Gray: return 'bg-gray-200 text-gray-700'
        case BadgeType.Primary: return 'bg-primary-50 text-primary-700'
        case BadgeType.Error: return 'bg-error-50 text-error-700'
        case BadgeType.Warning: return 'bg-warning-50 text-warning-900'
        case BadgeType.Success: return 'bg-success-50 text-success-700'
    }
}
