import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './Select.module.css';
import { Dropdown } from '../dropdown';
import { InputField } from '../input-field';
import ButtonNew from '../../molecules/button/Button';
import { classNames } from '../../utils';
import ArrowDownSLineIcon from '../../atoms/icons/ArrowDownSLineIcon';
import CheckLineIcon from '../../atoms/icons/CheckLineIcon';

export interface ListItem {
    order?: number;
    value: string | null;
    label: ReactNode | string;
    iconBefore?: ReactNode;
    iconAfter?: ReactNode;
}

interface SelectProps {
    list: ListItem[];
    selected?: string | null;
    hasInputField?: boolean;
    onChange: (selected: ListItem) => void;
}

export const findSelectedListItemByValue = (list: ListItem[], val: string | null): ListItem | null => {
    const item = list.find((item) => item.value == val);

    if (item !== undefined) {
        return item;
    }

    return null;
}

const Select = ({
    list,
    selected = null,
    hasInputField = false,
    onChange
}: SelectProps) => {
    const selectDivRef = useRef<HTMLDivElement>(null);

    const [isDropdownActive, setIsDropdownActive] = useState(false);

    const [selectedListItem, setSelectedListItem] = useState(findSelectedListItemByValue(list, selected));

    useEffect(() => {
        document.addEventListener('pointerdown', handleOutsideClick);

        return () => {
            document.removeEventListener('pointerdown', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        onChange(selectedListItem)
    }, [selectedListItem])

    const handleInputFieldClick = () => {
        setIsDropdownActive(!isDropdownActive);
    }

    const handleOutsideClick = (event: PointerEvent) => {
        if (selectDivRef.current && !selectDivRef.current.contains(event.target as Node)) {
            setIsDropdownActive(false);
        }
    };

    const handleListItemClick = (item: ListItem) => {
        setSelectedListItem(item);
        setIsDropdownActive(false);
    }

    const renderList = (): ReactNode[] => {
        return list.map((listItem, index) => {
            return (
                <li
                    key={index}
                    className={classNames(styles.listItem, selectedListItem.value === listItem.value && styles.selected)}
                    onClick={() => handleListItemClick(listItem)}
                >
                    {listItem.label}
                    <CheckLineIcon className={styles.checkLineIcon}/>
                </li>
            );
        })
    }

    return (
        <div
            ref={selectDivRef}
            className={styles.Select}
        >
            {hasInputField ?
                <InputField/>
                :
                <ButtonNew
                    iconAfter={<ArrowDownSLineIcon/>}
                    variant="secondary-gray"
                    onClick={handleInputFieldClick}
                >
                    {selectedListItem?.label}
                </ButtonNew>
            }
            <Dropdown
                isActive={isDropdownActive}
            >
                <ul className={styles.list}>
                    {renderList()}
                </ul>
            </Dropdown>
        </div>
    );
};

export default Select;
