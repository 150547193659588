import PropTypes from 'prop-types';
import { Menu } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import CandidateViewingStatus from '../../enums/candidateViewingStatus';
import CandidateStatus from '../../enums/candidateStatus';
import ButtonGroupButton from './_buttonGroupButton';
import ButtonGroup from './_buttonGroup';

/**
 * @deprecated Part of old ButtonGroup. When working with ButtonGroup use components/buttonGroup/ButtonGroup instead of _archivedComponents/_buttonGroup!
 */
function CandidateActionsButtonGroup({
    toggleInviteToViewingModal,
    toggleMessageModal,
    candidate,
    setSelectedPeople,
    updateCandidateStatus,
    isDisabled,
    dropsUp = false
}) {

    const {t} = useTranslation();

    const candidateId = candidate.id;
    const currentCandidateStatus = candidate.status;

    const dropdownMenuItems = () => (
        <>
            <Menu.Item
                as='div'
                disabled={isDisabled}
                onClick={() => updateCandidateStatus((currentCandidateStatus === CandidateStatus.NotSuitable ? 'reset' : 'devote'), candidateId)}
            >
                <button className='appearance-none inline-flex text-gray-700 block px-4 py-2 text-sm'>
                    <i className="ri-pause-circle-line mr-2"></i>
                    <span>{ t('candidate.action.queue') }</span>
                </button>
            </Menu.Item>

            {candidate.viewingInvitationStatus === CandidateViewingStatus.NotInvited &&
                <Menu.Item
                    as='div'
                    disabled={isDisabled}
                    onClick={() => sendMessageToCandidate(candidate, setSelectedPeople, toggleMessageModal)}
                >
                    <button className='appearance-none px-4 inline-flex text-gray-700 block px-4 py-2 text-sm'>
                        <i className="ri-mail-line mr-2"></i>
                        <span>{ t('candidate.action.sendMessage') }</span>
                    </button>
                </Menu.Item>
            }

            <Menu.Item
                as='div'
                disabled={isDisabled}
                onClick={() => updateCandidateStatus((currentCandidateStatus === CandidateStatus.Rejected ? 'reset' : 'reject'), candidateId)}
            >
                <button className='appearance-none inline-flex text-gray-700 block px-4 py-2 text-sm'>
                    <i className="ri-mail-close-line mr-2 text-red-500"></i>
                    <span>{ t('candidate.action.reject') }</span>
                </button>
            </Menu.Item>
        </>
    );

    return (
        <ButtonGroup
            dropDownItems={dropdownMenuItems()}
            isDisabled={isDisabled}
            dropsUp={dropsUp}
        >
            {candidate.viewingInvitationStatus === CandidateViewingStatus.NotInvited ?
                <ButtonGroupButton
                    onClick={() => {
                        setSelectedPeople([candidate]);
                        toggleInviteToViewingModal(true);
                    }}
                    isDisabled={isDisabled}
                >
                    <i className="ri-calendar-line h-5 w-5 mr-1"></i><span>{ t('candidate.action.invite') }</span>
                </ButtonGroupButton> :
                <ButtonGroupButton
                    onClick={() => sendMessageToCandidate(candidate, setSelectedPeople, toggleMessageModal)}
                    isDisabled={isDisabled}
                >
                    <i className="ri-mail-line h-5 w-5 mr-1"></i>
                    <span>{ t('candidate.action.sendMessage') }</span>
                </ButtonGroupButton>
            }
        </ButtonGroup>
    );
}

function sendMessageToCandidate(candidate, setSelectedPeople, toggleMessageModal) {
    setSelectedPeople([candidate]);
    toggleMessageModal(true);
}

CandidateActionsButtonGroup.propTypes = {
    toggleMessageModal: PropTypes.func.isRequired,
    candidate: PropTypes.object.isRequired,
    setSelectedPeople: PropTypes.func.isRequired,
    toggleInviteToViewingModal: PropTypes.func.isRequired,
    updateCandidateStatus: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    dropsUp: PropTypes.bool,
};

export default CandidateActionsButtonGroup;
