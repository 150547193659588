import { request } from '../../../utils/api';
import { API_URIS } from '../../../utils/constants';


export const getAgentDetails = (token, id, params) => (dispatch) => {
    request(token)
        .get(`${API_URIS.AGENTS_URI}/${id}`, { params: params })
        .then((response) => {
            dispatch({
                type: 'SET_AGENT_DETAILS',
                payload: response.data,
            });
            return response;
        }).then((res) => {
            if (res.data.logins.length < 1 ) return;
            res.data.logins.forEach(uri => {
                request(token)
                    .get(uri, { params: params })
                    .then((response) => {
                        dispatch({
                            type: 'ADD_AGENT_LOGIN',
                            payload: response.data,
                        });
                    })
                    .catch((e) => console.log(e));
            });
        })
        .catch((e) => console.log(e));
};
