import Text from '../../typography/text/Text';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import ColorEnum from '../../../enums/colorEnum';
import {Trans, useTranslation} from 'react-i18next';
import TextInput from '../../forms/textInput/TextInput';
import CopyButton from '../../buttons/button/CopyButton';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import ButtonSize from '../../buttons/button/logic/models/buttonSize';
import styles from './CopyContactRequestLinkTab.module.css';
import React from 'react';

interface CopyContractRequestLinkTabProps {
    readonly getURLForContactRequestPage: () => string
}

export default function CopyContactRequestLinkTab(props: CopyContractRequestLinkTabProps) {

    const {t} = useTranslation()

    return (
        <>
            <Text
                size={FontSizeEnum.Md}
                color={ColorEnum.Gray800}
            >
                <Trans i18nKey={'listing.details.generateContactRequestLinkModal.tab.copyLink.description'} components={{
                    p: <p style={{marginBottom: '0.5rem'}}/>,
                    ul: <ul style={{textAlign: 'left', listStyle: 'initial', marginLeft: '1rem'}}/>,
                    li: <li style={{marginBottom: 8}}/>
                }}/>
            </Text>

            <div
                className={styles.copyLinkFieldWrapper}>
                <div className={styles.textInputWrapper}>
                    <TextInput name={'test'} value={props.getURLForContactRequestPage()} isDisabled={true}
                        keepColorOnDisabled={true}></TextInput>
                </div>
                <div>
                    <CopyButton textToCopy={props.getURLForContactRequestPage()}
                        tooltipText={t('listing.details.generateContactRequestLinkModal.tab.copyLink.copyButton.tooltip')}
                        buttonType={ButtonType.SecondaryGray} size={ButtonSize.Lg}
                        icon={'ri-file-copy-line'}></CopyButton>
                </div>
            </div>
        </>
    );

}
