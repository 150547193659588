import React, {Fragment, useEffect, useState} from 'react';
import styles from './Select.module.css';
import Label from '../../componentsNew/atoms/label/Label';
import {Menu, Transition} from '@headlessui/react';
import {ChevronDownIcon} from '@heroicons/react/solid';
import LeadflowDropdownOption from './LeadflowDropdownOption';
import classNames from '../../componentsNew/utils/classNames';

export interface SelectOption {
    label: string;
    value: string;
}

interface Props {
    readonly label: string;
    readonly name: string;
    readonly options: SelectOption[] | [];
    readonly value: string;
    readonly onChange: (value: string) => void;
}

const Select = ({label, name, options, value, onChange}: Props) => {
    const [valueLabel, setValueLabel] = useState('')

    useEffect(() => {
        options?.forEach((option: SelectOption) => {
            if (option.value === value) {
                setValueLabel(option.label)
            }
        })
    }, [value]);

    return (
        <div className={styles.container}>
            <Label htmlFor={name}>{label}</Label>
            <Menu as="span" className="relative z-20 inline-block text-left w-full">
                <div className={styles.input}>
                    <Menu.Button
                        className={
                            classNames(styles.input,
                                'inline-flex justify-between items-center rounded-md border px-4 py-2 whitespace-nowrap',
                                'bg-white text-gray-700 border-gray-300',
                                'focus:outline-none focus:ring-[3px] focus:ring-primary-100 focus:border-primary-300 w-auto',
                            )
                        }
                    >
                        <p className={styles.label}>{valueLabel}</p>
                        <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true"/>
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items
                        className={
                            classNames(
                                'origin-top-left absolute z-10 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none',
                                'max-h-60 overflow-y-auto',
                                'divide-y divide-gray-100'
                            )
                        }
                        style={{maxHeight: '400px'}}
                    >
                        {options.map((option, index) =>
                            <Menu.Item key={index} as="div">
                                <LeadflowDropdownOption
                                    hidden={option.hidden}
                                    label={option.label}
                                    value={option.value}
                                    onChange={onChange}
                                />
                            </Menu.Item>
                        )}
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

export default Select
