enum CandidateScreeningRequestOutcomeEnum {
    FraudDetected = 'FRAUD_DETECTED',
    FakeDocument = 'FAKE_DOCUMENT',
    NegativeEdrScore = 'NEGATIVE_EDR_SCORE',
    EdrFailed = 'EDR_FAILED',
    IdIsDriversLicense = 'ID_IS_DRIVERS_LICENSE',
    ForeignIncome = 'FOREIGN_INCOME',
    AverageFraudRisk = 'AVERAGE_FRAUD_RISK',
    IncomeLessThanTwiceRentalPrice = 'INCOME_LESS_THAN_TWICE_RENTAL_PRICE',
    IncomeTwiceToTripleRentalPrice = 'INCOME_TWICE_TO_TRIPLE_RENTAL_PRICE',
    IncomeHigherThanTripleRentalPrice = 'INCOME_TRIPLE_RENTAL_PRICE'
}

export default CandidateScreeningRequestOutcomeEnum 
