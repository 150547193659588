import BadgesList from '../../badges/BadgesList';
import Button from '../../buttons/button/Button';
import ButtonActionType from '../../buttons/button/logic/models/buttonActionType';
import { Trans, useTranslation } from 'react-i18next';
import ButtonType from '../../buttons/button/logic/models/buttonType';
import Modal from '../../organisms/modal/Modal';
import ColorEnum from '../../../enums/colorEnum';
import FontSizeEnum from '../../typography/enums/fontSizeEnum';
import FontWeightEnum from '../../typography/enums/fontWeightEnum';
import Text from '../../typography/text/Text';
import TextArea from '../../textArea/TextArea';
import CandidateModel from '../../../models/candidate/candidateModel';
import style from './InviteToViewingModal.module.css';
import DateInput from '../../atoms/dateInput/DateInput';
import TimeInput from '../../atoms/timeInput/TimeInput';
import React, { useEffect, useState } from 'react';
import InviteViewingModalModel from './model/inviteViewingModalModel';
import TextInput from '../../forms/textInput/TextInput';
import FormError from '../../banners/FormError';
import Checkbox from '../../forms/checkbox/Checkbox';
import Select from '../../dropdowns/Select';
import { useFeature } from '@growthbook/growthbook-react';
import NumberOfTimeslotsSubText from '../../../componentsNew/atoms/viewing-timeslots/NumberOfTimeslotsSubText';
import { HomeseekerSelector } from '../../../componentsNew/organisms/homeseeker-selector';

interface InviteToViewingModalProps {
    readonly listingId: string;
    readonly isOpen: boolean;
    readonly onCloseButtonClick: () => void;
    readonly selectedPeople: ReadonlyArray<CandidateModel>;
    readonly setSelectedPeople: React.Dispatch<React.SetStateAction<any[]>>;
    readonly street: string;
    readonly houseNumber: string;
    readonly city: string;
    readonly onSubmitButtonClick: (model: InviteViewingModalModel, pickableTimeslotsFeatureOn: boolean) => void
}

export default function InviteToViewingModal(props: InviteToViewingModalProps) {
    const {t} = useTranslation()
    const FEATURE_PICKABLE_TIMESLOTS = useFeature('pickable-timeslots').value

    const [model, setModel] = useState(InviteViewingModalModel.create('', []))

    useEffect(() => {
        setModel(InviteViewingModalModel.create(
            t('candidate.action.invite.viewing', {
                street: props.street,
                houseNumber: props.houseNumber,
                city: props.city
            }),
            [...props.selectedPeople]
        ))
    }, [props.street, props.houseNumber, props.city, props.selectedPeople])

    function handleSubmitButtonClick() {
        props.onSubmitButtonClick(model, FEATURE_PICKABLE_TIMESLOTS)
        props.onCloseButtonClick()
    }

    function showInvitationDateTimeErrors() {
        return <>
            {model.isInvitationStartDateTimeInThePast() &&
                <FormError message={t('candidate.action.invite.error.startDateTimeInPast')}/>}
            {model.isInvitationStartDateTimeTheSameAsEndDateTime() &&
                <FormError message={t('candidate.action.invite.error.startAndEndDateTimeSame')}/>}
            {model.isInvitationStartDateTimeTooCloseToCurrentTime() &&
                <FormError message={t('candidate.action.invite.error.startDateTimeTooCloseToCurrentTime')}/>}
            {model.isInvitationEndDateTimeBeforeStartDateTime() &&
                <FormError message={t('candidate.action.invite.error.endDateTimeBeforeStartDateTime')}/>}
        </>
    }

    return (
        <Modal
            icon={'ri-calendar-line'}
            iconColor={ColorEnum.Primary600}
            iconBackgroundColor={ColorEnum.Primary100}
            flowyColor={ColorEnum.Primary100}
            isOpen={props.isOpen}
            onCloseButtonClick={() => props.onCloseButtonClick()}
        >
            <div className={style.titleContainer}>
                <Text
                    size={FontSizeEnum.Lg}
                    weight={FontWeightEnum.Bold}
                    color={ColorEnum.Gray800}
                >
                    {t('candidate.action.invite.title')}
                </Text>
            </div>
            <div className="flex flex-col gap-4">
                <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500}>
                    {t('candidate.action.invite.label')}
                </Text>
                <HomeseekerSelector
                    transactionalListingId={props.listingId}
                    preselectedHomeseekers={model.getHomeseekerApplicationsForSelector()}
                    onChange={props.setSelectedPeople}
                />
                <div>
                    <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500}>
                        {t('candidate.action.invite.date')}
                    </Text>
                    <div className={style.dateContainer}>
                        <DateInput
                            minimumValue={model.getMinimumSelectableInvitationDate()}
                            value={model.value.invitationDate}
                            onChange={(val) => setModel(prev => prev.updateInvitationDate(val.year(), val.month(), val.date()))}
                        />
                        <TimeInput
                            value={model.getInvitationStartTimeInputModel()}
                            onChange={(val) => setModel(prev => prev.updateInvitationStartDateTimeMaintainingTimeDiff(val))}
                        />
                        <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500}>
                            {t('candidate.action.invite.date.to')}
                        </Text>
                        <TimeInput
                            value={model.getInvitationEndTimeInputModel()}
                            onChange={(val) => setModel(prev => prev.updateInvitationEndDateTime(val))}
                        />
                    </div>
                    {showInvitationDateTimeErrors()}
                </div>
                {FEATURE_PICKABLE_TIMESLOTS && <>
                    <div>
                        <Select
                            label={t('candidate.action.invite.viewing.pickableTimeslotsLabel')}
                            name="agency" options={[null, 10, 15, 20, 30, 60].map((m) => {
                                return { label: m ? t('candidate.action.invite.viewing.minutes', { minutes: m }) : 'Niet opsplitsen', value: m ? `${m}` : '' }
                            })}
                            value={model.value.timeslotDurationInMinutes || ''}
                            onChange={(val) => setModel(prev => prev.updateTimeslotDuration(val))}
                        />
                        {(model.value.timeslotDurationInMinutes && Number.isInteger(model.getViewingDuration())) &&
                            <div className={style.formSubText}>
                                <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>
                                    <NumberOfTimeslotsSubText
                                        number={Math.floor(model.getViewingDuration() / +model.value.timeslotDurationInMinutes)}
                                    />
                                </Text>
                            </div>
                        }
                    </div>
                    <div>
                        <label className={style.maxCandidatesText}>
                            {!model.value.timeslotDurationInMinutes &&
                                t('candidate.action.invite.viewing.maxNumberOfCandidatesForViewing')
                            }
                            {model.value.timeslotDurationInMinutes &&
                                t('candidate.action.invite.viewing.maxNumberOfCandidatesForTimeslots')
                            }
                        </label>
                        <input
                            type="number"
                            className="mt-2 p-3 shadow-sm focus:ring-secondary focus:border-secondary block sm:text-sm border-gray-300 rounded-md disabled:bg-slate-50"
                            min="1"
                            value={model.value.maxNumberOfHomeseekersPerTimeslot}
                            onChange={(e) =>
                                setModel((prev) =>
                                    prev.with({maxNumberOfHomeseekersPerTimeslot: e.target.value})
                                )
                            }
                        />
                    </div>
                </>}
                <div>
                    <label className="text-gray-500 sm:text-md">
                        {t('candidate.action.invite.subject')}
                    </label>
                    <TextInput
                        name="subject"
                        value={model.value.messageSubject}
                        onChange={(e) => setModel(prev => prev.with({messageSubject: e.target.value}))}
                    />
                    {model.isMessageSubjectInvalid() &&
                        <FormError message={t('candidate.action.invite.error.subject')}/>}
                </div>
                <div className={style.checkboxDiv}>
                    <Checkbox
                        id="includeDateAndTimeInEmail"
                        label={
                            <div>
                                {t('candidate.action.invite.date.includeInEmail')}

                                <Text size={FontSizeEnum.Sm} color={ColorEnum.Gray500}>
                                    <Trans
                                        i18nKey="candidate.action.invite.date.includeInEmail.transText"
                                    />
                                </Text>

                                <div className={style.messageBodyWithTime}>
                                    <p>
                                        {t('candidate.action.invite.viewing.messageBodyWithTime', {
                                            date: model.isInvitationDateInvalid() ? model.value.invitationDate.format('dddd D MMMM YYYY') : t('candidate.action.invite.viewing.noDate'),
                                            startTime: model.isInvitationStartDateTimeInvalid() ? model.value.invitationStartDateTime.format('HH:mm') : t('candidate.action.invite.viewing.noTime'),
                                            endTime: model.isInvitationEndDateTimeInvalid() ? model.value.invitationEndDateTime.format('HH:mm') : t('candidate.action.invite.viewing.noTime')
                                        })}
                                    </p>
                                </div>
                            </div>
                        }
                        isChecked={model.value.includeDateAndTimeInEmail}
                        onChange={(e) =>
                            setModel((prev) =>
                                prev.with({includeDateAndTimeInEmail: e.target.checked})
                            )
                        }
                    />
                </div>

                <div data-testid="leadflow-editor">
                    <Text size={FontSizeEnum.Md} color={ColorEnum.Gray500}>
                        {t('candidate.action.invite.body')}
                    </Text>
                    <TextArea
                        value={model.value.messageBody}
                        onChange={(v) => setModel(prev => prev.with({messageBody: v}))}
                        id='inviteToViewingEditor'
                    />
                </div>
            </div>
            <div className="mt-4 flex justify-end gap-2">
                <Button
                    title={t('actions.cancel')}
                    onClick={() => props.onCloseButtonClick()}
                />
                <Button
                    buttonType={ButtonType.Primary}
                    isDisabled={!model.isSubmitButtonEnabled()}
                    onClick={() => handleSubmitButtonClick()}
                    buttonActionType={ButtonActionType.Submit}
                    title={t('viewing.invitation.send')}
                    icon="ri-mail-send-line"
                />
            </div>
        </Modal>
    )
}
