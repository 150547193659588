import Confetti from '../../../../components/effects/confetti';
import {CheckCircleIcon} from '@heroicons/react/solid';
import HorizontalDivider from '../../../../components/generics/horizontalDivider/HorizontalDivider';
import {Trans, useTranslation} from 'react-i18next';
import Text from '../../../../components/typography/text/Text';
import styles from './SuccessPageFragment.module.css';
import FontSizeEnum from '../../../../components/typography/enums/fontSizeEnum';
import ColorEnum from '../../../../enums/colorEnum';

export default function SuccessPageFragment() {

    const {t} = useTranslation()

    return (
        <>
            <Confetti/>
            <div className={styles.titles}>
                <CheckCircleIcon
                    width="48"
                    height="48"
                    className="text-success-600"
                />
                <h1 className="text-2xl font-bold text-primary-700">{t('emailAddressConfirmation.success.title')}</h1>
            </div>
            <HorizontalDivider/>
            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                <Trans
                    i18nKey="emailAddressConfirmation.success.body"
                    components={{Link: <a/>}}
                />
            </Text>

            <Text size={FontSizeEnum.Md} color={ColorEnum.Gray800}>
                <strong>{t('emailAddressConfirmation.success.closing')}</strong>
            </Text>
        </>
    )
}
