import moment from 'moment';
import {t} from 'i18next';
import ListingStatus from '../../enums/listingStatus';
import BadgeModel from '../../components/generics/badge_deprecated/logic/models/badgeModel';
import BadgeType from '../../components/generics/badge_deprecated/enums/BadgeType';

export enum TextType {
    Detailed,
    Brief
}

export enum TimeUnit {
    Weeks,
    Days
}

export default class ListingStatusTimerModel {
    constructor(
        private readonly listingStatus: ListingStatus,
        private readonly dateOfClosing: Date
    ) {}

    public getTimeLeftUntilClosing(): {amountOfTime: number, unit: TimeUnit} {
        const weeksUntillClosing = moment(this.dateOfClosing).diff(moment(), 'weeks')
        if (weeksUntillClosing <= 0) {
            const daysUntillClosing = moment(this.dateOfClosing).diff(moment(), 'days')
            return {
                amountOfTime: daysUntillClosing <= 0 ? 0 : daysUntillClosing,
                unit: TimeUnit.Days
            }
        }
        return {amountOfTime: weeksUntillClosing, unit: TimeUnit.Weeks}
    }

    public isListingClosed(): boolean {
        return this.listingStatus === ListingStatus.Closed;
    }

    public getBriefText(): string {
        switch(this.listingStatus) {
            case ListingStatus.Closed: return t('listings.status.closed')
            case ListingStatus.Archived: return this.getText(TextType.Brief)
            default: return ''
        }
    }

    public getDetailedText(): string {
        switch(this.listingStatus) {
            case ListingStatus.Closed: return t('listings.status.closed.tooltip', {date: moment(this.dateOfClosing).format('DD-MM-YYYY')})
            case ListingStatus.Archived: return this.getText(TextType.Detailed)
            default: return ''
        }
    }

    private getText(textType: TextType): string {
        const timeLeft = this.getTimeLeftUntilClosing()
        if (timeLeft.unit === TimeUnit.Days) {
            return this.getClosedInDaysText(timeLeft.amountOfTime, textType)
        }
        return this.getClosedInWeeksText(timeLeft.amountOfTime, textType)
    }

    private getClosedInWeeksText(weeks: number, textType: TextType): string {
        if (weeks > 1) {
            return t(`listings.status.closesInWeeks${textType === TextType.Detailed ? '.tooltip' : ''}`, {weeks: weeks})
        }
        return t(`listings.status.closesInOneWeek${textType === TextType.Detailed ? '.tooltip' : ''}`)
    }

    private getClosedInDaysText(days: number, textType: TextType): string {
        if (days > 1) {
            return t(`listings.status.closesInDays${textType === TextType.Detailed ? '.tooltip' : ''}`, {days: days})
        }
        return t(`listings.status.closesInOneDay${textType === TextType.Detailed ? '.tooltip' : ''}`)
    }

    public asBadge(): BadgeModel | null {
        if (this.dateOfClosing !== null && (this.listingStatus === ListingStatus.Archived || this.listingStatus === ListingStatus.Closed)) {
            return new BadgeModel(
                this.getBriefText(),
                this.listingStatus === ListingStatus.Closed ? BadgeType.Gray : BadgeType.Warning,
                this.listingStatus === ListingStatus.Closed ? 'ri-lock-line' : 'ri-timer-line',
                this.getDetailedText()
            )
        }
        return null
    }
}
