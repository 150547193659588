import ImmutableModel from '../../../../models/immutableModel/immutableModel'
import CandidateScreeningRequestStatusEnum from '../../../../enums/candidateScreeningRequestStatusEnum'
import ColorEnum from '../../../../enums/colorEnum'

interface CandidateScreeningStatusBadgeModelProps {
    readonly status: CandidateScreeningRequestStatusEnum
}

export default class CandidateScreeningStatusBadgeModel extends ImmutableModel<CandidateScreeningStatusBadgeModel, CandidateScreeningStatusBadgeModelProps> {

    public getLabel(): string {
        switch (this.value.status) {
            case CandidateScreeningRequestStatusEnum.Invited:
                return 'candidate.screening.badge.invited'
            case CandidateScreeningRequestStatusEnum.Expired:
                return 'candidate.screening.badge.expired'
            case CandidateScreeningRequestStatusEnum.Completed:
                return 'candidate.screening.badge.completed'
            default:
                return '-'
        }
    }

    public getBackgroundColor(): ColorEnum {
        switch (this.value.status) {
            case CandidateScreeningRequestStatusEnum.Invited:
                return ColorEnum.Primary100
            case CandidateScreeningRequestStatusEnum.Expired:
                return ColorEnum.Error50
            case CandidateScreeningRequestStatusEnum.Completed:
                return ColorEnum.Success50
            default:
                return ColorEnum.Gray300
        }
    }

    getLabelColor(): ColorEnum {
        switch (this.value.status) {
            case CandidateScreeningRequestStatusEnum.Invited:
                return ColorEnum.Primary600
            case CandidateScreeningRequestStatusEnum.Expired:
                return ColorEnum.Error700
            case CandidateScreeningRequestStatusEnum.Completed:
                return ColorEnum.Success700
            default:
                return ColorEnum.Gray300
        }
    }

    public getBorderColor(): ColorEnum {
        switch (this.value.status) {
            case CandidateScreeningRequestStatusEnum.Invited:
                return ColorEnum.Primary600
            case CandidateScreeningRequestStatusEnum.Expired:
                return ColorEnum.Error700
            case CandidateScreeningRequestStatusEnum.Completed:
                return ColorEnum.Success700
            default:
                return ColorEnum.Gray300
        }
    }
}
